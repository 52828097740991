import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Dropdown,
  Button,
  Segment,
  Container,
  Header,
  Table,
  Pagination,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { industriesSchema } from "../tableSchema";

export const IndustriesCreateDelete = () => {
  const { instance, accounts } = useMsal();
  const [industriesList, setIndustriesList] = useState([]);
  const [newIndustry, setNewIndustry] = useState("");
  const [isValidIndustry, setIsValidIndustry] = useState(true);
  const [isValidExistingIndustry, setIsValidExistingIndustry] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [existingIndustry, setExistingIndustry] = useState("");
  const [refetchData, setRefetchData] = useState(false);


  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  useEffect(() => {
    const fetchIndustriess = async () => {
      var request = {
        collection: industriesSchema.plural,
        select: [
          industriesSchema.industry_name,
          industriesSchema.id,
        ],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          const industries = response.value.map((i) => ({
            name: i.cr0d7_name,
            key: i.cr0d7_industriesid || null,
          }));
          // Sort teams alphabetically by name
          industries.sort((a, b) => a.name.localeCompare(b.name));
          setIndustriesList(industries);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };

    fetchIndustriess();
    setRefetchData(false);
  }, [refetchData]);

  const createIndustryRow = async () => {
    // var entity = {
    //     cr749_companyname_: company.cr749_companyname,
    //     cr749_businesssection: businessSection.key
    // };
    var entity = {};
    entity[industriesSchema.industry_name] = newIndustry;

    var request = {
      // collection: "cr749_db_businesslines",
      collection: industriesSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    const response = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        console.log("created record: ", record);
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    setIsLoading(false);
    setRefetchData(true);
    setNewIndustry("");
    return response;
  };

  const deleteIndustryRow = async () => {
    var request = {
      key: existingIndustry,
      collection: industriesSchema.plural,
    };

    try {
      await dynamicsWebApi.deleteRequest(request);
      console.log(`Successfully deleted row with key ${existingIndustry}`);
    } catch (error) {
      console.log("Error deleting row", error);
    } finally {
        setIsDeleting(false);
        setRefetchData(true);
    }
  };

  
  const handleSubmit = async () => {
    if (isValidIndustry) {
        setIsLoading(true);
        await createIndustryRow();
    }
  };

  const handleDelete = async () => {
    if (isValidExistingIndustry) {
        setIsDeleting(true);
        await deleteIndustryRow();
    }
  };


  return (
    <>
      <Container text style={{ padding: "1em 0em" }}>
      <Header as="h3">Industries</Header>
        <Form>
          <label>New Industry</label>
          <Form.Group>
            <Form.Input
              width={10}
              fluid
              type="text"
              placeholder="Enter a new Industry"
              autoComplete="off"
              value={newIndustry}
              onChange={(e, { value }) => {
                setNewIndustry(value);
                setIsValidIndustry(value.length > 0);
              }}
            />
            <Button
              color="green"
              content="Create"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
              loading={loading}
            />
          </Form.Group>
          <label>Remove Industry</label>
          <Form.Group>
            <Form.Field width={10}>
              <Dropdown
                placeholder="Select a referral type to delete"
                fluid
                selection
                value={existingIndustry}
                onChange={(e, { value }) => {
                    console.log(
                        'here is the value', value
                    );
                  setExistingIndustry(value);
                  setIsValidExistingIndustry(value.length > 0);
                }}
                options={industriesList.map((type) => ({
                  key: type.key,
                  value: type.key,
                  text: type.name,
                }))}
              />
            </Form.Field>
            <Button
              content="Delete"
              loading={isDeleting}
              onClick={(e) => {
                e.preventDefault();
                handleDelete();
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};
