
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout";
import { RenewalsCompletedForm } from "../RenewalsCompletedForm";
import { RenewalsProvider } from "../context/renewalsContext";
import { DbUsersContext } from "../context/usersContext";

export const RenewalsCompletedApp = () => {
    return (<AuthenticatedPageLayout requiresAdmin={true}>
        <RenewalsProvider>
        <RenewalsCompletedForm/>
        </RenewalsProvider>
    </AuthenticatedPageLayout>);
};