
export const clientSchema = {
    plural: "cr749_db_companies",
    name: "cr749_db_company",
    id: "cr749_db_companyid",
    client_name: "cr749_companyname",
    hubspot_id: "cr749_hubspotid",
    ibroker: "cr749_ibrokerid",
    pipedrive_id: "cr749_pipedriveid",
    sharepoint: "cr749_sharepointsite"
};
 


export const newBusinessChecklistSchema = {
    plural: "cr0d7_newbusinesschecklists",
    name: "cr0d7_newbusinesschecklist",
    id: "cr0d7_newbusinesschecklistid",
    client: "cr0d7_client",
    additional_services_discussed: "cr0d7_additionalservicesdiscussed",
    applicant_declaration_completed: "cr0d7_applicantdeclarationcompleted",
    additional_services_arranged: "cr0d7_additionalservicesarranged",
    authority_to_proceed: "cr0d7_authoritytoproceed",
    date: "cr0d7_date",
    filing_completed: "cr0d7_filingcompleted",
    final_report_outlining_covers: "cr0d7_finalreportoutliningcovers",
    financial_literacy_discussed: "cr0d7_financialliteracydiscussed",
    hold_covers: "cr0d7_holdcovers",
    insurance_reviews_completed_documented: "cr0d7_insurancereviewcompleteddocumented",
    loa_completed: "cr0d7_loacompleted",
    nature_and_scope_advice_outlined: "cr0d7_natureandscopeadviceoutlined",
    nature_and_scope_advice_including_vulnerability: "cr0d7_natureandscopeofadviceincludingvulnerability",
    needs_objectives_established: "cr0d7_needsobjectivesestablished",
    notes: "cr0d7_notes",
    opt_retail: "cr0d7_optretail",
    quote_presented: "cr0d7_quotepresented",
    ra: "cr0d7_ra",
    research_documented: "cr0d7_researchdocumented",
    soa_prepared: "cr0d7_soaprepared",
    tasks_set_to_follow_up: "cr0d7_taskssettofollowupanyriskmanagement",
    terms_of_engagment_agreed: "cr0d7_termsofengagementagreed",
    type: "cr0d7_type",
    uninsured_risks_reviewed: "cr0d7_uninsuredrisksreviewed",
    vulnerable_client_discussed: "cr0d7_vulnerableclientdiscussed",
}



export const renewalInstance = {
    plural: "cr0d7_renewal_p1_instancetables",
    name: "cr0d7_renewal_p1_instancetable",
    id: "cr0d7_renewal_p1_instancetableid",
    client_name: "cr0d7_fullclientname",
    ibroker: "cr0d7_ibrokerid",
    pipedrive_id: "cr0d7_pipedriveid",
    renewal_date: "cr0d7_renewaldate",
    alternate_risk_adviser: "cr0d7_alternateriskadviser",
    associate_risk_adviser: "cr0d7_associateriskadviser",
    claims: "cr0d7_claims",
    claims_in_progress: "cr0d7_claimsinprogress",
    client_contact_email: "cr0d7_clientcontactemail",
    client_contact_name: "cr0d7_clientcontactname",
    days_till_renewal: "cr0d7_daystillrenewal",
    matter_to_discuss: "cr0d7_mattertodiscuss",
    pending_actions: "cr0d7_pendingactions",
    client_full_name: "cr0d7_client",
    ra_email: "cr0d7_raemail",
    risk_adviser: "cr0d7_riskadviser",
    status: "cr0d7_status",
    client_specific_issue: "cr0d7_clientspecificissues"

};


export const renewalMeetingInstance = {
    plural: "cr0d7_renewal_p1_meetingdatatables",
    id: "cr0d7_renewal_p1_meetingdatatableid",
    relationship_id: "cr0d7_RenewalP1InstanceTable", //look up
    client_full_name: "cr0d7_newcolumn",
    specific_details: "cr0d7_specificdetails",
    task_1st_instalment: "cr0d7_task1instalment",
    task_2nd_instalment: "cr0d7_task2ndinstalment",
    task_3_or_4_instalment: "cr0d7_task3or4instalment",
    task_3rd_instalment: "cr0d7_rdinstalment",
    task_4th_instalment: "cr0d7_task4thinstalment",
    task_action_required_instalment: "cr0d7_taskactionrequired",
    task_completed_renewal: "cr0d7_taskcompletedrenewal",
    task_email_information: "cr0d7_taskemailinformation",
    task_email_pre_renewal: "cr0d7_taskemailprerenewal",
    task_email_renewal_doc: "cr0d7_taskemailrenewaldoc",
    task_email_report: "cr0d7_taskemailreport",
    task_payment_full: "cr0d7_taskpaymentinfull",
    task_select_payment_option: "cr0d7_taskpaymentselectoption",
    task_pre_renewal: "cr0d7_taskprerenewal",
    task_preparation_renewal_doc: "cr0d7_taskpreparationrenewaldoc",
    task_present_renewal: "cr0d7_taskpresentrenewal",
    task_renewal_agreement: "cr0d7_taskrenewalagreement",
    task_renewal_confirmation: "cr0d7_taskrenewalconfirmation",
    task_return_signed_document: "cr0d7_taskreturnsigneddocument",
    task_preparation_pre_renewal_meeting: "cr0d7_taskpreparationprerenewalmeeting",
    agreed_date_reason: "cr0d7_agreeddatereason", 
    other_agreed_reason: "cr0d7_otheragreeddatereason",
    payment_reason: "cr0d7_paymentreason",
    payment_option: "cr0d7_paymentoption",
};


export const renewalDateReasonsSchema = {
    plural: "cr0d7_renewal_date_reasons",
    name: "cr0d7_renewal_date_reason",
    id: "cr0d7_renewal_date_reasonid",
    reason_name: "cr0d7_name"
};


export const dbUsersSchema = {
    plural: "cr0d7_dbuserses",
    name: "cr0d7_dbusers",
    id: "cr0d7_dbusersid",
    email: "cr0d7_email",
    admin: "cr0d7_admin",
};




export const individualSchema = {
    plural: "cr749_db_individuals",
    name: "cr749_db_individual",
    id: "cr749_db_individualid",
    first_name: "cr749_firstname",
    full_name: "cr749_fullname",
    last_name: "cr749_lastname",
    hubspot_id: "cr749_hubspotid",
    personal_email: "cr749_personalemail",
    phone_number: "cr749_phonenumber",
    pipedrive_id: "cr749_pipedrive",
    work_email: "cr749_workemail",
    ibroker: "cr0d7_ibrokerid",
};

export const businessLinesSchema = {
    plural: "cr749_db_businesslines",
    name: "cr749_db_businessline",
    id: "cr749_db_businesslineid",
    status: "cr0d7_businesslinestatus", // choice
    category: "cr0d7_clientcategory", // choice
    adviser: "cr749_Adviser", // lookup - user table
    associate: "cr749_Associate", // lookup - user table
    business_section: "cr749_businesssection", // choice
    client: "cr749_Company", // lookup - db client
    client_name: "cr749_companyname_",
    main_contact: "cr749_MainContact", // lookup - db individual
};

export const relationshipSchema = {
    plural: "cr088_db_client_individual_relationships",
    name: "cr088_db_client_individual_relationship",
    id: "cr088_db_client_individual_relationshipid",
    row_name: "cr088_name",
    client: "cr088_Client", // lookup - db client
    individual: "cr088_Individual", // lookup - db individual
};

export const adviserFeeTeamSchema = {
    plural: "cr0d7_dbadviserfeeroleses",
    name: "cr0d7_dbadviserfeeroles",
    adviser_name: "cr0d7_name",
    id: "cr0d7_dbadviserfeerolesid",
    associate: "cr0d7_associate",
    pa_team: "cr0d7_pateam",
    ra_team: "cr0d7_rateam",
    fin_team: "cr0d7_finlinesteam",
    pipedrive_id: "cr0d7_pipedriveid",
}

export const feesSchema = {
    plural: "cr0d7_db_adviser_feeses",
    name: "cr0d7_db_adviser_fees",
    id: "cr0d7_db_adviser_feesid",
    admin_fee: "cr0d7_adminfee",
    adviser_id: "cr0d7_adviserid",
    adviser_name: "cr0d7_advisorname",
    approx_turnover: "cr0d7_approximateturnover",
    claims_fee: "cr0d7_claimsfee",
    client: "cr0d7_Client",
    deal_id: "cr0d7_dealid",
    disbursements: "cr0d7_disbursements",
    fee_associated: "cr0d7_feeassociated",
    fin_lines: "cr0d7_financial",
    industry: "cr0d7_industry",
    ld_team: "cr0d7_ldteamfee",
    other: "cr0d7_otherfee",
    pa_team: "cr0d7_pateamfee",
    pipedrive_deal_name: "cr0d7_pipedrivedealname",
    policy_count: "cr0d7_policycount",
    endorsment_last_period: "cr0d7_endorsementlastperiod",
    premium_pool_percent: "cr0d7_premiumpoolpercentage",
    previous_income: "cr0d7_previousincome",
    ra_team: "cr0d7_rateamfee",
    operational_costs: "cr0d7_operationalcosts",
    total: "cr0d7_total",
    total_premium_pool: "cr0d7_totalpremiumpool",
    fee_percent_vs_last_year: "cr0d7_feepercentagevslastyear",
    pa_associates: "cr0d7_paassociates",
    ra_associates: "cr0d7_raassociates",
    fin_lines_associates: "cr0d7_finlinesassociates",
    fin_lines_adviser_fees: "cr0d7_finlinesadviserfees",
    ra_team_adviser_fees: "cr0d7_rateamadviserfees",
    pa_team_adviser_fees: "cr0d7_paadviserteamfees"

};

export const dealOwnersSchema = {
    plural: "cr0d7_db_dealowners",
    name: "cr0d7_db_dealowner",
    id: "cr0d7_db_dealownerid",
    business_section: "cr0d7_businesssection", // choice
    business_section_type: "cr0d7_businesssectiontype", // string
    owner_name: "cr0d7_name",
    pipedrive_id: "cr0d7_pipedriveid",
    user_email: "cr0d7_useremail"
};

export const industriesSchema = {
    plural: "cr0d7_industrieses",
    name: "cr0d7_industries",
    id: "cr0d7_industriesid",
    industry_name: "cr0d7_name"
};

export const notesSchema = {
    plural: "cr0d7_notestaskses",
    name: "cr0d7_notestasks",
    id: "cr0d7_notestasksid",
    deal_name: "cr0d7_dealname",
    deal_id: "cr0d7_dealid",
    client_name: "cr0d7_clientname",
    note_title: "cr0d7_name",
    note_body: "cr0d7_notecontent",
    note_date: "cr0d7_date",
    assigned_to_email: "cr0d7_assignedtoemail",
    is_task: "cr0d7_taskpipedrive",
};
