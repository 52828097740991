import { useRouteError } from "react-router-dom";
import { Container, Header, Divider} from 'semantic-ui-react';
import { PageLayout } from "../PageLayout";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <PageLayout>
        <Container text id="error-page" style={{ padding: "7em 0em 0.5em" }}>
            <Header as="h1">
                Oops!
                <Header.Subheader>
                    Sorry, an unexpected error has occurred.
                </Header.Subheader>
            </Header>
            <Divider/>
            <p><i>{error.statusText || error.message}</i></p>
        </Container>
    </PageLayout>
  );
}