import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Dropdown,
  Button,
  Segment,
  Container,
  Header,
  Divider,
  Label,
  Message,
  Icon,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  Input,
  Pagination,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import axios from "axios";
import { CompaniesSearch } from "./CompaniesSearch";
import {
  mkey,
  functionSite,
  clientSite,
  sharepointSite,
  noValueObject,
  formatDate,
} from "../constants";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { renewalInstance } from "../tableSchema";
import { RenewalDealRow } from "./RenewalRow";

export const RenewalsList = () => {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [filteredClients, setFiltered] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [isValidClient, setIsValidClient] = useState(true);
  const [isValidName, setIsValidName] = useState(true);
  const [formState, setFormState] = useState("");
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = activePage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentClients = filteredClients.slice(indexOfFirstItem, indexOfLastItem);

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const calculateDaysTillRenewal = (date) => {
    const renewalDate = new Date(date);
    const today = new Date();
    const timeDifference = renewalDate.getTime() - today.getTime();
    const daysTillRenewal = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return Math.max(0, daysTillRenewal);
  };

  //because the dataverse doesn't retrieve label only value for status create own object
  const status = {
    1: "Not started",
    2: "Started",
    3: "Pre-renewal complete",
  };

  useEffect(() => {
    const fetchRenewalClients = async () => {
      const fetchXml =
        `<fetch mapping="logical">` +
        `<entity name="${renewalInstance.name}">` +
        `<attribute name="${renewalInstance.client_name}"/>` +
        `<attribute name="${renewalInstance.client_full_name}"/>` +
        `<attribute name="${renewalInstance.ibroker}"/>` +
        `<attribute name="${renewalInstance.pipedrive_id}"/>` +
        `<attribute name="${renewalInstance.id}"/>` +
        `<attribute name="${renewalInstance.renewal_date}"/>` +
        `<attribute name="${renewalInstance.days_till_renewal}"/>` +
        `<attribute name="${renewalInstance.status}"/>` +
        `</entity>` +
        `</fetch>`;

      try {
        const response = await dynamicsWebApi.executeFetchXmlAll(
          renewalInstance.plural,
          fetchXml
        );
        console.log(response.value);

        const records = response.value.filter(
          (record) => record.cr0d7_pipedriveid != null
        );
        if (records.length > 0) {
          const renewalRows = records.map((deal) => ({
            client_name: deal.cr0d7_fullclientname,
            client_full_name: deal.cr0d7_client,
            ibroker: deal.cr0d7_ibrokerid,
            pipedrive_id: deal.cr0d7_pipedriveid,
            days_till_renewal: calculateDaysTillRenewal(deal.cr0d7_renewaldate),
            renewal_date: formatDate(deal.cr0d7_renewaldate),
            status: status[deal.cr0d7_status],
            id: deal.cr0d7_renewal_p1_instancetableid,
          }));
          setClients(renewalRows);
        }
      } catch (error) {
        console.error("Error fetching renewal clients:", error);
        setClients([]);
      }
    };

    fetchRenewalClients();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFields()) {
      setIsLoading(true);
      resetFields();
      setFormState("success");
    }
  };

  const resetFields = () => {
    setIsLoading(false);
    setSelectedClient({});
    setIsValidClient(true);
    setIsValidName(true);
    setFormState("");
  };

  const validateFields = () => {
    setIsValidClient(Object.keys(selectedClient).length > 0);

    if (isValidClient && isValidName) {
      return true;
    } else {
      setFormState("error");
      return false;
    }
  };

  const handleSearchChange = (e, { value }) => {
    setSearchQuery(value);
  };

  useEffect(() => {
    if (clients && clients.length > 0) {
      const filteredClients = clients.filter((client) =>
        client.client_name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFiltered(filteredClients);
    }
  }, [clients, searchQuery]);
  

  const handlePaginationChange = (e, { activePage }) => {
    setActivePage(activePage);
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            Renewal App
            <Header.Subheader>
              Select Renewal from dropdown to start capturing renewal plan.
            </Header.Subheader>
          </Header>
          <Divider inverted />
        </Container>
      </Segment>
      <Container text style={{ padding: "1em 0em" }}>
        <Form
          loading={isLoading}
          method="post"
          onSubmit={handleSubmit}
          error={formState === "error"}
          success={formState === "success"}
        >
          <Form.Field required>
            <label>Client Name Search</label>
            <Input
              fluid
              icon="search"
              placeholder="Search client..."
              onChange={handleSearchChange}
            />
            {!isValidClient && (
              <Label prompt error pointing>
                Please select a client
              </Label>
            )}
          </Form.Field>
          <Table celled selectable>
            <TableHeader>
              <TableRow>
                <TableHeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Client
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  iBroker
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Pipedrive id
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Days till renewal
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Renewal Date
                </TableHeaderCell>
                <TableHeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Status
                </TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {currentClients.map((client) => (
                <RenewalDealRow key={client.id} deal={client} />
              ))}
            </TableBody>
          </Table>
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={Math.ceil(filteredClients.length / itemsPerPage)}
          />
          <Message
            error
            header="Error"
            content="Please ensure the mandatory fields are filled out"
          />
        </Form>
      </Container>
    </>
  );
};
