import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Button,
  Segment,
  Container,
  Header,
  Divider,
  Label,
  Message,
  Icon,
  Table,
  TableHeader,
  TableRow,
  TableHeaderCell,
  TableBody,
  Input,
  TextArea,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { renewalInstance } from "../tableSchema";
import { useNavigate } from "react-router-dom";
import { useRenewalsContext } from "./context/renewalsContext";
import { RenewalsKeyMilestonesForm } from "./RenewalsKeyMilestonesForm";

export const RenewalsContactForm = () => {
  const { instance, accounts } = useMsal();

  const { renewalInstanceData, renewalMeetingData,  error, refetchData } = useRenewalsContext();
  console.log("here is the renewalInstanceData: ", renewalInstanceData);
  console.log("here is the renewalMeetingData: ", renewalMeetingData);
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState("");
  const [clientContactName, setClientContactName] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [significantClaims, setSignificantClaims] = useState("");
  const [communications, setCommunications] = useState("");
  const [otherMatters, setOtherMatters] = useState("");
  const [specificIssues, setSpecificIssues] = useState("");
  const [raEmail, setRaEmail] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [milestoneSection, setMileStoneSection ] = useState(false);
  const navigate = useNavigate();

  const url = window.location.pathname;
  const id = url.split("/").pop();

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  useEffect(() => {
    setClientContactName(renewalInstanceData.cr0d7_clientcontactname ?? "");
    setClientEmail(renewalInstanceData.cr0d7_clientcontactemail ?? "");
    setRaEmail(renewalInstanceData.cr0d7_raemail ?? "");
    setSignificantClaims(renewalInstanceData.cr0d7_claimsinprogress ?? "");
    setCommunications(renewalInstanceData.cr0d7_pendingactions ?? "");
    setOtherMatters(renewalInstanceData.cr0d7_mattertodiscuss ?? "");
    setClientName(renewalInstanceData.cr0d7_fullclientname ?? "");
    setSpecificIssues(renewalInstanceData.cr0d7_clientspecificissues ?? "");
  }, [renewalInstanceData]);

  const updateRenewalInstance = async (statusValue) => {
    var request = {
      collection: renewalInstance.plural,
      select: [renewalInstance.id],
      filter: `${renewalInstance.id} eq ${id}`,
      count: true,
    };

    try {
      // Get related renewal instances
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      console.log("Here are the records to be updated:", response);

      // Update each record with new values
      for (const record of response.value) {
        const keyValuePair = {
          [renewalInstance.client_contact_name]: clientContactName.trim(),
          [renewalInstance.client_contact_email]: clientEmail,
          [renewalInstance.ra_email]: raEmail,
          [renewalInstance.claims_in_progress]: significantClaims,
          [renewalInstance.pending_actions]: communications,
          [renewalInstance.matter_to_discuss]: otherMatters,
          [renewalInstance.status]: statusValue,
          [renewalInstance.client_specific_issue]: specificIssues,
        };

        // Update the record
        const updateRequest = {
          key: record[renewalInstance.id],
          collection: renewalInstance.plural,
          data: keyValuePair,
        };

        await dynamicsWebApi.updateRequest(updateRequest);

        console.log("Successfully updated renewal instance");
      }
    } catch (error) {
      console.log("Error updating renewal instances", error);
    }
  };





  const validateFields = () => {
    return true;
  };

  const handleSubmit = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (validateFields()) {
      setIsLoading(true);
      await updateRenewalInstance(2);
      var message = `Successfully saved for client: ${clientName}`;
      setSuccessMessage(message);
      setFormState("success");
      setIsLoading(false);
      await refetchData();
    }
  };

  const handleBackButtonClick = () => {
    setMileStoneSection(false);
  };

  return (
    <>
      {!milestoneSection ? (
        <>
          <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
            <Container text>
              <Header inverted as="h1">
                Renewal App
                <Header.Subheader>
                  Fill out the form and click complete to generate tasks and
                  planning meeting report. Click save to save your progress and
                  complete at a later date.
                </Header.Subheader>
              </Header>
              <Divider inverted />
            </Container>
          </Segment>
  
          <Container text style={{ padding: "1em 0em" }}>
            <Form
              loading={isLoading}
              method="post"
              onSubmit={handleSubmit}
              error={formState === "error"}
              success={formState === "success"}
            >
              {/* Form Inputs */}
              <Form.Input
                fluid
                label="Client Contact Name"
                type="text"
                placeholder="Enter contact name"
                name="newName"
                autoComplete="off"
                value={clientContactName}
                onChange={(e) => {
                  setClientContactName(e.target.value);
                }}
                required
              />
              <Form.Input
                fluid
                label="Client Email"
                type="text"
                placeholder="Enter contact email"
                name="newName"
                autoComplete="off"
                value={clientEmail}
                onChange={(e) => {
                  setClientEmail(e.target.value);
                }}
                required
              />
              <Form.Input
                fluid
                label="RA Email"
                type="text"
                placeholder="Enter ra email"
                name="newName"
                autoComplete="off"
                value={raEmail}
                onChange={(e) => {
                  setRaEmail(e.target.value);
                }}
                required
              />
              <label>
                {" "}
                Are there any significant claims in progress or contentious or
                positive claims at the time of the planning meeting/ to discuss?
                Anything claims team wants to raise with client?
              </label>
              <TextArea
                fluid
                label=""
                autoComplete="off"
                value={significantClaims}
                onChange={(e) => {
                  setSignificantClaims(e.target.value);
                }}
              />
              <label>
                Are there any communications that the client directed to us since the
                previous renewal that we did not action?{" "}
              </label>
              <TextArea
                fluid
                label=""
                autoComplete="off"
                value={communications}
                onChange={(e) => {
                  setCommunications(e.target.value);
                }}
              />
              <label>
                Any other matters we want to discuss as part of the planning
                process?
              </label>
              <TextArea
                fluid
                label=""
                autoComplete="off"
                value={otherMatters}
                onChange={(e) => {
                  setOtherMatters(e.target.value);
                }}
              />
              <label>
               Ask the client if there are any specific issues they want to raise to help us plan?
              </label>
              <TextArea
                fluid
                label=""
                autoComplete="off"
                value={specificIssues}
                onChange={(e) => {
                 setSpecificIssues(e.target.value);
                }}
              />
              <Button
                fluid
                type="submit"
                content="Save"
                color="green"
                style={{ margin: "2em 0em 0.5em" }}
                onClick={validateFields}
              />
              <Button
                fluid
                color="green"
                content="Next"
                onClick={() => setMileStoneSection(true)}
              />
  
              <Button
                fluid
                basic
                style={{ margin: "0.5em 0em 0.5em" }}
                content="Back"
                onClick={() => navigate("/renewals-app")}
              />
              <Message
                success
                header="Renewal deal saved!"
                content={successMessage}
              />
              <Message
                error
                header="Error"
                content="Error saving renewal deal information"
              />
            </Form>
          </Container>
        </>
      ) : (
        <RenewalsKeyMilestonesForm onBackButtonClick={handleBackButtonClick}/>
      )}
    </>
  );
  
};
