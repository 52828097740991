import React from 'react';
import { Dropdown, Input, Form, Button } from 'semantic-ui-react';

export const TeamFee = ({ teamList, onSelectAdviser, onEnterFee, onDeleteFee, index }) => {
    const handleSelectAdviser = (e, { value }) => {
        onSelectAdviser(value);
    };

    const handleEnterFee = (e, { value }) => {
        onEnterFee(value);
    };


    return (
        <Form.Input>
            <Button basic icon="times" size="mini" floated="right" style={{ boxShadow: "none" }} onClick={(e) => { e.preventDefault(); onDeleteFee(e); }} />
            <Dropdown
                placeholder="Select Adviser"
                selection
                options={teamList}
                onChange={handleSelectAdviser}
                style={{ flex: '1', marginRight: '2px' }}
            />
            <Input
                type="number"
                icon="dollar sign"
                iconPosition="left"
                min="0"
                placeholder="Enter Value"
                onChange={handleEnterFee}
            />
        </Form.Input>
    );
};




