import * as React from "react";
import {
  Button,
  Form,
  TextArea,
  Dropdown,
  Checkbox,
  Segment,
  Header,
  Divider,
  Container,
  Label,
  Message,
  Icon,
  Input,
} from "semantic-ui-react";
import { useState, useEffect, useRef } from "react";
import "semantic-ui-css/semantic.min.css";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import DynamicsWebApi from "dynamics-web-api";
import { jsPDF } from "jspdf";
//import * as msal from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { clientSchema, notesSchema } from "../tableSchema";
import {
  mkey,
  functionSite,
  serverUrl,
  sharepointSite,
  noValueObject,
} from "../constants";
import { sendTeamsMessage } from "../teams";
import { Task } from "./Task";

export const NoteWebPart = () => {
  const { instance, accounts } = useMsal();

  const [advisers, setAdvisers] = useState([]);

  const [activityTypes, setActivityTypes] = useState([]);

  const [selectedClient, setSelectedClient] = useState({});
  const [clients, setClients] = useState([]);
  const [receivedClients, hasReceivedClients] = useState(false);

  // const [isClient, setIsClient] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);

  // const [dealId, setDealID] = useState("");

  const [deals, setDeals] = useState([]);
  const [loadingDeals, setLoadingDeals] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState({});
  const [isNewDeal, setIsNewDeal] = useState(false);
  const [dealName, setDealName] = useState("");

  const [pipelines, setPipelines] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState({});

  const [selectedAdviser, setSelectedAdviser] = useState({});

  const [tasks, setTasks] = useState([]);

  const [isLoading, setIsLoading] = useState("");

  //const [accessToken, setAccessToken] = useState('');
  const [currentDate, setNewDate] = useState("");

  const [note, setNote] = useState("");
  const [noteSubject, setSubjectName] = useState("");

  const [isValidNote, setIsValidNote] = useState(true);
  const [isValidClient, setIsValidClient] = useState(true);
  const [isValidDate, setIsValidDate] = useState(true);
  const [isValidSubject, setIsValidSubject] = useState(true);
  const [isValidDeal, setIsValidDeal] = useState(true);
  const [isValidDealName, setIsValidDealName] = useState(true);
  const [isValidPipeline, setIsValidPipeline] = useState(true);
  const [isValidAdviser, setIsValidAdviser] = useState(true);

  const [formState, setFormState] = useState("");

  const taskButtonRef = useRef(null);

  //Changes and formats date
  const onChange = (event, data) => {
    const selectedDate = data.value instanceof Date ? data.value : "";
    const formattedDate = selectedDate
      ? selectedDate
          .toLocaleDateString("en-NZ", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
          .replace(/\//g, "-")
      : "";

    setIsValidDate(formattedDate.length > 0);
    setNewDate(formattedDate);
  };

  //Note change
  const handleNoteChange = (e, { value }) => {
    setNote(value);
    setIsValidNote(value.length > 0);
  };

  const handleSelectedFolder = (e, { value }) => {
    console.log("here is the selected folder: ", value);
    setSelectedFolder(value);
  };

  const handleSelectedTag = (e, { value }) => {
    console.log("here is the selected tag: ", value);
    setSelectedTag(value);
  };

  //Sharepoint select folder stuff
  const [foldersRetrieved, setFoldersRetrieved] = useState([]);
  const [loadingFolders, setLoadingFolders] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [tagsRetrieved, setTagsRetrieved] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [selectedTag, setSelectedTag] = useState("");

  const getSharepointHeaders = async () => {
    return await instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then(async (response) => {
        const accessToken = response.accessToken;
        return {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${accessToken}`,
        };
      });
  };

  const getFormDigestValue = async () => {
    const headers = await getSharepointHeaders();
    return await axios
      .post(`${sharepointSite}/_api/contextinfo`, null, {
        headers: headers,
      })
      .then(async (response) => {
        const formDigestValue =
          response.data.d.GetContextWebInformation.FormDigestValue;
        return formDigestValue;
      });
  };

  const getFolderContents = async (
    folderPath,
    headers,
    formDigestValue,
    clientType,
    clientName
  ) => {
    //clients-ClientFolders
    //prospects is sites/Clients
    let clientUrl;
    if (clientType === "prospect") {
      clientUrl = `${sharepointSite}/sites/Clients/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Folders`;
    } else if (clientType === "client") {
      clientUrl = `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Folders`;
    } else if (clientType === "clientSite") {
      clientUrl = `${sharepointSite}/sites/clients-${clientName}/_api/web/GetFolderByServerRelativeUrl('${folderPath}')/Folders`;
    }
    return axios.get(clientUrl, {
      headers: {
        ...headers,
        "X-RequestDigest": formDigestValue,
      },
    });
  };

  const getSharepointYearFoldersInClient = async (
    clientName,
    clientFolder,
    headers,
    formDigestValue,
    clientType
  ) => {
    setLoadingFolders(true);
    const processFolder = async (currentFolderPath, depth) => {
      if (depth >= 2) {
        return [currentFolderPath];
      }

      const foldersResponse = await getFolderContents(
        currentFolderPath,
        headers,
        formDigestValue,
        clientType,
        clientName
      );
      console.log("here are the folders response: ", foldersResponse);
      const folders = foldersResponse.data.d;
      const folderPaths = [currentFolderPath];

      const subfolders = await Promise.all(
        folders.results.map(async (subfolder) => {
          const subfolderPath = `${currentFolderPath}/${subfolder.Name}`;
          const subfolderPaths = await processFolder(subfolderPath, depth + 1);
          return subfolderPaths;
        })
      );

      return folderPaths.concat(...subfolders);
    };

    try {
      let folderPath =
        clientType === "clientSite"
          ? clientFolder
          : `${clientFolder}/${clientName}`;
      return await processFolder(folderPath, 0);
    } catch (err) {
      console.error(`Error getting client ${clientName} folder`, err);
      return [];
    } finally {
      setLoadingFolders(false);
    }
  };

  //handle selected client
  const handleSelectedClient = async (e, { value }) => {
    setSelectedClient(value);
    console.log("here is the selected client: ", value);
    setIsValidClient(Object.keys(value).length > 0);
    // Call getSharepointFoldersRecursive when a client is selected
    if (isValidClient) {

      try {
        const headers = await getSharepointHeaders();
        const formDigestValue = await getFormDigestValue();
        const clientName = value.cr749_companyname;

        const folderProspectPaths = await getSharepointYearFoldersInClient(
          clientName,
          "Prospects",
          headers,
          formDigestValue,
          "prospects"
        );
        const folderClientPaths = await getSharepointYearFoldersInClient(
          clientName,
          "Shared Documents",
          headers,
          formDigestValue,
          "client"
        );
        var spacesRemovedName = clientName
          .split(" ")
          .join("")
          .replace("'", "%27");
        const validClientName = spacesRemovedName.replace(
          /[^A-Za-z0-9_\-/]/g,
          ""
        );
        const folderClientSitePaths = await getSharepointYearFoldersInClient(
          validClientName,
          "Shared Documents/General",
          headers,
          formDigestValue,
          "clientSite"
        );

        const updatedFolderProspectPaths = folderProspectPaths.map((path) => {
          const parts = path.split("/");
          return parts.slice(1).join("/");
        });

        const updatedFolderClientPaths = folderClientPaths.map((path) => {
          const parts = path.split("/");
          return parts.slice(1).join("/");
        });

        const updatedFolderClientSitePaths = folderClientSitePaths.map(
          (path) => {
            const parts = path.split("/");
            return parts.slice(1).join("/");
          }
        );

        console.log("folders1: ", updatedFolderProspectPaths);
        console.log("folders2: ", updatedFolderClientPaths);
        console.log("folders3: ", updatedFolderClientSitePaths);

        if (updatedFolderClientSitePaths.length >= 1) {
          console.log("Setting Client Site Paths");
          setFoldersRetrieved(updatedFolderClientSitePaths);
          setTagsRetrieved([]);
        } else if (updatedFolderClientPaths.length >= 1) {
          console.log("Setting Client Paths");
          setFoldersRetrieved(updatedFolderClientPaths);
          const fileTypeClientFolderUrl = `${sharepointSite}/sites/clients-ClientFolders/_api/lists/getbytitle('Documents')/contenttypes`;
          // const fileMetadataUrl = `${sharepointSite}/_api/web/lists(7Bcd7a3056-0984-48de-ac33-47c75b252f43%7D)/contenttypes`;
          const fileTypeClientFolderResponse = await axios.get(
            fileTypeClientFolderUrl,
            {
              headers,
            }
          );
          console.log(
            "here is the file type client folder: ",
            fileTypeClientFolderResponse
          );
          if (fileTypeClientFolderResponse.data?.d?.results.length > 0) {
            const clientTagTypes =
              fileTypeClientFolderResponse.data?.d?.results.map((i) => {
                return {
                  name: i.Name,
                  id: i.StringId,
                };
              });
            console.log("Client obj: ", clientTagTypes);
            setTagsRetrieved(clientTagTypes);
          }
        } else if (updatedFolderProspectPaths.length >= 1) {
          console.log("Setting Prospect Paths");
          setFoldersRetrieved(updatedFolderProspectPaths);
          const fileTypeProspectUrl = `${sharepointSite}/sites/Clients/_api/lists/getbytitle('Prospects')/contenttypes`;
          const fileTypeProspectResponse = await axios.get(
            fileTypeProspectUrl,
            {
              headers,
            }
          );
          console.log(
            "here is the file type prospect folder: ",
            fileTypeProspectResponse
          );
          if (fileTypeProspectResponse.data?.d?.results.length > 0) {
            const prospectTagTypes =
              fileTypeProspectResponse.data?.d?.results.map((i) => {
                return {
                  name: i.Name,
                  id: i.StringId,
                };
              });
            console.log("prospect obj: ", prospectTagTypes);
            setFoldersRetrieved(prospectTagTypes);
          }
        } else {
          console.log("No folders retrieved");
          setFoldersRetrieved([]);
        }
      } catch (error) {
        console.error("Error fetching SharePoint folders:", error);
      }
    }
  };

  //handle selected deal
  const handleSelectedDeal = (e, { value }) => {
    var newValue = typeof value == "object" ? value : {};
    setSelectedDeal(newValue);
    setIsValidDeal(Object.keys(newValue).length > 0);
    // setDealID(value.id);
    // setDealName(value.title);
  };

  const onSearchFetch = () => {
    if (!receivedClients) {
      fetchClients();
      hasReceivedClients(true);
    }
  };

  useEffect(() => {
    const fetchPipelines = async () => {
      var getPipelines = `${functionSite}GetAllPipelines?code=${mkey}`;
      let response = await axios.get(getPipelines);
      // console.log("Pipelines", response.data);
      setPipelines(response.data.data);
    };
    const fetchAdvisers = async () => {
      const getAdvisers = `${functionSite}GetPipedriveAdvisers?code=${mkey}`;
      let response = await axios.get(getAdvisers);
      // console.log("Advisers", response.data);
      setAdvisers(response.data.advisers.filter((v) => v.active_flag));
    };
    const fetchActivityTypes = async () => {
      const getActivityTypes = `${functionSite}GetActivityTypes?code=${mkey}`;
      let response = await axios.get(getActivityTypes);
      // console.log("Activity types", response.data);
      setActivityTypes(response.data.data.filter((v) => v.active_flag));
    };
    fetchAdvisers();
    fetchActivityTypes();
    fetchPipelines();
  }, []);

  // useEffect(() => {
  //   console.log("Tasks", tasks);
  // }, [tasks]);

  const dynamicsWebApi = new DynamicsWebApi({
    serverUrl: serverUrl,
    dataApi: {
      version: "9.1",
    },
    useEntityNames: false,
    onTokenRefresh: (dynamicsWebApiCallback) => {
      instance
        .acquireTokenSilent({
          scopes: [`${serverUrl}/user_impersonation`],
          account: accounts[0],
        })
        .then((response) => {
          dynamicsWebApiCallback(response.accessToken);
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
  });

  // useEffect(() => {
  const fetchClients = async () => {
    var fetchXml =
      '<fetch mapping="logical">' +
      `<entity name="${clientSchema.name}">` +
      `<attribute name="${clientSchema.client_name}"/>` +
      `<attribute name="${clientSchema.ibroker}"/>` +
      `<attribute name="${clientSchema.pipedrive_id}"/>` +
      `<attribute name="${clientSchema.hubspot_id}"/>` +
      `<attribute name="${clientSchema.id}"/>` +
      "</entity>" +
      "</fetch>";

    dynamicsWebApi
      .executeFetchXmlAll(clientSchema.plural, fetchXml)
      .then(function (response) {
        console.log(response.value);
        var records = response.value.filter(
          (record) => record[clientSchema.pipedrive_id] != null
        );
        setClients(records);
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });

    //need to add a check if client has I broker go down client otherwise go down prospect
  };
  // fetchClients();
  // }, []);

  //get deals from selected organisation
  useEffect(() => {
    const fetchDeals = async () => {
      setSelectedDeal({});
      setLoadingDeals(true);
      var getDeals = `${functionSite}GetPipedriveDealsByOrganisation?code=${mkey}`;
      let response = await axios.get(getDeals, {
        params: {
          // pipedrive_id: parseInt(selectedClient.cr749_pipedriveid)
          pipedrive_id: parseInt(selectedClient[clientSchema.pipedrive_id]),
        },
      });
      // console.log("Deals", response.data.deals);
      setDeals(response.data.deals);
      setLoadingDeals(false);
    };
    // if ("cr749_pipedriveid" in selectedClient) {
    if (clientSchema.pipedrive_id in selectedClient) {
      fetchDeals();
    } else {
      setDeals([]);
    }
  }, [selectedClient]);

  // Adviser change
  const handleAdviserChange = (e, { value }) => {
    var newValue = typeof value == "object" ? value : {};
    var isValid = Object.keys(newValue).length > 0;
    setSelectedAdviser(newValue);
    setIsValidAdviser(isValid);
  };

  const checkClientSite = async (
    formDigestValue,
    headers,
    accessToken,
    clientName,
    noteWithDate
  ) => {
    return await axios
      .get(`${sharepointSite}/sites/clients-${clientName}/_api/web/url')`, {
        headers: { ...headers, "X-RequestDigest": formDigestValue },
      })
      .then(async (response) => {
        if (JSON.stringify(response.data.d).length > 0) {
          console.log(response.data.d.Url);
          const clientUrl = response.data.d.Url;
          console.log(clientUrl);

          // Prepare the request URL attach client
          const folder = selectedFolder.length > 0 ? selectedFolder : 'General'
          const clienturl = `${clientUrl}/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents/${folder}')/Files/add(url='${noteSubject}.pdf',overwrite=true)`;
          return await generatePDFAndUpload(
            noteWithDate,
            formDigestValue,
            accessToken,
            clienturl,
          );
        } else {
          console.log("There is no valid url:", response);
        }
      })
      .catch((err) => {
        console.log("Client site doesn't exist:", err);
      });
  };

  const checkClientFolderURL = async (
    formDigestValue,
    headers,
    accessToken,
    clientName,
    noteWithDate
  ) => {
    const clientFolderUrl = `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents/${clientName}')`;
    return await axios
      .get(clientFolderUrl, {
        headers: { ...headers, "X-RequestDigest": formDigestValue },
      })
      .then(async (response) => {
        if (response.data.d.toString().length > 0) {
          console.log("Client exists in client folder");
          // setIsClient(true);
          return await generatePDFAndUpload(
            noteWithDate,
            formDigestValue,
            accessToken,
            `${clientFolderUrl}/Files/add(url='${noteSubject}.pdf',overwrite=true)`,
            "client"
          );
        } else {
          // setIsClient(false);
        }
      })
      .catch((err) => {
        console.log("there was an err checking the client folder", err);
        // setIsClient(false);
      });
  };

  const checkProspectsFolderURL = async (
    formDigestValue,
    headers,
    accessToken,
    clientName,
    noteWithDate
  ) => {
    const prospectsUrl = `${sharepointSite}/sites/Clients/_api/web/GetFolderByServerRelativeUrl('Prospects/${clientName}')`;
    return await axios
      .get(prospectsUrl, {
        headers: { ...headers, "X-RequestDigest": formDigestValue },
      })
      .then(async (response) => {
        if (response.data.d.toString().length > 0) {
          console.log(response.data.d);
          return await generatePDFAndUpload(
            noteWithDate,
            formDigestValue,
            accessToken,
            `${prospectsUrl}/Files/add(url='${noteSubject}.pdf',overwrite=true)`,
            'prospect'
          );
        }
      })
      .catch((err) => console.log(err, "error getting prospect folder"));
  };

  const addToSharepoint = async (noteWithDate, clientName) => {
    console.log('here is the clientName: ', clientName);
    return await instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then(async (response) => {
        const accessToken = response.accessToken;
        const headers = {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${accessToken}`,
        };
        // get webcontext and digest value
        return await axios
          .post(`${sharepointSite}/_api/contextinfo`, null, {
            headers: headers,
          })
          .then(async (response) => {
            const formDigestValue =
              response.data.d.GetContextWebInformation.FormDigestValue;
            var fileId;
            //sharepoint url searching doesn't like ' so replace
            var spacesRemovedName = clientName
              .split(" ")
              .join("")
              .replace("'", "%27");
            console.log(spacesRemovedName);
            const validClientSiteName = spacesRemovedName.replace(
              /[^A-Za-z0-9_\-/]/g,
              ""
            );

  
            const validClientFolderName = clientName.replace(/[/]/g, "");
            console.log(
              "here is the validClientfolder name: ",
              validClientFolderName
            );

            const oldClientName = selectedClient.cr749_companyname.replace(
              /[^A-Za-z0-9_\-/]/g,
              ""
            ).split(" ").join("");
            // Check for client site
            console.log("Check for client site", clientName);
            fileId = await checkClientSite(
              formDigestValue,
              headers,
              accessToken,
              oldClientName,
              noteWithDate
            );
            if (fileId == null) {
              console.log("Check for client site", validClientSiteName);
              fileId = await checkClientSite(
                formDigestValue,
                headers,
                accessToken,
                validClientSiteName,
                noteWithDate
              );
            }
            if (fileId == null) {
              console.log("Check for client folder URL", clientName);
              fileId = await checkClientFolderURL(
                formDigestValue,
                headers,
                accessToken,
                clientName,
                noteWithDate
              );
            }
            if (fileId == null) {
              console.log(
                "Check for client folder URL removed special characters",
                validClientFolderName
              );
              fileId = await checkClientFolderURL(
                formDigestValue,
                headers,
                accessToken,
                validClientFolderName,
                noteWithDate
              );
            }
            if (fileId == null) {
              console.log("Check for prospects URL", clientName);
              fileId = await checkProspectsFolderURL(
                formDigestValue,
                headers,
                accessToken,
                clientName,
                noteWithDate
              );
            }
            if (fileId == null) {
              const encodedClientName = encodeURIComponent(
                clientName.replace("'", "%27")
              );
              console.log("Check for prospects URL encoded", encodedClientName);
              fileId = await checkProspectsFolderURL(
                formDigestValue,
                headers,
                accessToken,
                encodedClientName,
                noteWithDate
              );
            }
          })
          .catch((err) => console.error("error getting digest value", err));
      })
      .catch((err) => {
        console.log("there was an error getting the access token", err);
      });
  };

  const uploadNoteToDataverse = async (
    noteName,
    noteBody,
    isTask,
    date,
    clientName,
    deal = {},
    adviser = {}
  ) => {
    var entity;

    var newNote = {};
    newNote[notesSchema.client_name] = clientName;
    if (Object.keys(deal).length > 0) {
      newNote[notesSchema.deal_name] = deal.title;
      newNote[notesSchema.deal_id] = `${deal.id}`;
    }
    newNote[notesSchema.note_title] = noteName ?? "";
    newNote[notesSchema.note_body] = noteBody ?? "";
    newNote[notesSchema.is_task] = isTask;
    newNote[notesSchema.note_date] = `${date}` ?? "";
    if (Object.keys(adviser).length > 0) {
      newNote[notesSchema.assigned_to_email] = adviser.email;
    }

    var request = {
      collection: notesSchema.plural,
      entity: newNote,
      returnRepresentation: true,
    };

    console.log("Upload note request", request);
    //call dynamicsWebApi.createRequest function
    entity = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        return entity;
      })
      .catch(function (error) {
        console.log("error creating note: ", JSON.stringify(error));
      });
  };


  const updateFileMetadata = async (fileUniqueId, metadata, clientType) => {
    const formDigestValue = await getFormDigestValue();
    const headers = await getSharepointHeaders();

    try {
        // Construct the URL for updating metadata using file unique ID
        const fileListItemUrl = clientType === "client" ? `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFileById('${fileUniqueId}')/ListItemAllFields` : `${sharepointSite}/sites/Clients/_api/web/GetFileById('${fileUniqueId}')/ListItemAllFields`;
        // Construct the metadata update payload
        const metadataUpdatePayload = JSON.stringify({
            '__metadata': { 'type': 'SP.ListItem' },
            ...metadata
        });

        // Update the list item with new metadata
        const updateResponse = await axios.post(fileListItemUrl, metadataUpdatePayload, {
            headers: {
                ...headers,
                'X-RequestDigest': formDigestValue,
                'X-HTTP-Method': 'MERGE',
                'IF-MATCH': '*'
            }
        });
        return updateResponse.data;
    } catch (error) {
        console.error('🙅 ~ Error updating SharePoint file metadata:', error);
    }
};

  const generatePDFAndUpload = async (
    textInput,
    digestValue,
    accessToken,
    url,
    clientType
  ) => {
    const doc = new jsPDF({
      unit: "mm",
      format: "a4",
    });
  
    const pdfWidth = doc.internal.pageSize.width;
    const lineHeight = 6;
    const lines = textInput.split("\n");
    let yPosition = 15;
    lines.forEach((line, index) => {
      const textLines = doc.splitTextToSize(line, pdfWidth - 30);
      if (index === 0) {
        doc.text(textLines, 15, yPosition);
      } else {
        yPosition += lineHeight;
        doc.text(textLines, 15, yPosition);
      }
      yPosition += (textLines.length - 1) * lineHeight;
    });
  
    const fileContent = doc.output("arraybuffer");
    const fileBlob = new Blob([fileContent], { type: "application/pdf" });
  
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "X-RequestDigest": digestValue,
    };
  
    const data = new FormData();
    data.append("file", fileBlob);
    try {
      // Post the file without setting content type ID in FormData
      const fileResponse = await axios.post(url, data, {
        headers,
      });
      
      console.log('here is the created file reponse: ', fileResponse);
      const serverUrl = fileResponse.data.UniqueId;
      if (selectedTag !== null) {
        const updateRes = await updateFileMetadata(serverUrl, {"ContentTypeId": selectedTag}, clientType);
        console.log('here is the updateRes: ', updateRes);
      }
      return fileResponse;
    } catch (error) {
      console.log("Error creating file:", error);
      sendTeamsMessage(
        instance,
        accounts,
        true,
        `Error creating PDF: ${error.message}`
      );
    }
  };
  
  const setTaskObject = (index, newObject) => {
    var oldTasks = Array.from(tasks);
    oldTasks.splice(index, 1, newObject);
    setTasks(oldTasks);
  };

  const createTask = async (body, date, taskType, dealID, adviserID) => {
    try {
      const addNoteToOrg = `${functionSite}AddActivityToDeal?code=${mkey}`;
      const reqData = {
        content: body,
        deal_id: dealID,
        date: date,
        type: taskType,
        adviser_id: adviserID,
      };
      const response = await axios.post(addNoteToOrg, reqData);
      console.log(response);
    } catch (err) {
      console.log("There was an error adding note", err);
      sendTeamsMessage(
        instance,
        accounts,
        true,
        `There was an error adding the Activity <br> ${err.message}`
      );
    }
  };

  const createDeal = async (dealName, client, pipeline, adviser) => {
    try {
      const createDealURL = `${functionSite}CreatePipedriveDeal?code=${mkey}`;
      const reqData = {
        name: dealName,
        owner_id: parseInt(adviser.id),
        pipedrive_id: parseInt(client.id),
        pipeline_id: parseInt(pipeline.id),
      };
      const response = await axios.post(createDealURL, reqData);
      console.log("Create deal", response);
      return response;
    } catch (err) {
      console.log("There was an error creating deal", err);
      sendTeamsMessage(
        instance,
        accounts,
        true,
        `There was an error adding a new Deal for ${client.name} <br> ${err.message}`
      );
    }
  };

  const resetDeal = (e) => {
    e.preventDefault();
    setIsNewDeal(false);
    setDealName("");
    setIsValidDeal(true);
    setIsValidDealName(true);
    setSelectedPipeline({});
    setIsValidPipeline(true);
    setSelectedDeal({});
    setSelectedAdviser({});
    setIsValidAdviser(true);
  };

  const validateFields = () => {
    setIsValidClient(Object.keys(selectedClient).length > 0);
    setIsValidNote(note.length > 0);
    setIsValidDate(currentDate.length > 0);
    setIsValidSubject(noteSubject.length > 0);

    setIsValidDealName(dealName.trim().length > 0);
    setIsValidPipeline(Object.keys(selectedPipeline).length > 0);
    setIsValidAdviser(Object.keys(selectedAdviser).length > 0);

    var isValid =
      isValidClient &&
      (tasks.length > 0 || (isValidNote && isValidDate && isValidSubject)) &&
      (!isNewDeal || (isValidDealName && isValidPipeline && isValidAdviser));

    for (var i = 0; i < tasks.length; i++) {
      var taskIsValid =
        tasks[i].due_date.length > 0 &&
        tasks[i].note.trim().length > 0 &&
        Object.keys(tasks[i].adviser).length > 0 &&
        Object.keys(tasks[i].type).length > 0;
      setTaskObject(i, { ...tasks[i], isValid: taskIsValid });
    }

    if (tasks.length > 0) {
      isValid &= tasks.every((v) => v.isValid);
    }

    if (isValid) {
      return true;
    } else {
      setFormState("error");
      return false;
    }
  };

  const handleFormReset = () => {
    setIsLoading(false);
    setSelectedClient({});
    setNote("");
    setSelectedDeal({});
    setIsValidDeal(true);
    setFoldersRetrieved([]);
    setSelectedFolder({});
    setSelectedTag("");
    setNewDate("");
    setIsValidClient(true);
    setIsValidNote(true);
    setIsValidDate(true);
    setIsValidSubject(true);
    setIsNewDeal(false);
    setDealName("");
    setSelectedPipeline({});
    setIsValidPipeline(true);
    setSubjectName("");
    setSelectedAdviser({});
    setIsValidAdviser(true);
    setTasks([]);
    setClients([]);
    hasReceivedClients(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateFields()) {
      setIsLoading(true);

      try {
        var noteWithDate = `${note} - ${currentDate}\n`;
        const clientName = selectedClient[clientSchema.client_name];
        var dealObject;

        if (isNewDeal) {
          dealObject = await createDeal(
            dealName,
            selectedClient,
            selectedPipeline,
            selectedAdviser
          );
          console.log("Made new deal", dealObject);
          noteWithDate = noteWithDate.concat(
            `New deal created: ${dealObject.id} ${dealName} - ${selectedPipeline.name}\n`
          );
        } else if (Object.keys(selectedDeal).length > 0) {
          dealObject = { ...selectedDeal };
          console.log("Existing deal", dealObject);
        }

        if (dealObject != null && Object.keys(dealObject).length > 0) {
          await Promise.all(
            tasks.map(async (task) => {
              console.log("task", task);
              await createTask(
                task.note,
                task.due_date,
                task.type.key_string,
                parseInt(dealObject.id),
                parseInt(task.adviser.id)
              );
              await uploadNoteToDataverse(
                task.note,
                true,
                task.due_date,
                clientName,
                dealObject,
                task.adviser
              );
              noteWithDate.concat(`Task created: ${task.type.name}\n`);
            })
          );
        }

        console.log(noteWithDate);
        //checks if selected folder other go by selected client name
        
        const clientFolderName =
          selectedFolder.length > 0 ? selectedFolder : selectedClient.cr749_companyname;

        
        console.log('here is the client folder name: ', clientFolderName);
        
        await addToSharepoint(noteWithDate, clientFolderName);
        if (note.length > 0 || noteSubject.length > 0) {
          await uploadNoteToDataverse(
            noteSubject,
            note,
            false,
            currentDate,
            clientName,
            dealObject
          );
        }
        // const sendTask = (dealId.length > 0 && saveToPipedrive == true) ? false : true;
        handleFormReset();
        setFormState("success");
      } catch (err) {
        console.log("Error", err);
        setFormState("error");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            Add a new Note
            <Header.Subheader>
              Record new notes, calls, or tasks to SharePoint and Pipedrive. If
              this is a Task, tick the box to save for tasking in Pipedrive.
            </Header.Subheader>
          </Header>
          <Divider inverted />
        </Container>
      </Segment>
      <Container text style={{ padding: "1em" }}>
        <Form
          method="post"
          loading={isLoading}
          onSubmit={handleSubmit}
          error={formState === "error"}
          success={formState === "success"}
        >
          <Form.Field required error={!isValidClient}>
            <label>Client Name Search</label>
            <Dropdown
              placeholder="Search client here"
              search
              selection
              className="dropdown-custom"
              options={clients.map((client) => ({
                key: client[clientSchema.id],
                value: client,
                text:
                  client[clientSchema.ibroker] != null
                    ? `${client[clientSchema.client_name]} ${
                        client[clientSchema.ibroker]
                      }`
                    : `${client[clientSchema.client_name]} | Pipedrive: ${
                        client[clientSchema.pipedrive_id]
                      }`,
              }))}
              value={
                Object.keys(selectedClient).length > 0
                  ? selectedClient
                  : noValueObject
              }
              onSearchChange={onSearchFetch}
              onChange={handleSelectedClient}
              error={!isValidClient}
            />
            {!isValidClient && (
              <Label prompt error pointing>
                Please select a client
              </Label>
            )}
          </Form.Field>
          <label
            style={{
              width: "100%",
              display: "block",
              margin: " 0 0 .28571429rem 0",
              color: "rgba(0,0,0,.87)",
              fontSize: ".92857143em",
              fontWeight: 700,
              textTransform: "none",
            }}
          >
            Select Specific Folder (optional)
          </label>
          <Form.Group style={{ margin: "0 -.5em" }}>
            <Form.Field style={{ width: "100%" }}>
              <Dropdown
                disabled={foldersRetrieved.length === 0}
                loading={loadingFolders}
                placeholder={
                  loadingFolders
                    ? "Loading folders ..."
                    : foldersRetrieved.length === 0
                    ? "No subfolders found"
                    : "Pick a folder"
                }
                clearable
                selection
                value={
                  Object.keys(selectedFolder).length === 0
                    ? noValueObject
                    : selectedFolder
                }
                options={foldersRetrieved.map((folder) => ({
                  key: folder,
                  value: folder,
                  text: folder,
                }))}
                onChange={handleSelectedFolder}
                // error={!isValidFolder}
              />
            </Form.Field>
          </Form.Group>
          <label  style={{
              width: "100%",
              display: "block",
              margin: " 0 0 .28571429rem 0",
              color: "rgba(0,0,0,.87)",
              fontSize: ".92857143em",
              fontWeight: 700,
              textTransform: "none",
            }}>Select Tag type (old client sites aren't taggable)</label>
          <Form.Group style={{ margin: "0 -.5em" }}>
            <Form.Field style={{ width: "100%" }}>
              <Dropdown
                disabled={tagsRetrieved.length === 0}
                loading={loadingFolders}
                placeholder={
                  loadingFolders
                    ? "Loading tags..."
                    : foldersRetrieved.length === 0
                    ? "No tag found"
                    : "Pick a tag"
                }
                clearable
                selection
                value={selectedTag}
                options={tagsRetrieved.map((tag) => ({
                  key: tag.id,
                  value: tag.id,
                  text: tag.name,
                }))}
                onChange={handleSelectedTag}
                // error={!isValidFolder}
              />
            </Form.Field>
          </Form.Group>
          {!isNewDeal && (
            <>
              <label
                style={{
                  width: "100%",
                  display: "block",
                  margin: " 0 0 .28571429rem 0",
                  color: "rgba(0,0,0,.87)",
                  fontSize: ".92857143em",
                  fontWeight: 700,
                  textTransform: "none",
                }}
              >
                Pipedrive Deal
              </label>
              <Form.Group style={{ margin: "0 -.5em" }}>
                <Form.Field style={{ width: "100%" }}>
                  <Dropdown
                    disabled={deals.length === 0}
                    loading={loadingDeals}
                    placeholder={
                      loadingDeals
                        ? "Loading available deals ..."
                        : Object.keys(selectedClient).length === 0
                        ? "Select a client to see available deals"
                        : deals.length === 0
                        ? "No deals associated with this client"
                        : "Pick an associated deal"
                    }
                    clearable
                    selection
                    value={
                      Object.keys(selectedDeal).length === 0
                        ? noValueObject
                        : selectedDeal
                    }
                    options={deals.map((deal) => ({
                      key: deal.id,
                      value: deal,
                      text: `${deal.id} | ${deal.title}`,
                    }))}
                    onChange={handleSelectedDeal}
                    // error={!isValidDeal}
                  />
                </Form.Field>
                <Button
                  size="mini"
                  disabled={Object.keys(selectedClient).length === 0}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsNewDeal(true);
                    setSelectedDeal({});
                    setIsValidDeal(true);
                  }}
                >
                  Create New Deal
                </Button>
              </Form.Group>
              {/* {!isValidDeal && <Label prompt error pointing>Please select a deal</Label>} */}
            </>
          )}

          {isNewDeal && (
            <Segment style={{ margin: "1.5em 0em" }}>
              <Header as="h5">
                Create new Deal{" "}
                <Button
                  basic
                  icon="times"
                  size="mini"
                  floated="right"
                  style={{ boxShadow: "none" }}
                  onClick={resetDeal}
                />
              </Header>
              <Form.Input
                required={isNewDeal}
                fluid
                label="Deal name"
                placeholder="Enter a name for the new deal"
                name="dealName"
                value={dealName}
                onChange={(e) => {
                  setDealName(e.target.value);
                  setIsValidDealName(e.target.value.trim().length > 0);
                }}
                autoComplete="off"
                error={
                  !isValidDealName
                    ? { content: "Please enter a deal name", pointing: "above" }
                    : null
                }
              />
              <Form.Field required={isNewDeal}>
                <label>Pipeline</label>
                <Dropdown
                  // disabled={pipelines.length === 0}
                  placeholder="Select a pipeline for the new deal"
                  clearable
                  selection
                  value={
                    Object.keys(selectedPipeline).length === 0
                      ? noValueObject
                      : selectedPipeline
                  }
                  options={pipelines.map((pipeline) => ({
                    key: pipeline.id,
                    value: pipeline,
                    text: `${pipeline.name}`,
                  }))}
                  onChange={(e, { value }) => {
                    setSelectedPipeline(typeof value == "object" ? value : {});
                    setIsValidPipeline(
                      typeof value == "object" && Object.keys(value).length > 0
                    );
                  }}
                  error={!isValidPipeline}
                />
                {!isValidPipeline && (
                  <Label prompt error pointing>
                    Please select a pipeline
                  </Label>
                )}
              </Form.Field>
              <Form.Field required={isNewDeal}>
                <label>Deal owner</label>
                <Dropdown
                  placeholder="Please select a deal owner"
                  required
                  selection
                  search
                  className="dropdown-custom"
                  options={advisers.map((adviser) => ({
                    key: adviser.id,
                    value: adviser,
                    text: adviser.name,
                  }))}
                  value={
                    Object.keys(selectedAdviser).length === 0
                      ? noValueObject
                      : selectedAdviser
                  }
                  onChange={handleAdviserChange}
                  error={!isValidAdviser}
                />
                {!isValidAdviser && (
                  <Label prompt error pointing>
                    Please select a deal owner
                  </Label>
                )}
              </Form.Field>
            </Segment>
          )}

          {tasks.map((_, index) => (
            <Task
              key={index}
              tasks={tasks}
              index={index}
              taskObject={tasks[index]}
              setTaskObject={setTaskObject}
              advisers={advisers}
              activityTypes={activityTypes}
              deleteTaskFunction={(e) => {
                e.preventDefault();
                setTasks(tasks.filter((_, i) => i !== index));
              }}
            ></Task>
          ))}

          <Button
            fluid
            color="green"
            style={{ "margin-top": "1.5em" }}
            disabled={
              Object.keys(selectedDeal).length === 0 &&
              !(
                dealName.trim().length > 0 &&
                Object.keys(selectedPipeline).length > 0
              )
            }
            ref={taskButtonRef}
            onClick={(e) => {
              e.preventDefault();
              setTasks([
                ...tasks,
                {
                  note: "",
                  type: {},
                  due_date: "",
                  subject: "",
                  adviser: {},
                  isValid: true,
                },
              ]);
              taskButtonRef.current.ref.current.blur();
            }}
          >
            <Icon name="plus" />
            Add Pipedrive Task
          </Button>

          <Segment style={{ margin: "1.5em 0em" }}>
            <Form.Input
              fluid
              label="Note Subject"
              type="text"
              placeholder="Please enter a subject for the note"
              name="noteTitle"
              autoComplete="off"
              value={noteSubject}
              onChange={(e) => {
                setSubjectName(e.target.value);
              }}
              error={
                !isValidSubject && tasks.length === 0
                  ? {
                      content: "Please enter a subject for the note",
                      pointing: "above",
                    }
                  : null
              }
              required={tasks.length === 0}
            />
            <Form.Field
              required={tasks.length === 0}
              error={!isValidNote && tasks.length === 0}
            >
              <label>Note</label>
              <TextArea
                id="note"
                name="note"
                rows={4}
                cols={40}
                onChange={handleNoteChange}
                value={note}
                error={!isValidNote && tasks.length === 0}
              />
              {!isValidNote && tasks.length === 0 && (
                <Label prompt error pointing>
                  This field cannot be empty
                </Label>
              )}
            </Form.Field>
            <Form.Field
              required={tasks.length === 0}
              error={!isValidDate && tasks.length === 0}
            >
              <label>Date</label>
              <SemanticDatepicker
                onChange={onChange}
                error={!isValidDate && tasks.length === 0}
              />
            </Form.Field>
            {!isValidDate && tasks.length === 0 && (
              <Label
                prompt
                error
                pointing
                style={{
                  whiteSpace: "normal",
                  background: "#fff",
                  border: "1px solid #e0b4b4",
                  color: "#9f3a38",
                  marginTop: "0",
                }}
              >
                This field cannot be empty
              </Label>
            )}
          </Segment>
          <Message
            success
            header="Added Note"
            content="Successfully added a new Note"
          />
          <Message
            error
            header="Error"
            content={
              formState === "error"
                ? "Please ensure you have filled out all the fields"
                : "There was an issue; please try again"
            }
          />
          <Button
            color="green"
            loading={isLoading}
            fluid
            type="submit"
            content="Create"
            style={{ margin: "2em 0em 0.5em" }}
            onClick={validateFields}
          >
            Save Note
          </Button>
          <Button basic fluid type="reset" onClick={handleFormReset}>
            Reset
          </Button>
        </Form>
      </Container>
    </>
  );
};
