import * as React from "react";
import { Button, Form, TextArea, Dropdown, Checkbox, Segment, Header, Divider, Container, Label, Message, Icon, Input} from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { noValueObject } from "../constants";

export const Task = ({tasks, index, setTaskObject, taskObject, advisers, activityTypes, deleteTaskFunction }) => {

    //const [isValidSubject, setIsValidSubject] = useState(true);
    const [isValidDate, setIsValidDate] = useState(true);
    const [isValidNote, setIsValidNote] = useState(true);
    const [isValidAdviser, setIsValidAdviser] = useState(true);
    const [isValidActivityType, setIsValidActivityType] = useState(true);

    // Changes and formats date
    const onDateChange = (e, data) => {
        const selectedDate = data.value instanceof Date ? data.value : "";
        const formattedDate = selectedDate ? selectedDate.toLocaleDateString('en-NZ', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/\//g, '-') : "";
        
        var isValid = formattedDate.length > 0;
        setIsValidDate(isValid);
        isValid &= isValidNote && isValidActivityType && isValidAdviser;
        setTaskObject(index, {...taskObject, due_date: formattedDate, isValid: isValid });
    };

    // Note change 
    const handleNoteChange = (e, { value }) => {
        var isValid = value.trim().length > 0;
        setIsValidNote(isValid);
        isValid &= isValidDate && isValidActivityType && isValidAdviser;
        setTaskObject(index, {...taskObject, note: value, isValid: isValid});
    };

    /* Subject change
    const handleSubjectChange = (e, { value }) => {
        var isValid = value.trim().length > 0;
        setIsValidSubject(isValid);
        isValid &= isValidDate && isValidNote && isValidActivityType && isValidAdviser;
        setTaskObject(index, {...taskObject, subject: value, isValid: isValid});
    }; */

    // Adviser change
    const handleAdviserChange = (e, { value }) => {
        var newValue = typeof value == "object" ? value : {};
        var isValid = Object.keys(newValue).length > 0;
        setIsValidAdviser(isValid);
        isValid &= isValidNote && isValidActivityType && isValidDate;
        setTaskObject(index, {...taskObject, adviser: newValue, isValid: isValid});
    };

    // Activity type change
    const handleActivityTypeChange = (e, { value }) => {
        var newValue = typeof value == "object" ? value : {};
        var isValid = Object.keys(newValue).length > 0;
        setIsValidActivityType(isValid);
        isValid &=  isValidNote && isValidDate && isValidAdviser;
        setTaskObject(index, {...taskObject, type: newValue, isValid: isValid});
    };

    return (<Segment style={taskObject.isValid ? null : { border: "1px solid #e0b4b4"}}>
            <Header as="h5" style={taskObject.isValid ? null : { color: "#9f3a38" }}>Create a task {tasks.length > 1 ? `(${index+1})` : ""}
                <Button basic icon="times" size="mini" floated="right" style={{ boxShadow: "none" }} onClick={deleteTaskFunction}/>
            </Header>
          {/* <Form.Input
              fluid
              label="Task Subject"
              type="text"
              placeholder="Please enter a subject for the task"
              name={`task${index}Subject`}
              autoComplete="off"
              value={taskObject.subject}
              onChange={handleSubjectChange}
              error={!isValidSubject ? {content: "Please enter a subject for this task", pointing: "above"} : null}
              required
/> */} 
            <Form.Field required>
                <label>Task type</label>
                <Dropdown
                    placeholder="Please select a task type"
                    required
                    selection
                    className="dropdown-custom"
                    options={activityTypes.map((activityType) => ({ key: activityType.id, value: activityType, text: activityType.name }))}
                    value={Object.keys(taskObject.type).length === 0 ? noValueObject : taskObject.type}
                    onChange={handleActivityTypeChange}
                    error={!isValidActivityType}
                />
                {!isValidActivityType && <Label prompt error pointing>Please select a task type</Label>}
            </Form.Field>
            <Form.Field required error={!isValidNote}>
                <label>Note</label>
                <TextArea 
                    id="note" 
                    name="note" 
                    rows={4} 
                    cols={40}
                    value={taskObject.note}
                    onChange={handleNoteChange}
                    error={!isValidNote}
                />
                {!isValidNote && <Label prompt error pointing>This field cannot be empty</Label>}
            </Form.Field>
            <Form.Field required>
                <label>User</label>
                <Dropdown
                    placeholder="Please select a user to assign task to"
                    required
                    selection
                    search
                    className="dropdown-custom"
                    options={advisers.map((adviser) => ({ key: adviser.id, value: adviser, text: adviser.name }))}
                    value={Object.keys(taskObject.adviser).length === 0 ? noValueObject : taskObject.adviser}
                    onChange={handleAdviserChange}
                    error={!isValidAdviser}
                />
                {!isValidAdviser && <Label prompt error pointing>Please select a user to assign task to</Label>}
            </Form.Field>
            <Form.Field required>
                <label>Due Date</label>
                <SemanticDatepicker onChange={onDateChange} error={!isValidDate} />
            </Form.Field>
            {!isValidDate && <Label prompt error pointing style={{whiteSpace: "normal", background: "#fff", border: "1px solid #e0b4b4", color: "#9f3a38", marginTop: "0"}}>This field cannot be empty</Label>}
            {!(taskObject.isValid) && (
                <Label fluid error style={{whiteSpace: "normal", background: "#fff", border: "1px solid #e0b4b4", color: "#9f3a38", width: "100%"}}>
                    Please complete the fields in this task before continuing
            </Label>)}
          </Segment>)
}