import React, { useState, useEffect } from "react";
import { Input, Label } from "semantic-ui-react";
import { Header } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";

const RenewalDatePicker = ({
  fieldName,
  onChange,
  date,
}) => {

  const [selectedDate, setSelectedDate] = useState(
    date
  );

  const handleDateChange = (event, data) => {
    const newDate = new Date(data.value);
    setSelectedDate(newDate);
    onChange(fieldName, data.value);
  };


  return (
    <div
      style={{ marginBottom: "1em", display: "flex", flexDirection: "column" }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <SemanticDatepicker
          onChange={handleDateChange}
          value={selectedDate}
        />
      </div>
    </div>
  );
};

export default RenewalDatePicker;
