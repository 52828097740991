import React, { createContext, useContext, useState, useEffect } from "react";
import DynamicsWebApi from "dynamics-web-api";
import { useMsal } from "@azure/msal-react";
import { dynamicsParams, dynamicsWebApiCallback } from "../../authConfig";
import { renewalInstance, renewalMeetingInstance } from "../../tableSchema";
import { functionSite, mkey } from "../../constants";
import axios from "axios";

const DataContext = createContext();

export const useDataContext = () => useContext(DataContext);
export const DataProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);



  const [referralList, setReferralList] = useState([]);
  const [asbValueList, setAsbValueList] = useState([]);
  const [asbDivisionList, setAsbDivisionList] = useState([]);
  const [asbCommentaryList, setAsbCommentaryList] = useState([]);


  const fetchPipedriveDealFields = async (listName, callback) => {
    try {
      const getAdvisers = `${functionSite}GetAllDealFields?code=${mkey}`;
      const response = await axios.get(getAdvisers);
      const referral = response.data.data.find(
        (item) => item.name === listName
      );
      if (referral && referral.options) {
        const options = referral.options.reduce((acc, option) => {
            acc[option.label] = option.id;
            return acc;
          }, {});
          
          const optionsArray = Object.entries(options);
          const sortedOptions = optionsArray.sort(([keyA], [keyB]) => {
            const nameA = keyA.toUpperCase();
            const nameB = keyB.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          
          const sortedOptionsObject = Object.fromEntries(sortedOptions);
        callback(sortedOptionsObject);
      }
    } catch (error) {
      console.error("Error fetching deal fields: ", error);
    }
  };

  useEffect(() => {
    fetchPipedriveDealFields("ORIGINATION SOURCE", setReferralList);
    fetchPipedriveDealFields("ASB Values", setAsbValueList);
    fetchPipedriveDealFields("ASB Division", setAsbDivisionList);
    fetchPipedriveDealFields("ASB Commentary", setAsbCommentaryList);
  }, []);

  
  return (
    <DataContext.Provider
      value={{ referralList, asbValueList, asbDivisionList, asbCommentaryList}}
    >
      {children}
    </DataContext.Provider>
  );
};
