import React from "react";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../authConfig";
import { Button } from "semantic-ui-react";

export const SignInButton = ({showFluid, showInverted}) => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }

    return (
        <Button inverted={showInverted == null || showInverted === true} fluid={showFluid == null || showFluid === true} basic onClick={() => handleLogin("redirect")}>Sign In</Button>
    )
}