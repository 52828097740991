import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Form, Dropdown, Button, Segment, Container, Header, Divider, Label, Message, Icon, } from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import axios from "axios";
import { CompaniesSearch } from "./CompaniesSearch";
import { mkey, functionSite, clientSite, sharepointSite, noValueObject } from "../constants";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { businessLinesSchema, clientSchema, individualSchema, relationshipSchema } from "../tableSchema";
import { sendTeamsMessage } from "../teams";

export const NameChangeForm = () => {
  const { instance, accounts } = useMsal();
  const [isloading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  
  const [newName, setNewName] = useState("");

  const [successMessage, setSuccessMessage] = useState("");

  // Validation
  const [isValidClient, setIsValidClient] = useState(true);
  const [isValidName, setIsValidName] = useState(true);

  // Form validation
  const [formState, setFormState] = useState("");

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => { dynamicsWebApiCallback(instance, accounts, callback); }
});

  useEffect(() => {
    const fetchClients = async () => {
      var fetchXml = '<fetch mapping="logical">' +
        `<entity name="${clientSchema.name}">` +
        `<attribute name="${clientSchema.client_name}"/>` +
        `<attribute name="${clientSchema.ibroker}"/>` +
        `<attribute name="${clientSchema.pipedrive_id}"/>` +
        `<attribute name="${clientSchema.hubspot_id}"/>` +
        `<attribute name="${clientSchema.id}"/>` +
        '</entity>' +
        '</fetch>';
    
        dynamicsWebApi.executeFetchXmlAll(clientSchema.plural, fetchXml).then(function (response) {
          console.log(response.value);
          var records = response.value.filter((record) => record.cr749_pipedriveid != null);
          setClients(records);
      }).catch(function (error) {
          console.log(JSON.stringify(error));
      });
  };  
      fetchClients();
  }, []);

  // useEffect(() => {
  //     setSelectedClient(clients.length > 0 ? clients[0] : {});
  // }, [clients]);

  const updatePipedrive = async () => {
      var endpoint = `${functionSite}UpdatePipedriveOrganisation?code=${mkey}`;
      // if (selectedClient.cr749_pipedriveid != null && selectedClient.cr749_pipedriveid.length !== 0) {
      if (selectedClient[clientSchema.pipedrive_id] != null && selectedClient[clientSchema.pipedrive_id].length !== 0) {
        const intPipedriveid = parseInt(selectedClient[clientSchema.pipedrive_id]); 
        const reqData = {
          new_name: newName.trim(),
          pipedrive_id: intPipedriveid,
          ibroker: selectedClient[clientSchema.ibroker]
        };
          let response = await axios.post(endpoint, 
              // { params: {new_name: newName.trim(), pipedrive_id: selectedClient.cr749_pipedriveid, ibroker: selectedClient.cr749_ibrokerid} }
              //{ params: {new_name: newName.trim(), pipedrive_id: selectedClient[clientSchema.pipedrive_id], ibroker: selectedClient[clientSchema.ibroker]} }
              reqData
          );
          return response.data;
      }
  };

  const updateHubspot = async () => {
    var endpoint = `${functionSite}UpdateHubspotOrganisation?code=${mkey}`;
    // if (selectedClient.cr749_hubspotid != null && selectedClient.cr749_hubspotid.length !== 0) {
    if (selectedClient[clientSchema.hubspot_id] != null && selectedClient[clientSchema.hubspot_id].length !== 0) {
      const inthubspot_id = parseInt(selectedClient[clientSchema.hubspot_id]); 
      const reqData = {
        hubspot_id: inthubspot_id,
        new_name: newName.trim()
      };

        let response = await axios.post(endpoint, reqData
            // { params: {new_name: newName.trim(), hubspot_id: selectedClient.cr749_hubspotid} }
            //{ params: {new_name: newName.trim(), hubspot_id: selectedClient[clientSchema.hubspot_id]} }
        );
        return response.data;
    }
  };


  const CreateAsanaTask = (taskName, note) => {
      
    try {
      const url = 'https://app.asana.com/api/1.0/tasks';
      const accessToken = '1/1202288361823701:74d41bcfe72f21116571eaed636b7b2c';
    
      const headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      };

      const data = {
        data: {
          projects: ['1202076835040470'],
          name: `Update ${taskName} with new Site URL in Sharepoint Admin center`,
          notes: note,
          assignee: '1202288361823701',
          workspace: '1152934542367444',
      },
    };
      const response = axios.post(url, data, { headers });
      console.log('Task created successfully: ', response);
    } catch (error) {
      console.error('Error creating task: ', error);
      sendTeamsMessage(instance, accounts, true, `There was an error creating the asana task <br> error: ${error.message}`);
    }
  }

  const updateBusinessLine = async () => {
    // var request = {
    //   collection: "cr749_db_businesslines",
    //   select: ["cr749_db_businesslineid"],
    //   filter: `_cr749_company_value eq ${selectedClient.cr749_db_companyid}`,
    //   count: true,
    // };
    var request = {
      collection: businessLinesSchema.plural,
      select: [businessLinesSchema.id],
      filter: `_${businessLinesSchema.client.toLowerCase()}_value eq ${selectedClient[clientSchema.id]}`,
      count: true,
    };

    //Get related business lines and update each business Line  record with new company name
    dynamicsWebApi.retrieveMultipleRequest(request).then((response) => {
        // var keyValuePair = { cr749_companyname_: newName.trim() };
        var keyValuePair = {}
        keyValuePair[businessLinesSchema.client_name] = newName.trim();
        
        response.value.forEach( async (record) => {
          // console.log(record.cr749_db_businesslineid);
          console.log(record[businessLinesSchema.id]);

          //perform an update single property operation
          dynamicsWebApi.updateSingleProperty(
            // record.cr749_db_businesslineid, 
            // "cr749_db_businesslines", 
            record[businessLinesSchema.id], businessLinesSchema.plural, keyValuePair
          ).then(() => {
              console.log("Successfully updated business line");
          }).catch((error) => {
              console.log("Error updating business line", error);
          });
        });
    })
    .catch(function (error) {
      console.log("Error retrieving business lines", error);
    });
  };

  const updateClientRelationship = async () => {
    //Get related Client Individual Relationships and update each client individual relationship
    // var request = {
    //   collection: "cr749_db_client_individual_relationships",
    //   select: ["cr749_db_client_individual_relationshipid", "_cr749_individual_value"],
    //   filter: `_cr749_client_value eq ${selectedClient.cr749_db_companyid}`,
    //   count: true,
    // };
    var request = {
      collection: relationshipSchema.plural,
      select: [relationshipSchema.id, `_${relationshipSchema.individual.toLowerCase()}_value`],
      filter: `_${relationshipSchema.client.toLowerCase()}_value eq ${selectedClient[clientSchema.id]}`,
      count: true,
    };

    dynamicsWebApi.retrieveMultipleRequest(request).then((response) => {
        // Update each relationship with new name
        response.value.forEach((record) => {
          console.log(record);
          // const client_individual_id = record.cr749_db_client_individual_relationshipid;
          // const individualValue = record._cr749_individual_value;

          // var individualRequest = {
          //   collection: "cr749_db_individuals",
          //   select: ["cr749_fullname"],
          //   filter: `cr749_db_individualid eq ${individualValue}`,
          //   count: true,
          // };
          const client_individual_id = record[relationshipSchema.id];

          var individualRequest = {
            collection: individualSchema.plural,
            select: [individualSchema.full_name],
            filter: `${individualSchema.id} eq ${record[`_${relationshipSchema.individual.toLowerCase()}_value`]}`,
            count: true,
          };

          //Get individual name
          dynamicsWebApi.retrieveMultipleRequest(individualRequest).then((response) => {
              response.value.forEach((record) => {
                // var keyValuePair = {
                //   cr749_name: `${newName.trim()} - ${record.cr749_fullname}`,
                // };
                var keyValuePair = {}
                keyValuePair[relationshipSchema.row_name] = `${newName.trim()} - ${record[individualSchema.full_name]}`;
                console.log(record);

                //perform an update single property operation
                // dynamicsWebApi.updateSingleProperty(client_individual_id, "cr749_db_client_individual_relationships", keyValuePair
                dynamicsWebApi.updateSingleProperty(client_individual_id, relationshipSchema.plural, keyValuePair
                  ).then(() => {
                    // console.log(`Successfully updated relationship to ${record.cr749_fullname}`);
                    console.log(`Successfully updated relationship to ${record[individualSchema.full_name]}`);
                  })
                  .catch((error) => {
                    console.log("Error updating relationship", error);
                  });
              });
            })
            .catch(function (error) {
              console.log("Error retrieving individual", error);
            });
        });
      })
      .catch(function (error) {
        console.log("Error fetching relationship", error);
      });
  };

  // entity: {
  //   cr749_sharepointsite: newUrl,
  //   cr749_companyname: newName.trim(),
  // }
  const updateClientRow = async (entity) => {
      // updates DB_client table in dataverse
      // var request = {
      //   key: selectedClient.cr749_db_companyid,
      //   collection: "cr749_db_companies",
      //   entity: entity,
      //   returnRepresentation: true,
      //   select: ["cr749_db_companyid"],
      // };
      var request = {
        key: selectedClient[clientSchema.id],
        collection: clientSchema.plural,
        entity: entity,
        returnRepresentation: true,
        select: [clientSchema.id],
      };

      dynamicsWebApi.updateRequest(request).then((response) => {
          console.log(`Successfully updated DB_client Row ${entity}`);
      })
      .catch((error) => {
          console.log("Error updating client row", error);
      });
  };

  const updateSharepoint = async () => {
    // const replacedExistingName = symbolRemovedString(selectedClient.cr749_companyname);
    const replacedExistingName = symbolRemovedString(selectedClient[clientSchema.client_name]);
    const replacedNewName = symbolRemovedString(newName.trim());
    
    instance.acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      }).then((response) => {
        const accessToken = response.accessToken;
        // console.log(`Bearer ${response.accessToken}`);
        const headers = {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${accessToken}`,
        };
        // Get form digest value
        axios.post(`${clientSite}/_api/contextinfo`, null, { headers: headers }).then((response) => {
            const formDigestValue = response.data.d.GetContextWebInformation.FormDigestValue;

            // Function to update list item in Client list
            const updateClientListItem = (url) => {
              const headersItem = {
                Accept: "application/json;odata=verbose",
                "X-HTTP-Method": "MERGE",
                "Content-Type": "application/json",
                "If-Match": "*",
                Authorization: `Bearer ${accessToken}`,
              };
              //Grabs item in client list by pipedrive id
              axios.get(
                  // `${clientSite}/_api/web/lists/getbytitle('Clients')/items?$filter=PipedriveReference eq '${selectedClient.cr749_pipedriveid}'`,
                  `${clientSite}/_api/web/lists/getbytitle('Clients')/items?$filter=Pipedrive_Reference eq '${selectedClient[clientSchema.pipedrive_id]}'`,
                  {
                    headers: {
                      ...headers,
                      "X-RequestDigest": formDigestValue,
                    },
                  }
                ).then((response) => {
                  console.log("List item", response.data.d.results[0]);

                  const itemId = JSON.stringify(response.data.d.results[0].Id);
                  console.log(itemId);

                  // Creates the new updated clientURL
                  var newUrl = url.replace(replacedExistingName, replacedNewName);
                  console.log(newUrl);

                  //Updates the list item
                  axios.post(`${clientSite}/_api/web/lists/getbytitle('Clients')/items(${itemId})`,
                      {
                        Title: replacedNewName,
                        ClientURL: newUrl,
                      },
                      {
                        headers: {
                          ...headersItem,
                          "X-RequestDigest": formDigestValue,
                        },
                      }
                  ).then(async (response) => {
                      console.log("The list item has been updated successfully");
                      var entity = {};
                      entity[clientSchema.sharepoint] = newUrl;
                      updateClientRow(entity);
                      // updateClientRow({ cr749_sharepointsite: newUrl });
                  }).catch((error) => {
                      console.log("There was an error updating the list item", error);
                      sendTeamsMessage(instance, accounts, true, `there was an error updating the list item <br> error:  ${error.message}`)
                  });
                }).catch((error) => {
                  console.log("Error retrieving list item", error);
                });
            };

            //Checks and then updates group scheme folder with new name
            axios.get(
                `${sharepointSite}/sites/GroupSchemeSite/_api/web/GetFolderByServerRelativeUrl('Group%20Scheme%20File/${replacedExistingName}')`,
                {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                }
            ).then((response) => {
                console.log("Get folder response here", response.data.d);
                //Checks if response has data if so update folder
                if (response.data.d.toString().length > 0) {
                  console.log("The folder exists in Sharepoint");

                  const headers2 = {
                    Accept: "application/json;odata=verbose",
                    "X-HTTP-Method": "MERGE",
                    "Content-Type": "application/json",
                    "If-Match": "*",
                    Authorization: `Bearer ${accessToken}`,
                  };
                  axios.post(
                    `${sharepointSite}/sites/GroupSchemeSite/_api/web/GetFolderByServerRelativeUrl('Group%20Scheme%20File/${replacedExistingName}')/ListItemAllFields`,
                    {
                      Title: replacedNewName,
                      FileLeafRef: replacedNewName,
                    },
                    {
                      headers: {
                        ...headers2,
                        "X-RequestDigest": formDigestValue,
                      },
                    }
                  );
                } else {
                  console.log("The folder does not exist");
                }
              })
              .catch((error) => {
                console.error("Error getting folder: ", error);
              });

            // Check prospects folder
            axios.get(
                `${clientSite}/_api/web/GetFolderByServerRelativeUrl('Prospects/${replacedExistingName}')`,
                {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                }
            ).then((response) => {
                console.log("Current url", response.data.d.ServerRelativeUrl);
                var prospectUrl = `${sharepointSite}${response.data.d.ServerRelativeUrl}`;

                //checks if folder exists in prospects folder
                if (response.data.d.toString().length > 0) {
                  console.log("the prospect folder exists");
                  const headersProspect = {
                    Accept: "application/json;odata=verbose",
                    "X-HTTP-Method": "MERGE",
                    "Content-Type": "application/json",
                    "If-Match": "*",
                    Authorization: `Bearer ${accessToken}`,
                  };

                  axios.post(
                      `${clientSite}/_api/web/GetFolderByServerRelativeUrl('Prospects/${replacedExistingName}')/ListItemAllFields`,
                      {
                        Title: replacedNewName,
                        FileLeafRef: replacedNewName,
                      },
                      {
                        headers: {
                          ...headersProspect,
                          "X-RequestDigest": formDigestValue,
                        },
                      }
                  ).then((response) => {
                      console.log("Successfully changed prospects folder", response);
                      updateClientListItem(prospectUrl);
                  });
                }
              })
              .catch((error) => {
                console.log("There was a problem with checking the prospects folder", error);
              });

            //Check client folder and if folder exists change name
            axios.get(
                `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents/${replacedExistingName}')`,
                {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                }
            ).then((response) => {
                console.log(response.data.d);
                var clientUrl = `${sharepointSite}${response.data.d.ServerRelativeUrl}`;

                //checks if folder exists in Clients folder if it does update it
                if (response.data.d.toString().length > 0) {
                  console.log("The Client folder exists");

                  const headersClient = {
                    Accept: "application/json;odata=verbose",
                    "X-HTTP-Method": "MERGE",
                    "Content-Type": "application/json",
                    "If-Match": "*",
                    Authorization: `Bearer ${accessToken}`,
                  };

                  axios.post(
                      `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents/${replacedExistingName}')/ListItemAllFields`,
                      {
                        Title: replacedNewName,
                        FileLeafRef: replacedNewName,
                      },
                      {
                        headers: {
                          ...headersClient,
                          "X-RequestDigest": formDigestValue,
                        },
                      }
                  ).then((response) => {
                      console.log("Successfully changed clients folder", response.data);
                      updateClientListItem(clientUrl);
                  });
                }
            }).catch((error) => {
              console.log("There was a problem with checking the Clients folder", error);
            });
            // end of client folder check

            // Check if sharepoint existing client site exists
            axios.get(`${sharepointSite}/sites/clients-${replacedExistingName}/_api/web/url')`,
                {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                }
            ).then((response) => {
              console.log("Client has a site:", response.data.d);
              if (JSON.stringify(response.data.d).length > 0) {
                console.log("There is a valid Url");

                //Adds an Asana Task to update client URl to new one
                CreateAsanaTask(newName.trim(), `${sharepointSite}/sites/clients-${replacedNewName}`);


              } else {
                console.log("There is no data here");
              }
            }).catch((error) => {
              console.log("There was an error checking the client site or client site doesn't exist", error);
            });
          }).catch((error) => {
            console.error("Error retrieving form digest value", error);
          });
      })
      .catch((error) => {
        console.error("Error retrieving access token", error);
      });
  };

  const handleSubmit = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (validateFields()) {
      setIsLoading(true);
      await updateHubspot();
      await updatePipedrive();
      await updateBusinessLine();
      await updateClientRelationship();
      // await updateClientRow({ cr749_companyname: newName.trim() });
      var entity = {};
      entity[clientSchema.client_name] = newName.trim();
      await updateClientRow(entity);
      await updateSharepoint();
      
      // var message = `Successfully changed name for ${selectedClient.cr749_companyname} to ${newName}`;
      var message = `Successfully changed name for ${selectedClient[clientSchema.client_name]} to ${newName}`;
      sendTeamsMessage(instance, accounts, false, `${selectedClient[clientSchema.client_name]} has changed their name to: ${newName}`);
      resetFields();
      setSuccessMessage(message);
      setFormState("success");
    }
  };

  const symbolRemovedString = (string) => {
    return string.replace(/[()\/.,]/g, '');
  };

  const resetFields = () => {

    setIsLoading(false);
    setSelectedClient({});
    setNewName("");

    setIsValidClient(true);
    setIsValidName(true);

    setSuccessMessage("");

    setFormState("");
  };

  const validateFields = () => {
    const replacedNewName = symbolRemovedString(newName.trim());

    setIsValidClient(Object.keys(selectedClient).length > 0);
    setIsValidName(replacedNewName.length > 0);

    if (isValidClient && isValidName) {
      return true;
    } else {
      setFormState("error");
      return false;
    }
  };

  const reformatName = () => {
    if (newName !== null && newName.length > 0) {
      let words = newName.toLowerCase().split("");
      for (let i = 0; i < words.length; i++) {
        if (i > 0 && (words[i-1] === " " || words[i-1] === "_")) {
          words[i] = words[i].toUpperCase();
        }
      }
      words[0] = words[0].toUpperCase();
      setNewName(words.join(""));
    }
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            Change a Client Name
            <Header.Subheader>
              Change a Client Name in Pipedrive and Sharepoint
            </Header.Subheader>
          </Header>
          <Divider inverted />
        </Container>
      </Segment>
      <Container text style={{ padding: "1em 0em" }}>
        <Form  loading={isloading}  method="post" onSubmit={handleSubmit} error={formState === "error"} success={formState === "success"}>
          <Form.Field required>
            <label>Client Name Search</label>
            <Dropdown
              placeholder="Search client here"
              search
              clearable
              selection
              className="dropdown-custom"
              // options={clients.map((client) => ({
              //   key: client.cr749_db_companyid,
              //   value: client,
              //   text: `${client.cr749_companyname} | Pipedrive: ${client.cr749_pipedriveid}`,
              // }))}
              options={clients.map((client) => ({
                key: client[clientSchema.id],
                value: client,
                text: client.cr749_ibrokerid != null
                ? `${client.cr749_companyname} (${client.cr749_ibrokerid})`
                : `${client.cr749_companyname} | Pipedrive: ${client.cr749_pipedriveid}`,
              }))}
              value={Object.keys(selectedClient).length > 0 ? selectedClient : noValueObject}
              onChange={(e, { value }) => {
                var newValue = typeof value == "object" ? value : {};
                setSelectedClient(newValue);
                setIsValidClient(Object.keys(newValue).length > 0);
              }}
              error={!isValidClient}
            />
            {!isValidClient && (<Label prompt error pointing>Please select a client</Label>)}
          </Form.Field>
          <Form.Input
            fluid
            label="New Name"
            type="text"
            placeholder="Enter new name for client"
            name="newName"
            autoComplete="off"
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
              const replacedNewName = symbolRemovedString(e.target.value.trim());
              setIsValidName(replacedNewName.length > 0);
            }}
            action={
              <Label as="a" onClick={reformatName}>
                <Icon name="font" style={{padding: "5px", margin:"0"}}/>
              </Label>
            }
            error={!isValidName ? {content: "Please enter a valid new name for client", pointing: "above"} : null}
            required
          />
          {/* <CompaniesSearch searchText={newName} selectName={(name)=>setNewName(name)}/> */}
          <Message success header="Name changed" content={successMessage} />
          <Message error header="Error" content="Please ensure the mandatory fields are filled out" />

          <Button loading={isloading} fluid type="submit" content="Change Name" color="green" style={{ margin: "2em 0em 0.5em" }} onClick={validateFields}/>
          <Button fluid basic type="reset" content="Reset" onClick={resetFields}/>
        </Form>
      </Container>
    </>
  );
};
