import React from "react";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Button, Container, Label, Menu } from "semantic-ui-react";

export const SignOutButton = () => {
    const { instance, accounts } = useMsal();

    // const { accounts } = useMsal();
//   console.log(accounts[0]);


  const isAuthenticated = useIsAuthenticated();

    const handleLogout = (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    return (
        <div>
        {isAuthenticated && accounts.length > 0 && (
            <Label basic size="tiny" style={{ display: "block", marginBottom: "0.5em" }}>{accounts[0].username.toLowerCase()}</Label>
        )}
        <Button inverted basic onClick={() => handleLogout("popup")}>
            Sign Out
        </Button>
        </div>
    )
}