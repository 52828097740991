import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import DynamicsWebApi from 'dynamics-web-api';
import { Container, List, Segment, Button, Header, Message, Loader, Dimmer } from "semantic-ui-react";
import { clientSchema } from "../tableSchema";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";

export const ClientSearch = ({searchText, selectedClient, selectClient}) => {
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);

    const { instance, accounts } = useMsal();

    const dynamicsWebApi = new DynamicsWebApi({
        ...dynamicsParams,
        onTokenRefresh: (callback) => { dynamicsWebApiCallback(instance, accounts, callback); }
    });

    useEffect(() => {
        const fetchClients = async () => {
            setLoading(true);
            var request = {
                collection: clientSchema.plural,
                select: [clientSchema.client_name],
            }
            if (searchText != null && searchText.length !== 0) {
                // request["filter"] = `contains(cr749_companyname, '${searchText}')`;
                var escapedString = searchText.replaceAll("'", "''");
                request["filter"] = `contains(${clientSchema.client_name}, '${encodeURIComponent(escapedString)}')`;
            }
            await dynamicsWebApi.retrieveMultipleRequest(request).then(function (response) {
                var records = response.value;
                // console.log(records);
                setClients(records);
            }).catch(function (error){
                console.log(JSON.stringify(error));
            });
            setLoading(false);
        };
        fetchClients();
    }, [searchText]);

    //22C5A1
    return (
        <Segment >
            <Header as="h5">Existing Clients</Header>
            {/* {loading && (
                <Dimmer>
                    <Loader active inline='centered' />
                </Dimmer>)} */}
            <Dimmer.Dimmable as={Container} dimmed={loading}>
            <Dimmer style={{ backgroundColor: "rgba(0,0,0,.3)"}} active={loading}><Loader style={{ color: "#22c4a1"}} active inline='centered' />Loading...</Dimmer>
            <Container style={{ overflow: "auto", maxHeight: 200 }}>
                <List relaxed>
                {clients.map(client => (
                    <List.Item key={client[clientSchema.id]}>
                    {clientSchema.id in selectedClient && selectedClient[clientSchema.id] === client[clientSchema.id] ? 
                        <Button type="button" fluid inverted color="green" style={{ textAlign: "left" }} onClick={() => selectClient({})} >
                            <List.Content>
                                <List.Header>{client[clientSchema.client_name]}</List.Header>
                            </List.Content>
                        </Button>
                    : 
                        <Button type="button" fluid basic style={{ textAlign: "left" }} onClick={() => selectClient(client)} >
                            <List.Content>
                                <List.Header>{client[clientSchema.client_name]}</List.Header>
                            </List.Content>
                        </Button>}
                    </List.Item>
                ))} 
                </List>
            </Container>
            </Dimmer.Dimmable>
            <Message 
                visible={
                    !(clientSchema.id in selectedClient) && clients.filter(e => clientSchema.client_name in e && e[clientSchema.client_name].trim() === searchText.trim()).length > 0
                } 
                warning content={searchText + " may already exist as a client."}
            />
        </Segment>
    );
};