import { NewClientInput } from "../NewClientInput";
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout";
import { DbUsersContext } from "../context/usersContext";
import { DataProvider } from "../context/dataContext";

export const NewOpportunity = () => {
    return (<AuthenticatedPageLayout>
        <DataProvider>
        <NewClientInput/>
        </DataProvider>
    </AuthenticatedPageLayout>);
};