
/* 
*   businessSections is copied from choice list "Business Sections" in Dataverse
*/ 
export const businessSections = {
    // "Group Medical": 863040000,
    "Private Risk": 863040002,
    "Commercial": 863040001
};

/* 
*   busLineBusinessSections is copied from choice list "Business Sections" in Dataverse because there are apparently two different lists for different tables
*/
export const busLineBusinessSections = {
    "Group Medical": 859850000,
    "Private Risk": 859850001,
    "Commercial": 859850002
}

/* 
*   referralTypesObject is used for setting values in Pipedrive
*/
export const referralTypesObject = {
    "ASB": 94,
    "Website": 95,
    "DB Client Referral": 96,
    "DB Staff Member": 97,
    "Other Formal Referral Source": 98,
    "Random Google Search": 99,
    "New Project for Existing Client": 106,
    "Event": 199,
    "Google": 200,
    "Networking": 201,
    "Social Media": 202,
    "DB or Internal Reference": 203,
    "Other": 204
};

/* 
*   asbDivisions is used for setting values in Pipedrive
*/
export const asbDivisions = {
    "Commercial Auckland CBD": 147,
    "Commercial Auckland South/East": 148,
    "Commercial Auckland North": 149,
    "Commercial Auckland West": 150,
    "Commercial Northland": 151,
    "Commercial Hamilton": 152,
    "Commercial Palmerston North": 153,
    "Commercial Taranaki": 154,
    "Commercial BOP": 155,
    "Commercial Napier": 156,
    "Commercial Wellington": 157,
    "Commercial Nelson": 158,
    "Commercial Christchurch": 173,
    "Commercial Southland": 159,
    "Insurance Manage": 160,
    "Major Commercial": 161,
    "Professions": 162,
    "Property Finance": 163,
    "Business Banking": 164,
    "Commercial Asian Banking": 174,
    "Corporate": 196
};

/* 
*   asbValues is used for setting values in Pipedrive
*/
export const asbValues = {
    "Caring": 49,
    "Courage": 50,
    "Integrity": 51,
    "Passion": 52,
    "United": 53
};

/* 
*   asbCommentaries is used for setting values in Pipedrive
*/
export const asbCommentaries = {
    "Awaiting engagement/contact with client": 79,
    "Engaged with client and conducting discovery in preparation for Audit and Recommendations": 80,
    "Indentified areas to de-risk the clients balance sheet.": 81,
    "Identified significant areas of risk exposure.": 82,
    "Feedback received that the client is appreciative of the ASB and has seen value in the process.": 83,
    "Client has made an informed decision to remain with current provider but has still seen value in the interaction and the referral.": 84,
    "Client has signed up with Donaldson Brown following a risk audit, and is happy with the value provided from the introduction with ASB": 100,
    "After several attempts to contact the client we have been unable to meaningfully engage.": 85
};

/* 
*   dealProbabilityObject is used for setting values in Pipedrive
*/
export const dealProbabilityObject = {
    "Low Probability": 323,
    "50|50 Probability": 324,
    "High Probability": 325
};