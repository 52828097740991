import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Dropdown,
  Button,
  Segment,
  Container,
  Header,
  Divider,
  Label,
  Message,
  Icon,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import axios from "axios";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import {
  businessLinesSchema,
  clientSchema,
  individualSchema,
  relationshipSchema,
} from "../tableSchema";
import { sendTeamsMessage } from "../teams";
import { functionSite, mkey } from "../constants";
import { TeamFeeTable } from "./dataverseTeamFeesTable";
import { DealOwnersTable } from "./DealOwnersTable";
import { IndustriesCreateDelete } from "./IndustriesAdmin";
import { DbUsersTable } from "./UsersTable";



export const PipedriveAdminApp = () => {
  const { instance, accounts } = useMsal();

  //referral Type
  const [newReferralType, setNewReferralType] = useState("");
  const [isValidReferralType, setIsValidReferralType] = useState(true);
  const [isValidExistingReferralType, setIsValidExisitingReferralType] =
    useState(true);
  const [existingReferralType, setExistingReferralType] = useState("");
  const [referralList, setReferralList] = useState([]);

  //ASB values
  const [newAsbValueType, setNewAsbType] = useState("");
  const [isValidAsbValueType, setIsValidAsbValueType] = useState(true);
  const [isValidExistingAsbValueType, setIsValidExisitingAsbValueType] =
    useState(true);
  const [existingAsbValueType, setExistingAsbValueType] = useState("");
  const [asbValueList, setAsbValueList] = useState([]);

  //ASB Division
  const [newAsbDivisionType, setNewAsbDivisionType] = useState("");
  const [isValidAsbDivisionType, setIsValidAsbDivisionType] = useState(true);
  const [isValidExistingAsbDivisionType, setIsValidExisitingAsbDivisionType] =
    useState(true);
  const [existingAsbDivisionType, setExistingAsbDivisionType] = useState("");
  const [asbDivisionList, setAsbDivisionList] = useState([]);

  const [loadingStates, setLoadingStates] = useState({
    referralType: false,
    asbValueType: false,
    asbDivisionType: false,
    asbCommentaryType: false,

    
  });

  //ASB Commentary
  const [newAsbCommentaryType, setNewAsbCommentaryType] = useState("");
  const [isValidAsbCommentaryType, setIsValidAsbCommentaryType] =
    useState(true);
  const [
    isValidExistingAsbCommentaryType,
    setIsValidExisitingAsbCommentaryType,
  ] = useState(true);
  const [existingAsbCommentaryType, setExistingAsbCommentaryType] =
    useState("");
  const [asbCommentaryList, setAsbCommentaryList] = useState([]);

  // Form validation
  const [refetchData, setRefetchData] = useState(false);


  //Pipedrive api custom fields
  //to add value pass in id and then label which would be an option e.g "label": "something here"

  //ASB Values
  //id 12490
  //Key decfc71bc687bb8ba1634975d4aedf2ecff3ee83

  //ASB Commentary
  // "id": 12494,
  //         "key": "f61a3338071062570f75064245af9b9baa138561",
  //         "name": "ASB Commentary"

  //ASB Division
  // "id": 12507,
  //         "key": "24c5a2c253b32647a654f868827ff1f264d89745",
  //         "name": "ASB Division",

  //Referral Type
  // "id": 12495,
  //         "key": "c44d649b190471b303fda0fd156a410c55ebad51",
  //         "name": "ORIGINATION SOURCE",

  const updateDealField = async (label, options, id) => {
    const endpoint = `${functionSite}UpdateDealField?code=${mkey}`;

    if (label) {
      const newOption = { label: label };
      options.push(newOption);
    }

    const reqData = {
      pipedrive_id: id,
      options: options,
    };
    console.log("here is the request data: ", reqData);
    let response = await axios.post(endpoint, reqData);
    return response.data;
  };

  const fetchPipedriveDealFields = async (listName, callback) => {
    try {
      const getAdvisers = `${functionSite}GetAllDealFields?code=${mkey}`;
      const response = await axios.get(getAdvisers);
      console.log("here are the deal fields: ", response.data.data);
      const referral = response.data.data.find(
        (item) => item.name === listName
      );
      if (referral && referral.options) {
        const options = referral.options.map((option) => {
          return {
            id: option.id,
            label: option.label,
          };
        });
        const sortedOptions = options.sort((a, b) => {
          const nameA = a.label.toUpperCase();
          const nameB = b.label.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        callback(sortedOptions);
      }
    } catch (error) {
      console.error("Error fetching deal fields: ", error);
    }
  };

  useEffect(() => {
    fetchPipedriveDealFields("ORIGINATION SOURCE", setReferralList);
    fetchPipedriveDealFields("ASB Values", setAsbValueList);
    fetchPipedriveDealFields("ASB Division", setAsbDivisionList);
    fetchPipedriveDealFields("ASB Commentary", setAsbCommentaryList);
    setRefetchData(false);
  }, [refetchData]);

  const handleDelete = async (field) => {
    if (isValidExistingReferralType && field === "referralType") {
      console.log("removing referral type: ", existingReferralType);
      const filteredReferralList = referralList.filter(
        (item) => item.label.trim() !== existingReferralType.trim()
      );
      await updateDealField(null, filteredReferralList, 12495);
    }

    if (isValidExistingAsbValueType && field === "asbValue") {
      console.log("removing ASB value: ", existingAsbValueType);
      const filteredAsbValueList = asbValueList.filter(
        (item) => item.label.trim() !== existingAsbValueType.trim()
      );
      await updateDealField(null, filteredAsbValueList, 12490);
    }

    if (isValidExistingAsbCommentaryType && field === "asbCommentary") {
      console.log("removing ASB commentary: ", existingAsbCommentaryType);
      const filteredAsbCommentaryList = asbCommentaryList.filter(
        (item) => item.label.trim() !== existingAsbCommentaryType.trim()
      );
      await updateDealField(null, filteredAsbCommentaryList, 12494);
    }

    if (isValidExistingAsbDivisionType && field === "asbDivision") {
      console.log("removing ASB division: ", existingAsbDivisionType);
      const filteredAsbDivisionList = asbDivisionList.filter(
        (item) => item.label.trim() !== existingAsbDivisionType.trim()
      );
      await updateDealField(null, filteredAsbDivisionList, 12507);
    }
    setRefetchData(true);
  };

  const handleSubmit = async (field) => {
    // Prevent the browser from reloading the page
    setLoadingStates(prevState => ({
      asbValueType: true,
      asbDivisionType: true,
      asbCommentaryType: true,
      referralType: true
    }));
    if (isValidReferralType && field === "referralType") {
      console.log("adding: ", newReferralType);
      await updateDealField(newReferralType, referralList, 12495);
      setNewReferralType("");
    }
    if (isValidAsbValueType && field === "asbValue") {
      console.log("adding: ", newAsbValueType);
      await updateDealField(newAsbValueType, asbValueList, 12490);
      setNewAsbType("");
    }
    if (isValidAsbCommentaryType && field === "asbCommentary") {
      console.log("adding ASB commentary: ", newAsbCommentaryType);
      await updateDealField(newAsbCommentaryType, asbCommentaryList, 12494);
      setNewAsbCommentaryType("");
    }

    if (isValidAsbDivisionType && field === "asbDivision") {
      console.log("adding ASB division: ", newAsbDivisionType);
      await updateDealField(newAsbDivisionType, asbDivisionList, 12507);
      setNewAsbDivisionType("");
    }
    console.log("success");
    setRefetchData(true);
    setLoadingStates(prevState => ({
      asbValueType: false,
      asbDivisionType: false,
      asbCommentaryType: false,
      referralType: false
    }));
    //could call reset form here after a timeout
  };


  const validateFields = () => {
    setIsValidReferralType(newReferralType.length > 0);
    setIsValidExisitingReferralType(existingReferralType.length > 0);
    if (isValidReferralType || isValidExistingReferralType) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Container text style={{ padding: "1em 0em" }}>
      <Header as="h3">Pipedrive</Header>
        <Form>
          <label>New Referral Type</label>
          <Form.Group>
            <Form.Input
              width={10}
              fluid
              type="text"
              placeholder="Enter a new referral type"
              autoComplete="off"
              value={newReferralType}
              onChange={(e, { value }) => {
                setNewReferralType(value);
                setIsValidReferralType(value.length > 0);
              }}
            />
            <Button
              color="green"
              content="Create"
              loading={loadingStates.referralType}
              disabled={loadingStates.referralType}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit("referralType");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
          <label>Remove Referral Type</label>
          <Form.Group>
            <Form.Field width={10}>
              <Dropdown
                placeholder="Select a referral type to delete"
                fluid
                selection
                value={existingReferralType}
                onChange={(e, { value }) => {
                  setExistingReferralType(value);
                  setIsValidExisitingReferralType(value.length > 0);
                }}
                options={referralList.map((type) => ({
                  key: type.id,
                  value: type.label,
                  text: type.label,
                }))}
              />
            </Form.Field>
            <Button
              content="Delete"
              onClick={(e) => {
                e.preventDefault();
                handleDelete("referralType");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
        </Form>
      </Container>
      <Container text style={{ padding: "1em 0em" }}>
        <Form>
          <label>New ASB Value</label>
          <Form.Group>
            <Form.Input
              width={10}
              fluid
              type="text"
              placeholder="Enter a new ASB value type"
              autoComplete="off"
              value={newAsbValueType}
              onChange={(e, { value }) => {
                setNewAsbType(value);
                setIsValidAsbValueType(value.length > 0);
              }}
            />
            <Button
              color="green"
              content="Create"
              loading={loadingStates.asbValueType}
              disabled={loadingStates.asbValueType}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit("asbValue");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
          <label>Remove ASB Value</label>
          <Form.Group>
            <Form.Field width={10}>
              <Dropdown
                placeholder="Select a ASB Value to delete"
                fluid
                selection
                value={existingAsbValueType}
                onChange={(e, { value }) => {
                  setExistingAsbValueType(value);
                  setIsValidExisitingAsbValueType(value.length > 0);
                }}
                options={asbValueList.map((type) => ({
                  key: type.id,
                  value: type.label,
                  text: type.label,
                }))}
              />
            </Form.Field>
            <Button
              content="Delete"
              onClick={(e) => {
                e.preventDefault();
                handleDelete("asbValue");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
        </Form>
      </Container>
      <Container text style={{ padding: "1em 0em" }}>
        <Form>
          <label>New ASB Commentary</label>
          <Form.Group>
            <Form.Input
              width={10}
              fluid
              type="text"
              placeholder="Enter a new ASB commentary type"
              autoComplete="off"
              value={newAsbCommentaryType}
              onChange={(e, { value }) => {
                setNewAsbCommentaryType(value);
                setIsValidAsbCommentaryType(value.length > 0);
              }}
            />
            <Button
              color="green"
              content="Create"
              loading={loadingStates.asbCommentaryType}
              disabled={loadingStates.asbCommentaryType}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit("asbCommentary");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
          <label>Remove ASB Commentary</label>
          <Form.Group>
            <Form.Field width={10}>
              <Dropdown
                placeholder="Select an ASB Commentary to delete"
                fluid
                selection
                value={existingAsbCommentaryType}
                onChange={(e, { value }) => {
                  setExistingAsbCommentaryType(value);
                  setIsValidExisitingAsbCommentaryType(value.length > 0);
                }}
                options={asbCommentaryList.map((type) => ({
                  key: type.id,
                  value: type.label,
                  text: type.label,
                }))}
              />
            </Form.Field>
            <Button
              content="Delete"
              onClick={(e) => {
                e.preventDefault();
                handleDelete("asbCommentary");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
        </Form>
      </Container>
      <Container text style={{ padding: "1em 0em" }}>
        <Form>
          <label>New ASB Division</label>
          <Form.Group>
            <Form.Input
              width={10}
              fluid
              type="text"
              placeholder="Enter a new ASB division type"
              autoComplete="off"
              value={newAsbDivisionType}
              onChange={(e, { value }) => {
                setNewAsbDivisionType(value);
                setIsValidAsbDivisionType(value.length > 0);
              }}
            />
            <Button
              color="green"
              content="Create"
              loading={loadingStates.asbDivisionType}
              disabled={loadingStates.asbDivisionType}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit("asbDivision");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
              />
          </Form.Group>
          <label>Remove ASB Division</label>
          <Form.Group>
            <Form.Field width={10}>
              <Dropdown
                placeholder="Select an ASB Division to delete"
                fluid
                selection
                value={existingAsbDivisionType}
                onChange={(e, { value }) => {
                  setExistingAsbDivisionType(value);
                  setIsValidExisitingAsbDivisionType(value.length > 0);
                }}
                options={asbDivisionList.map((type) => ({
                  key: type.id,
                  value: type.label,
                  text: type.label,
                }))}
              />
            </Form.Field>
            <Button
              content="Delete"
              onClick={(e) => {
                e.preventDefault();
                handleDelete("asbDivision");
              }}
              style={{ height: "38px", padding: "0.5em 1em", fontSize: "1rem" }}
            />
          </Form.Group>
        </Form>
      </Container>
    </>
  );
};
