import React, { createContext, useContext, useState, useEffect } from "react";
import DynamicsWebApi from "dynamics-web-api";
import { useMsal } from "@azure/msal-react";
import { dynamicsParams, dynamicsWebApiCallback } from "../../authConfig";
import { renewalInstance, renewalMeetingInstance } from "../../tableSchema";

const RenewalsContext = createContext();

export const useRenewalsContext = () => useContext(RenewalsContext);

export const RenewalsProvider = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [renewalInstanceData, setDealData] = useState({});
  const [renewalMeetingData, setMeetingDealData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const url = window.location.pathname;
  const id = url.split("/").pop();

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const request = {
        collection: renewalInstance.plural,
        select: [
          renewalInstance.id,
          renewalInstance.pipedrive_id,
          renewalInstance.client_contact_name,
          renewalInstance.client_contact_email,
          renewalInstance.ra_email,
          renewalInstance.risk_adviser,
          renewalInstance.associate_risk_adviser,
          renewalInstance.associate_risk_adviser,
          renewalInstance.alternate_risk_adviser,
          renewalInstance.claims,
          renewalInstance.claims_in_progress,
          renewalInstance.pending_actions,
          renewalInstance.matter_to_discuss,
          renewalInstance.client_name,
          renewalInstance.client_full_name,
          renewalInstance.renewal_date,
          renewalInstance.client_specific_issue,
          renewalInstance.client_specific_issue,
          renewalInstance.ibroker,
        ],
        filter: `${renewalInstance.id} eq ${id}`,
        count: true,
      };
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      setDealData(response.value[0]);
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setDealData({});
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const fetchMeetingData = async () => {
    if (renewalInstanceData !== null) {
      try {
        setIsLoading(true);
        const request = {
          collection: renewalMeetingInstance.plural,
          select: [
            renewalMeetingInstance.id,
            renewalMeetingInstance.relationship_id,
            renewalMeetingInstance.client_full_name,
            renewalMeetingInstance.specific_details,
            renewalMeetingInstance.task_1st_instalment,
            renewalMeetingInstance.task_2nd_instalment,
            renewalMeetingInstance.task_3_or_4_instalment,
            renewalMeetingInstance.task_3rd_instalment,
            renewalMeetingInstance.task_4th_instalment,
            renewalMeetingInstance.task_action_required_instalment,
            renewalMeetingInstance.task_completed_renewal,
            renewalMeetingInstance.task_email_information,
            renewalMeetingInstance.task_email_pre_renewal,
            renewalMeetingInstance.task_email_renewal_doc,
            renewalMeetingInstance.task_email_report,
            renewalMeetingInstance.task_payment_full,
            renewalMeetingInstance.task_select_payment_option,
            renewalMeetingInstance.task_pre_renewal,
            renewalMeetingInstance.task_preparation_renewal_doc,
            renewalMeetingInstance.task_present_renewal,
            renewalMeetingInstance.task_renewal_confirmation,
            renewalMeetingInstance.task_return_signed_document,
            renewalMeetingInstance.task_preparation_pre_renewal_meeting,
            renewalMeetingInstance.task_renewal_agreement,
            renewalMeetingInstance.agreed_date_reason,
            renewalMeetingInstance.other_agreed_reason,
            renewalMeetingInstance.payment_option,
            renewalMeetingInstance.payment_reason,
          ],
          filter: `${renewalMeetingInstance.relationship_id}/cr0d7_client eq '${renewalInstanceData.cr0d7_client}'`,
          count: true,
        };
        const response = await dynamicsWebApi.retrieveMultipleRequest(request);
        console.log("fetched meeting data: ", response.value[0]);
        setMeetingDealData(response.value[0] || {});
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setMeetingDealData({});
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchMeetingData();
  }, [renewalInstanceData, renewalMeetingInstance]);

  const refetchData = () => {
    console.log("refetching data!");
    fetchMeetingData();
    fetchData();
  };

  useEffect(() => {
    const calculateDaysTillRenewal = () => {
      if (renewalInstanceData.cr0d7_renewaldate) {
        const renewalDate = new Date(renewalInstanceData.cr0d7_renewaldate);
        const today = new Date();
        const timeDifference = renewalDate.getTime() - today.getTime();
        const daysTillRenewal = Math.ceil(timeDifference / (1000 * 3600 * 24));
        renewalInstanceData.daysTillRenewal = daysTillRenewal;
        console.log("here is the day till renewal: ", daysTillRenewal);
      }
      return 0;
    };

    calculateDaysTillRenewal();
  }, [renewalInstanceData]);

  return (
    <RenewalsContext.Provider
      value={{
        renewalInstanceData,
        renewalMeetingData,
        refetchData,
        isLoading,
        error,
      }}
    >
      {children}
    </RenewalsContext.Provider>
  );
};
