
import { AuthenticatedPageLayout } from "../AuthenticatedPageLayout";
import { RenewalsContactForm } from "../RenewalsContactsForm";
import { RenewalsProvider } from "../context/renewalsContext";
import { DbUsersContext } from "../context/usersContext";

export const RenewalsContactApp = () => {
    return (<AuthenticatedPageLayout requiresAdmin={true}>
        <RenewalsProvider>
        <RenewalsContactForm/>
        </RenewalsProvider>
    </AuthenticatedPageLayout>);
};