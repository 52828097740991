import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Form, Dropdown, Button, Segment, Container, Header, Divider, Label, Message, Icon } from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import axios from "axios";
import { mkey, functionSite, clientSite, sharepointSite, noValueObject } from "../constants";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { businessLinesSchema } from "../tableSchema";

export const MigrateGroupScheme = () => {
    const { instance, accounts } = useMsal();
    const dynamicsWebApi = new DynamicsWebApi({
        ...dynamicsParams,
        onTokenRefresh: (callback) => {
            dynamicsWebApiCallback(instance, accounts, callback);
        },
    });

    const [isloading, setIsLoading] = useState(false);
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const [successMessage, setSuccessMessage] = useState("");
    const [isValidClient, setIsValidClient] = useState(true);
    const [isValidFile, setIsValidFile] = useState(true);
    const [formState, setFormState] = useState("");
    const [file, setFile] = useState(null);

    useEffect(() => {
        const fetchClients = async () => {
            const fetchXml = `<fetch>
        <entity name="${businessLinesSchema.name}">
          <attribute name="${businessLinesSchema.client_name}"/>
          <attribute name="${businessLinesSchema.id}"/>
          <attribute name="${businessLinesSchema.business_section}"/>
        </entity>
      </fetch>`;

            try {
                const response = await dynamicsWebApi.executeFetchXmlAll(businessLinesSchema.plural, fetchXml);
                const filteredRecords = response.value.filter((record) => record[businessLinesSchema.business_section] !== "Group Medical");
                setClients(filteredRecords);
            } catch (error) {
                console.log(JSON.stringify(error));
            }
        };
        fetchClients();
    }, []);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setIsValidFile(e.target.files[0] != null);
        console.log("File uploaded:", e.target.files[0]);
    };

    const handleRemoveFile = () => {
        setFile(null);
    };

    const addToSharepoint = async (clientName, file) => {
        const sharePointTokenResponse = await instance.acquireTokenSilent({
            scopes: [`${sharepointSite}/.default`],
            account: accounts[0],
        });

        const accessToken = sharePointTokenResponse.accessToken;
        const digestResponse = await axios.post(
            `${sharepointSite}/_api/contextinfo`,
            null,
            {
                headers: {
                    Accept: "application/json;odata=verbose",
                    "Content-Type": "application/json;odata=verbose",
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        console.log('here is the file name: ', file.name);
        const formDigestValue = digestResponse.data.d.GetContextWebInformation.FormDigestValue;
        const fileContent = await file.arrayBuffer();
        console.log('here is the file content: ', fileContent);

        try {

            const sharePointApiUrl = `${sharepointSite}/sites/GroupSchemeSite/_api/web/GetFolderByServerRelativeUrl('Group%20Scheme%20File/${clientName}')/Files/add(url='${file.name}',overwrite=true)`;
            console.log('SharePoint API URL:', sharePointApiUrl);
            const headers = {
                "Authorization": `Bearer ${accessToken}`,
                "X-RequestDigest": formDigestValue,
            };

            try {
                const response = await axios.post(sharePointApiUrl, fileContent, { headers });
                console.log('SharePoint Response:', response.data);

                return response.data;
            } catch (error) {
                console.error("Error adding to SharePoint:", error);
            }

        } catch (error) {
            console.error("Error adding to SharePoint:", error);
        }
    }
    // const getFileData = async (file) => {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             const arrayBuffer = reader.result;
    //             console.log('FileReader Result:', arrayBuffer);
    //             resolve(arrayBuffer);
    //         };
    //         reader.onerror = (error) => {
    //             reject(error);
    //         };
    //         reader.readAsArrayBuffer(file);
    //     });
    // };


    const resetFields = () => {
        setIsLoading(false);
        setSelectedClient({});
        setIsValidClient(true);
        setSuccessMessage("");
        setFormState("");
        setFile(null);
        setIsValidFile(true);

    };

    const validateFields = () => {
        setIsValidClient(Object.keys(selectedClient).length > 0);
        setIsValidFile(file !== null);

        if (isValidClient && isValidFile) {
            return true;
        } else {
            setFormState("error");
            return false;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateFields()) {
            setIsLoading(true);
            await addToSharepoint(selectedClient[businessLinesSchema.client_name], file);
            setIsLoading(false);
            setSuccessMessage("File uploaded successfully!");
            setFormState("success");
        }
    };

    return (
        <>
            <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
                <Container text>
                    <Header inverted as="h1">
                        Group Scheme Loading
                        <Header.Subheader>
                            Populates individuals in Hubspot and sharepoint
                        </Header.Subheader>
                    </Header>
                    <Divider inverted />
                </Container>
            </Segment>
            <Container text style={{ padding: "1em 0em" }}>
                <Form loading={isloading} method="post" onSubmit={handleSubmit} error={formState === "error"} success={formState === "success"}>
                    <Form.Field required>
                        <label>Client Name Search</label>
                        <Dropdown
                            placeholder="Search client here"
                            search
                            clearable
                            selection
                            className="dropdown-custom"
                            options={clients.map((client) => ({
                                key: client[businessLinesSchema.id],
                                value: client,
                                text: client.cr749_companyname_
                            }))}
                            value={Object.keys(selectedClient).length > 0 ? selectedClient : noValueObject}
                            onChange={(e, { value }) => {
                                var newValue = typeof value == "object" ? value : {};
                                console.log('Selected Client:', newValue);
                                setSelectedClient(newValue);
                                setIsValidClient(Object.keys(newValue).length > 0);
                            }}
                            error={!isValidClient}
                        />
                        {!isValidClient && (<Label prompt error pointing>Please select a client</Label>)}
                    </Form.Field>
                    <Form.Field required>
                        <label>Upload File</label>
                        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
                    </Form.Field>
                    {file && (
                        <Message positive icon>
                            <Icon name="file" />
                            <Message.Content>
                                <Message.Header>{file.name}</Message.Header>
                                <Button icon="close" content="Remove" onClick={handleRemoveFile} />
                            </Message.Content>
                        </Message>
                    )}
                    {!isValidFile && (<Label prompt error pointing>Please upload a relevant file</Label>)}
                    <Message
                        success
                        header="Added Group Scheme File"
                        content="Successfully added file"
                    />
                    <Message
                        error
                        header="Error"
                        content={formState === "error" ? "There was an issue uploading the file" : "There was an issue; please try again"}
                    />
                    <Button loading={isloading} fluid type="submit" content="Migrate" color="green" style={{ margin: "2em 0em 0.5em" }} onClick={validateFields} />
                    <Button fluid basic type="reset" content="Reset" onClick={resetFields} />
                </Form>
            </Container>
        </>
    );
};
