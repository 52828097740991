import axios from "axios";
import { format } from "util";


export const serverUrl = "https://org061e3616.crm6.dynamics.com";

export const mkey = "yUy257nM-9pa-QayoYiNACZYxaHY8MD_-MRX4v4ekpYLAzFuKDDdQA==";
export const functionSite = `https://db-app-functions.azurewebsites.net/api/`;

export const clientSite = "https://donaldsonroberts.sharepoint.com/sites/Clients/";
export const sharepointSite = "https://donaldsonroberts.sharepoint.com";

export const descriptionUrl = "https://donaldsonroberts.sharepoint.com/sites/Clients/SitePages/Adviser-Fee-Breakdown.aspx";

export const noValueObject = { key: 0, value: {}, text: 'No selection' };


export const formatDate = (date, includeTime) => {
	if (date == null) {
		return 'Date not found';
	}
	const dateFormat = includeTime ? 'd MMM yyyy \'at\' h:mm a' : 'd MMM yyyy';
	return format(new Date(date), dateFormat);
};


export function formatDateYearMonthDay(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export const formatDateString = (date) => {

	if(date == null) {
		return 'Date not found';
	}
	const currentDate = new Date();
	const formattedDate = currentDate.toISOString().split('T')[0];
	return formattedDate;
};


