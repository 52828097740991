import axios from 'axios';

export const sendTeamsMessage = ( instance, accounts, error, body) => {
  //general "19%3aT30stBGqkunjMmP2bxl4GN0hQBt1pmPLvrDrDXUz9Sw1%40thread.tacv2";
  //error 19%3ad1c278ea68d049ff8e5949d188d40834%40thread.tacv2
  const channelId = (error) ? "19%3ad1c278ea68d049ff8e5949d188d40834%40thread.tacv2" : "19%3aT30stBGqkunjMmP2bxl4GN0hQBt1pmPLvrDrDXUz9Sw1%40thread.tacv2"
  const teamId = "65192de0-4fb0-4fc3-9d34-3deda9e584b5";
  
  instance
    .acquireTokenSilent({
      scopes: ["https://graph.microsoft.com/ChannelMessage.Send"],
      account: accounts[0],
    })
    .then((response) => {
      const accessToken = response.accessToken;
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      };
      const requestBody = {
        body: {
          contentType: "html",
          content: `${body}`,
        },
      };
      axios
        .post(
          `https://graph.microsoft.com/v1.0/teams/${teamId}/channels/${channelId}/messages`,
          requestBody,
          {
            headers: {
              ...headers,
            },
          }
        )
        .then((response) => {
          console.log("Successfully sent Teams message", response);
        })
        .catch((error) => {
          console.log("Error sending Teams message", error);
        });
    })
    .catch((error) => {
      console.log("There was an error getting the access token: ", error);
    });
};
