import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Dropdown,
  Button,
  Segment,
  Container,
  Header,
  Table,
  Pagination,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { dealOwnersSchema } from "../tableSchema";
import { businessSections } from "../data";

export const DealOwnersTable = () => {
  const { instance, accounts } = useMsal();
  const [isloading, setIsLoading] = useState(false);
  const [dealOwnerData, setDealOwnerData] = useState([]);
  const [newTeamUser, setNewTeamUser] = useState("");

  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = dealOwnerData.slice(indexOfFirstItem, indexOfLastItem);

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  useEffect(() => {
    const fetchAdviserTeams = async () => {
      var request = {
        collection: dealOwnersSchema.plural,
        select: [
          dealOwnersSchema.owner_name,
          dealOwnersSchema.business_section,
          dealOwnersSchema.business_section_type,
          dealOwnersSchema.user_email,
          dealOwnersSchema.id,
        ],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          console.log("users: ", response.value);
          const teams = response.value.map((person) => ({
            name: person.cr0d7_name,
            business_section: person.cr0d7_businesssection,
            business_section_type: person.cr0d7_businesssectiontype,
            key: person.cr0d7_db_dealownerid || null,
            email: person.cr0d7_useremail,
          }));
          // Sort teams alphabetically by name
          console.log("dealOwners: ", teams);
          teams.sort((a, b) => a.name.localeCompare(b.name));
          setDealOwnerData(teams);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };

    fetchAdviserTeams();
  }, []);

  const retrieveTeamFeeUser = async (rowData) => {
    var request = {
      collection: dealOwnersSchema.plural,
      select: [dealOwnersSchema.id],
      filter: `${dealOwnersSchema.id} eq '${rowData.key}'`,
      count: true,
    };

    try {
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      return response;
    } catch (error) {
      console.log("Error retrieving row", error);
      return null;
    }
  };

  const createTeamFeeRow = async (rowData) => {
    // var entity = {
    //     cr749_companyname_: company.cr749_companyname,
    //     cr749_businesssection: businessSection.key
    // };
    var entity = {};
    entity[dealOwnersSchema.owner_name] = rowData.name;
    entity[dealOwnersSchema.business_section] = rowData.business_section;
    entity[dealOwnersSchema.business_section_type] =
      rowData.business_section_type;
    entity[dealOwnersSchema.user_email] = rowData.email;

    var request = {
      // collection: "cr749_db_businesslines",
      collection: dealOwnersSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    const response = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        console.log("created record: ", record);
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return response;
  };

  const updateTeamFeeUser = async (rowData) => {
    console.log("here is the row data: ", rowData);
    var entity = {};
    entity[dealOwnersSchema.owner_name] = rowData.name;
    entity[dealOwnersSchema.business_section] = rowData.business_section;
    entity[dealOwnersSchema.business_section_type] =
      rowData.business_section_type;
    entity[dealOwnersSchema.user_email] = rowData.email;

    var request = {
      key: rowData.key,
      collection: dealOwnersSchema.plural,
      entity: entity,
      returnRepresentation: true,
      select: [dealOwnersSchema.id],
    };

    dynamicsWebApi
      .updateRequest(request)
      .then((response) => {
        console.log(`Successfully updated Row ${entity}`);
      })
      .catch((error) => {
        console.log("Error updating client row", error);
      });
  };

  const deleteTeamFeeUser = async (rowData) => {
    var request = {
      key: rowData.key,
      collection: dealOwnersSchema.plural,
    };

    try {
      await dynamicsWebApi.deleteRequest(request);
      console.log(`Successfully deleted row with key ${rowData.key}`);
    } catch (error) {
      console.log("Error deleting row", error);
    }
  };

  const handleAddRow = () => {
    setDealOwnerData([
      ...dealOwnerData,
      {
        name: "",
        business_section: false,
        email: "",
        business_section_type: false,
        key: "",
      },
    ]);
  };

  const handleInputChange = (pageIndex, itemIndex, field, value) => {
    const adjustedIndex = pageIndex * itemsPerPage + itemIndex;
    const updatedData = dealOwnerData.map((item, index) => {
      if (index === adjustedIndex) {
        if (field === "business_section_type") {
          const updatedBusinessSection = businessSections[value];
          return { ...item, business_section: updatedBusinessSection, business_section_type: value };
        } else {
          return { ...item, [field]: value };
        }
      }
      return item;
    });
    console.log("updated data: ", updatedData);
    setDealOwnerData(updatedData);
  };
  
  


  const handleSaveRow = async (index) => {
    const adjustedIndex = indexOfFirstItem + index;
    const rowData = dealOwnerData[adjustedIndex];
    console.log("here is the row data: ", rowData);
    if (!rowData || !rowData.name || rowData.name.trim().length === 0) {
      return;
    }

    const updatedData = [...dealOwnerData];
    updatedData[adjustedIndex] = { ...rowData, isSaving: true };
    setDealOwnerData(updatedData);

    let updatedKey = null; 

    try {
      const response = await retrieveTeamFeeUser(rowData);
      if (response && response?.value?.length > 0) {
        await updateTeamFeeUser(rowData);
      } else {
        const createdRow = await createTeamFeeRow(rowData);
        updatedKey = createdRow?.cr0d7_db_dealownerid || null;
        const updatedRow = {
          ...rowData,
          key: updatedKey,
        };
        updatedData[adjustedIndex] = updatedRow;
        console.log("here is the updated row: ", updatedRow);
        setDealOwnerData(updatedData);
      }
    } catch (error) {
      console.error("Error saving row:", error);
    } finally {
      const finalData = [...dealOwnerData];
      finalData[adjustedIndex] = {
        ...rowData,
        key: updatedKey,
        isSaving: false,
      };
      setDealOwnerData(finalData);
    }
  };

  const handleDeleteRow = async (index) => {
    const adjustedIndex = indexOfFirstItem + index;
    const rowData = dealOwnerData[adjustedIndex];
    console.log("here is the row data to delete: ", rowData);
    if (!rowData || !rowData.name || rowData.name.trim().length === 0) {
      return;
    }
    await deleteTeamFeeUser(rowData);
    const updatedData = [...dealOwnerData];
    updatedData.splice(adjustedIndex, 1);
    setDealOwnerData(updatedData);
  };

  return (
    <>
      <Container text style={{ padding: "1em 0em" }}>
        <Header as="h3">Deal Owners Table</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Business Section
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentItems.map((team, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Form.Input
                    value={team.name}
                    onChange={(e, { value }) =>
                      handleInputChange(activePage - 1, index, "name", value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Input
                    value={team.email}
                    onChange={(e, { value }) =>
                      handleInputChange(activePage - 1, index, "email", value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder="Select"
                    fluid
                    selection
                    value={team.business_section_type}
                    onChange={(e, { value }) =>
                      handleInputChange(
                        activePage - 1,
                        index,
                        "business_section_type",
                        value
                      )
                    }
                    options={Object.keys(businessSections).map((key) => ({
                      key: businessSections[key],
                      value: key,
                      text: key,
                    }))}
                  />
                </Table.Cell>
                <Table.Cell>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      color="green"
                      onClick={() => handleSaveRow(index)}
                      disabled={!team.name || team.isSaving}
                    >
                      {team.isSaving ? "Saving..." : "Save"}
                    </Button>
                    <Button onClick={() => handleDeleteRow(index)}>
                      Delete
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination
          activePage={activePage}
          onPageChange={(e, { activePage }) => setActivePage(activePage)}
          totalPages={Math.ceil(dealOwnerData.length / itemsPerPage)}
        />
        <Button color="green" style={{ marginLeft: 10 }} onClick={handleAddRow}>
          Add Row
        </Button>
      </Container>
    </>
  );
};
