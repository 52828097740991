import React from "react";
import { useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { PageLayout } from "./PageLayout";
import { Container, Divider, Header } from "semantic-ui-react";
import { adminAccounts } from "../authConfig";
import { DbUsersContext, useUsersContext } from "./context/usersContext";
/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */
export const AuthenticatedPageLayout = (props) => {
  const { accounts } = useMsal();
  const { dbUsersList } = useUsersContext();
  // const isAdminUser = accounts && accounts[0]?.username && dbUsersList.includes(accounts[0]?.username.toLowerCase());
  const isUser =
    accounts &&
    accounts[0]?.username &&
    dbUsersList.some((user) => user.email === accounts[0]?.username.toLowerCase());



  return (
    <PageLayout>
    <DbUsersContext>
    <AuthenticatedTemplate>
    {props.requiresAdmin !== true || isUser ?
      (props.children) :
      (<Container text style={{ padding: "8em 0em 0.5em" }}>
        <Header as="h1">
        You are unauthorised to view this page.
        <Header.Subheader>
            Please contact sean.g@dbib.co.nz if you have any queries.
        </Header.Subheader>
        </Header>
        <Divider/>
      </Container>)}
    </AuthenticatedTemplate>
    </DbUsersContext>
    <UnauthenticatedTemplate>
        <Container text style={{ padding: "8em 0em 0.5em" }}>
            <Header as="h1">
            Please sign-in to see your information
            </Header>
            <Divider/>
        </Container>
    </UnauthenticatedTemplate>
    </PageLayout>
  );
};