import React, { createContext, useContext, useState, useEffect } from "react";
import DynamicsWebApi from "dynamics-web-api";
import { useMsal } from "@azure/msal-react";
import { dynamicsParams, dynamicsWebApiCallback } from "../../authConfig";
import { dbUsersSchema } from "../../tableSchema";

const UsersContext = createContext();

export const useUsersContext = () => useContext(UsersContext);

export const DbUsersContext = ({ children }) => {
  const { instance, accounts } = useMsal();
  const [dbUsersList, setDbUsersList] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  useEffect(() => {
    setLoadingUsers(true);
    const fetchData = async () => {
      try {
        const request = {
          collection: dbUsersSchema.plural,
          select: [
            dbUsersSchema.id,
            dbUsersSchema.email,
            dbUsersSchema.admin,
          ],
          count: true,
        };
        const response = await dynamicsWebApi.retrieveMultipleRequest(request);
        const userEmails = response.value.map(user => {
          return {
            email: user.cr0d7_email,
            admin: user.cr0d7_admin,
          }
        });
        setDbUsersList(userEmails);
      } catch (error) {
        console.log('there was an error retrieving db users:', error);
        setDbUsersList([])
      } finally {
        setLoadingUsers(false);
      }
    };
    fetchData();
  }, [instance, accounts]);




  
  return (
    <UsersContext.Provider
      value={{ dbUsersList, loadingUsers }}
    >
      {children}
    </UsersContext.Provider>
  );
};
