import { useMsal } from "@azure/msal-react";
import React, { useState, useEffect } from "react";
import DynamicsWebApi from "dynamics-web-api";
import {
  Button,
  Form,
  Dropdown,
  Container,
  Header,
  Divider,
  Segment,
  Message,
  Input,
  Label,
  Icon,
  Popup,
  FormInput,
  Grid,
} from "semantic-ui-react";
import axios from "axios";
import {
  mkey,
  functionSite,
  descriptionUrl,
  sharepointSite,
  noValueObject,
} from "../constants";
import {
  adviserFeeTeamSchema,
  clientSchema,
  feesSchema,
  industriesSchema,
} from "../tableSchema";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { sendTeamsMessage } from "../teams";
import { jsPDF } from "jspdf";
import { TeamFee } from "./TeamFee";

export const FeeCalculator = () => {
  const { instance, accounts } = useMsal();

  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});

  const [deals, setDeals] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState({});
  const [pipeline, setPipeline] = useState({});

  const [advisers, setAdvisers] = useState([]);
  const [selectedAdviser, setSelectedAdviser] = useState({});

  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState("");

  const [raTeam, setRATeam] = useState(0);
  const [claims, setClaims] = useState(0);
  const [paTeam, setPATeam] = useState(0);
  const [raTeamList, setRaTeamList] = useState([]);
  const [paTeamList, setPaTeamList] = useState([]);
  const [associateTeamList, setAssociateTeamList] = useState([]);
  const [finLinesTeamList, setFinLinesTeamList] = useState([]);
  // const [admin, setAdmin] = useState(0);
  const [finLines, setFinLines] = useState(0);
  const [other, setOther] = useState(0);

  const [premiumPool, setPremiumPool] = useState(0);
  const [policyCount, setPolicyCount] = useState(0);
  const [numberEndorsementsLastPeriod, setNumberEndorsementsLastPeriod] =
    useState(0);
  const [approxTurnover, setApproxTurnover] = useState(0);
  const [previousIncome, setPreviousIncome] = useState(0);
  const [operationalCosts, setOperationalCosts] = useState(0);

  const [total, setTotal] = useState(0);
  const [poolPercent, setPoolPercent] = useState(0);

  // Form validation
  const [formState, setFormState] = useState("");

  // Form loading
  const [loading, setLoading] = useState(false);

  // Input validation
  const [isValidClient, setIsValidClient] = useState(true);
  const [isValidDeal, setIsValidDeal] = useState(true);
  const [isValidIndustry, setIsValidIndustry] = useState(true);

  // const [isValidRATeam, setIsValidRATeam] = useState(true);
  const [isValidClaims, setIsValidClaims] = useState(true);
  // const [isValidPATeam, setIsValidPATeam] = useState(true);
  // const [isValidAdmin, setIsValidAdmin] = useState(true);
  // const [isValidFinLines, setIsValidFinLines] = useState(true);
  // const [isValidOther, setIsValidOther] = useState(true);

  const [isValidPremiumPool, setIsValidPremiumPool] = useState(true);
  const [isValidPolicyCount, setIsValidPolicyCount] = useState(true);
  const [isValidApproxTurnover, setIsValidApproxTurnover] = useState(true);
  const [isValidPreviousIncome, setIsValidPreviousIncome] = useState(true);
  const [feePercentLastYear, setFeePercentLastYear] = useState(0);

  const [isClient, setIsClient] = useState(false);
  const [isSite, setIsSite] = useState(false);

  const [receivedClients, hasReceivedClients] = useState(false);
  // const [receivedAdvisers, hasReceivedAdvisers] = useState(false);

  const [loadingDeals, setLoadingDeals] = useState(false);
  const [loadingAdvisers, setLoadingAdvisers] = useState(false);

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  // useEffect(() => {
  const fetchClients = async () => {
    var fetchXml =
      '<fetch mapping="logical">' +
      `<entity name="${clientSchema.name}">` +
      `<attribute name="${clientSchema.client_name}"/>` +
      `<attribute name="${clientSchema.ibroker}"/>` +
      `<attribute name="${clientSchema.pipedrive_id}"/>` +
      `<attribute name="${clientSchema.hubspot_id}"/>` +
      `<attribute name="${clientSchema.id}"/>` +
      "</entity>" +
      "</fetch>";

    dynamicsWebApi
      .executeFetchXmlAll(clientSchema.plural, fetchXml)
      .then(function (response) {
        console.log(response.value);
        var records = response.value.filter(
          (record) => record[clientSchema.pipedrive_id] != null
        );
        setClients(records);
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  };
  //fetchClients();
  //}, []);

  // const [selectedRAStaff, setSelectedRAStaff] = useState(null);
  // const [selectedPAStaff, setSelectedPAStaff] = useState(null);
  // const [selectedFinLinesStaff, setSelectedFinLinesStaff] = useState(null);

  const [raTeamFees, setRATeamFees] = useState([{ selectedStaff: "", fee: 0 }]);

  const [paTeamFees, setPATeamFees] = useState([{ selectedStaff: "", fee: 0 }]);

  const [fineLinesTeamFees, setFinLinesTeamFees] = useState([
    { selectedStaff: "", fee: 0 },
  ]);

  useEffect(() => {
    console.log("here is the pa teams fee: ", paTeamFees);
  }, [paTeamFees]);

  const handleAddTeamFee = (section, e) => {
    e.preventDefault();

    let newTeamFee = { selectedStaff: "", fee: 0 };

    if (section === "raTeam") {
      setRATeamFees([...raTeamFees, newTeamFee]);
    } else if (section === "paTeam") {
      setPATeamFees([...paTeamFees, newTeamFee]);
    } else if (section === "finLines") {
      setFinLinesTeamFees([...fineLinesTeamFees, newTeamFee]);
    }
  };

  const handleDeleteTeamFee = (feeSection, index, e) => {
    e.preventDefault();
    if (feeSection === "raTeam") {
      setRATeamFees((prevFees) => prevFees.filter((_, i) => i !== index));
    } else if (feeSection === "paTeam") {
      setPATeamFees((prevFees) => prevFees.filter((_, i) => i !== index));
    } else if (feeSection === "finLines") {
      setFinLinesTeamFees((prevFees) => prevFees.filter((_, i) => i !== index));
    }
  };

  const handleAdviserSelection = (section, index, selectedStaff) => {
    if (section === "raTeam") {
      const updatedRATeamFees = [...raTeamFees];
      updatedRATeamFees[index].selectedStaff = selectedStaff;
      setRATeamFees(updatedRATeamFees);
    } else if (section === "paTeam") {
      const updatedPATeamFees = [...paTeamFees];
      updatedPATeamFees[index].selectedStaff = selectedStaff;
      setPATeamFees(updatedPATeamFees);
    } else if (section === "finLines") {
      const updatedFinLinesTeamFees = [...fineLinesTeamFees];
      updatedFinLinesTeamFees[index].selectedStaff = selectedStaff;
      setFinLinesTeamFees(updatedFinLinesTeamFees);
    }
  };

  const handleFeeEntry = (section, index, enteredFee) => {
    if (section === "raTeam") {
      const updatedRATeamFees = [...raTeamFees];
      updatedRATeamFees[index].fee = enteredFee;
      setRATeamFees(updatedRATeamFees);
    } else if (section === "paTeam") {
      const updatedPATeamFees = [...paTeamFees];
      updatedPATeamFees[index].fee = enteredFee;
      setPATeamFees(updatedPATeamFees);
    } else if (section === "finLines") {
      const updatedFinLinesTeamFees = [...fineLinesTeamFees];
      updatedFinLinesTeamFees[index].fee = enteredFee;
      setFinLinesTeamFees(updatedFinLinesTeamFees);
    }
  };

  const [finLinesAssociates, setFinLinesAssociates] = useState([]);
  const [raTeamAssociates, setRaTeamAssociates] = useState([]);
  const [paTeamAssociates, setPaTeamAssociates] = useState([]);

  const onSearchFetch = () => {
    if (!receivedClients) {
      fetchClients();
      hasReceivedClients(true);
    }
  };

  // const onSearchAdvisers  =  () => {
  //     if (!receivedAdvisers) {
  //         fetchAdvisers();
  //         hasReceivedAdvisers(true);
  //     }
  // }

  useEffect(() => {
    const fetchDeals = async () => {
      setLoadingDeals(true);
      var getDeals = `${functionSite}GetPipedriveDealsByOrganisation?code=${mkey}`;
      let response = await axios.get(getDeals, {
        params: {
          // pipedrive_id: parseInt(selectedClient.cr749_pipedriveid)
          pipedrive_id: parseInt(selectedClient[clientSchema.pipedrive_id]),
        },
      });
      // console.log("Deals", response.data.deals);
      setDeals(response.data.deals);
      setLoadingDeals(false);
      // if (response.data.deals.length === 0) {
      //     setSelectedAdviser({});
      // }
    };
    setSelectedDeal({});
    // if ("cr749_pipedriveid" in selectedClient) {
    if (clientSchema.pipedrive_id in selectedClient) {
      fetchDeals();
    } else {
      setDeals([]);
    }
  }, [selectedClient]);

  useEffect(() => {
    const fetchAdvisers = async () => {
      setLoadingAdvisers(true);
      var getAdvisers = `${functionSite}GetPipedriveAdvisers?code=${mkey}`;
      let response = await axios.get(getAdvisers);
      setAdvisers(response.data.advisers);
      setLoadingAdvisers(false);
    };
    fetchAdvisers();
  }, []);

  //Fetches adviser fee teams Associates, fin lines, pa team, ra team
  useEffect(() => {
    const fetchAdviserTeams = async () => {
      var request = {
        collection: adviserFeeTeamSchema.plural,
        select: [
          adviserFeeTeamSchema.adviser_name,
          adviserFeeTeamSchema.associate,
          adviserFeeTeamSchema.pa_team,
          adviserFeeTeamSchema.fin_team,
          adviserFeeTeamSchema.ra_team,
        ],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          const associates = [];
          const rateam = [];
          const pateam = [];
          const finLines = [];

          response.value.forEach((person) => {
            if (person.cr0d7_associate) {
              associates.push({
                key: person.cr0d7_name,
                text: person.cr0d7_name,
                value: person.cr0d7_name,
              });
            }
            if (person.cr0d7_pateam) {
              pateam.push({
                key: person.cr0d7_name,
                text: person.cr0d7_name,
                value: person.cr0d7_name,
              });
            }
            if (person.cr0d7_finlinesteam) {
              finLines.push({
                key: person.cr0d7_name,
                text: person.cr0d7_name,
                value: person.cr0d7_name,
              });
            }
            if (person.cr0d7_rateam) {
              rateam.push({
                key: person.cr0d7_name,
                text: person.cr0d7_name,
                value: person.cr0d7_name,
              });
            }
          });

          associates.sort((a, b) => a.key.localeCompare(b.key));
          rateam.sort((a, b) => a.key.localeCompare(b.key));
          pateam.sort((a, b) => a.key.localeCompare(b.key));
          finLines.sort((a, b) => a.key.localeCompare(b.key));

          // Set state after processing data
          setAssociateTeamList(associates);
          setRaTeamList(rateam);
          setPaTeamList(pateam);
          setFinLinesTeamList(finLines);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };
    fetchAdviserTeams();
  }, []);

  useEffect(() => {
    setSelectedDeal(deals.length > 0 ? deals[0] : {});
  }, [deals]);

  useEffect(() => {
    const fetchPipeline = async () => {
      var getPipeline = `${functionSite}GetPipeline?code=${mkey}`;
      let response = await axios.get(getPipeline, {
        params: {
          pipeline_id: parseInt(selectedDeal.pipeline_id),
        },
      });
      // console.log(response.data);
      setPipeline(response.data.data);
    };
    if (Object.keys(selectedDeal).length > 0) {
      fetchPipeline();
    } else {
      setPipeline({});
    }
  }, [selectedDeal]);

  useEffect(() => {
    const fetchIndustries = async () => {
      // var request = {
      //     collection: "cr0d7_industrieses",
      //     select: ["cr0d7_name", "cr0d7_industriesid"],
      // }
      var request = {
        collection: industriesSchema.plural,
        select: [industriesSchema.industry_name, industriesSchema.id],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          const sortedIndustries = response.value.sort((a, b) => {
            const nameA = a.cr0d7_name.toUpperCase();
            const nameB = b.cr0d7_name.toUpperCase();

            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
          });
          setIndustries(sortedIndustries);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };
    fetchIndustries();
  }, []);

  useEffect(() => {
    calculate();
  }, [raTeam, claims, paTeam, finLines, other, operationalCosts]);

  useEffect(() => {
    const defaultOperationalCostValue =
      0.3 * (raTeam + claims + paTeam + finLines);
    const operationalRoundedValue =
      Math.round(defaultOperationalCostValue / 10) * 10;
    setOperationalCosts(operationalRoundedValue);
  }, [raTeam, claims, paTeam, finLines]);

  useEffect(() => {
    calculatePremiumPool();
  }, [premiumPool, total]);

  const calculate = () => {
    // console.log(`raTeam ${raTeam} claims ${claims} paTeam ${paTeam} admin ${admin} finLines ${finLines} other ${other} // TOTAL ${raTeam + claims + paTeam + admin + finLines + other}`);
    setTotal(raTeam + claims + paTeam + finLines + other + operationalCosts);
  };

  const calculatePremiumPool = () => {
    if (total > 0 && premiumPool > 0) {
      const poolPercent = (total / premiumPool) * 100;
      setPoolPercent(Number(poolPercent.toFixed(1)));
    }
  };


  useEffect(() => {
    const calculateFeePercentageLastYear = () => {
      if (previousIncome > 0) {
        const increase = total - previousIncome;
        const percentage = (increase / previousIncome * 100);
        setFeePercentLastYear(Number(percentage.toFixed(1)));
      } else {
        setFeePercentLastYear(0); 
      }
    };
    calculateFeePercentageLastYear();
  }, [previousIncome, total]);



  const resetFields = () => {
    setSelectedClient({});

    setDeals([]);
    setSelectedDeal({});
    setPipeline({});

    setSelectedAdviser({});
    setSelectedIndustry("");

    setPremiumPool(0);
    setRATeam(0);
    setClaims(0);
    setPATeam(0);
    // setAdmin(0);
    setFinLines(0);
    setOther(0);

    setPolicyCount(0);
    setNumberEndorsementsLastPeriod(0);
    setApproxTurnover(0);
    setPreviousIncome(0);

    setTotal(0);
    setPoolPercent(0);

    setIsValidClient(true);
    setIsValidDeal(true);
    setIsValidIndustry(true);

    // setIsValidRATeam(true);
    setIsValidClaims(true);
    // setIsValidPATeam(true);
    // setIsValidAdmin(true);
    // setIsValidFinLines(true);
    // setIsValidOther(true);

    setIsValidPremiumPool(true);
    setIsValidPolicyCount(true);
    setIsValidApproxTurnover(true);
    setIsValidPreviousIncome(true);

    setLoading(false);

    setFormState("");
  };

  const validateFields = () => {
    setIsValidClient(Object.keys(selectedClient).length > 0);
    setIsValidDeal(Object.keys(selectedDeal).length > 0);
    setIsValidIndustry(Object.keys(selectedIndustry).length > 0);

    // setIsValidRATeam(raTeam > 0);
    setIsValidClaims(claims > 0);
    // setIsValidPATeam(true);
    // setIsValidAdmin(admin > 0);
    // setIsValidFinLines(true);
    // setIsValidOther(true);

    setIsValidPremiumPool(premiumPool > 0);
    setIsValidPolicyCount(policyCount > 0);
    setIsValidApproxTurnover(approxTurnover > 0);
    setIsValidPreviousIncome(previousIncome > 0);

    if (
      isValidClient &&
      isValidDeal &&
      isValidIndustry &&
      isValidClaims &&
      isValidPremiumPool &&
      isValidPolicyCount &&
      isValidApproxTurnover &&
      isValidPreviousIncome
    ) {
      return true;
    } else {
      // console.log("error");
      setFormState("error");
      return false;
    }
  };

  const generatePDFAndUpload = async (
    textInput,
    digestValue,
    accessToken,
    url
  ) => {
    const doc = new jsPDF();
    doc.text(textInput, 10, 10);
    const fileContent = doc.output("arraybuffer");
    const fileBlob = new Blob([fileContent], { type: "application/pdf" });

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      "X-RequestDigest": digestValue,
    };

    try {
      const fileResponse = await axios.post(url, fileBlob, { headers });
      console.log("Created file info:", fileResponse.data);

      const fileId = fileResponse.data.UniqueId;
      console.log(fileId);
    } catch (error) {
      console.log("Error creating file;", error);
    }
  };

  const formatDate = () => {
    const nzTimezone = "Pacific/Auckland";
    const date = new Date().toLocaleString("en-NZ", { timeZone: nzTimezone });
    const formattedDate = date.split(",")[0].replace(/\//g, "-");
    return formattedDate;
  };

  const addToSharepoint = async (note) => {
    const currentDate = formatDate();
    const clientName = selectedClient[clientSchema.client_name];
    const noteSubject = `${clientName} Fee Breakdown ${currentDate}`;
    instance
      .acquireTokenSilent({
        scopes: [`${sharepointSite}/.default`],
        account: accounts[0],
      })
      .then((response) => {
        const accessToken = response.accessToken;

        const headers = {
          Accept: "application/json;odata=verbose",
          "Content-Type": "application/json;odata=verbose",
          Authorization: `Bearer ${accessToken}`,
        };

        //get webcontext and digest value
        axios
          .post(`${sharepointSite}/_api/contextinfo`, null, {
            headers: headers,
          })
          .then((response) => {
            const formDigestValue =
              response.data.d.GetContextWebInformation.FormDigestValue;
            const replacedClientName = clientName.replaceAll(" ", "");
            console.log(replacedClientName);
            axios
              .get(
                `${sharepointSite}/sites/clients-${replacedClientName}/_api/web/url')`,
                {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                }
              )
              .then((response) => {
                if (JSON.stringify(response.data.d).length > 0) {
                  setIsSite(true);
                  const clientUrl = response.data.d.Url;
                  console.log(clientUrl);

                  // Prepare the request URL attach client
                  const clienturl = `${clientUrl}/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents')/Files/add(url='${noteSubject}.pdf',overwrite=true)`;
                  generatePDFAndUpload(
                    note,
                    formDigestValue,
                    accessToken,
                    clienturl
                  );
                } else {
                  console.log("there is no valid url:" + response);
                }
              })
              .catch((err) => {
                console.log("client site doesnt exists" + err);
              });
            if (!isSite) {
              //Check client folder
              const clientFolderUrl = `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents/${clientName}')`;
              const createClientFolder = `${sharepointSite}/sites/clients-ClientFolders/_api/web/GetFolderByServerRelativeUrl('Shared%20Documents/${clientName}')/Files/add(url='${noteSubject}.pdf',overwrite=true)`;
              axios
                .get(clientFolderUrl, {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                })
                .then((response) => {
                  if (response.data.d.toString().length > 0) {
                    console.log("client exists in client folder");
                    setIsClient(true);
                    generatePDFAndUpload(
                      note,
                      formDigestValue,
                      accessToken,
                      createClientFolder
                    );
                  } else {
                    setIsClient(false);
                  }
                })
                .catch((err) => {
                  console.log(
                    "there was an err checking the client folder",
                    err
                  );
                  setIsClient(false);
                });
            }

            //Check propects folder
            const prospectsUrl = `${sharepointSite}/sites/Clients/_api/web/GetFolderByServerRelativeUrl('Prospects/${clientName}')`;
            const prospectsCreateUrl = `${sharepointSite}/sites/Clients/_api/web/GetFolderByServerRelativeUrl('Prospects/${clientName}')/Files/add(url='${noteSubject}.pdf',overwrite=true)`;

            if (!isClient && !isSite) {
              axios
                .get(prospectsUrl, {
                  headers: {
                    ...headers,
                    "X-RequestDigest": formDigestValue,
                  },
                })
                .then((response) => {
                  if (response.data.d.toString().length > 0) {
                    console.log(response.data.d);
                    generatePDFAndUpload(
                      note,
                      formDigestValue,
                      accessToken,
                      prospectsCreateUrl
                    );
                  }
                })
                .catch((err) =>
                  console.log(err, "error getting prospect folder")
                );
            }
          })
          .catch((err) => console.error("error getting digest value", err));
      })
      .catch((err) => {
        console.log("there was an error getting the access token", err);
      });
  };

  const createBreakdown = async () => {
    var record;

    var entity = {};
    entity[feesSchema.previous_income] = previousIncome;
    entity[feesSchema.claims_fee] = claims;
    entity[feesSchema.premium_pool_percent] = poolPercent;
    entity[feesSchema.fee_percent_vs_last_year] = feePercentLastYear;
    entity[feesSchema.approx_turnover] = approxTurnover;
    entity[feesSchema.pipedrive_deal_name] = selectedDeal.title;
    entity[feesSchema.fin_lines] = finLines;
    entity[feesSchema.ra_team] = raTeam;
    entity[feesSchema.operational_costs] = operationalCosts;
    entity[feesSchema.total_premium_pool] = premiumPool;
    entity[feesSchema.pa_team] = paTeam;
    // entity[feesSchema.admin_fee] = admin;
    entity[feesSchema.fee_associated] = pipeline.name;
    entity[feesSchema.total] = total;
    entity[feesSchema.policy_count] = policyCount;
    entity[feesSchema.endorsment_last_period] = numberEndorsementsLastPeriod;
    entity[feesSchema.adviser_name] = selectedAdviser.name;
    entity[feesSchema.industry] =
      selectedIndustry[industriesSchema.industry_name];
    entity[feesSchema.disbursements] = other;
    entity[feesSchema.deal_id] = selectedDeal.id.toString();
    entity[feesSchema.pa_associates] = paTeamAssociates.join(", ");
    entity[feesSchema.ra_associates] = raTeamAssociates.join(", ");
    entity[feesSchema.fin_lines_associates] = finLinesAssociates.join(", ");
    entity[feesSchema.fin_lines_adviser_fees] = fineLinesTeamFees
      .map((fee) => `${fee.selectedStaff}: $${fee.fee}`)
      .join(", ");
    entity[feesSchema.ra_team_adviser_fees] = raTeamFees
      .map((fee) => `${fee.selectedStaff}: $${fee.fee}`)
      .join(", ");
    entity[feesSchema.pa_team_adviser_fees] = paTeamFees
      .map((fee) => `${fee.selectedStaff}: $${fee.fee}`)
      .join(", ");

    //entity[feesSchema.adviser_id] = selectedAdviser.id.toString();

    entity[`${feesSchema.client}@odata.bind`] = `/${clientSchema.plural}(${
      selectedClient[clientSchema.id]
    })`;
    var request = {
      // collection: "cr0d7_db_adviser_feeses",
      collection: feesSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    record = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        //do something with a record here
        return record;
      })
      .catch(function (error) {
        sendTeamsMessage(
          instance,
          accounts,
          true,
          `There was an error adding fee breakdown into Dataverse <br> Error: ${error}`
        );
        console.log(JSON.stringify(error));
      });
    return record;
  };

  const handleSubmit = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();

    if (validateFields()) {
      const note = `
            Deal Title: ${selectedDeal.title}
            Completed By: ${selectedAdviser.name}
            Pipeline Name: ${pipeline.name}
            Deal Id: ${selectedDeal.id}
            Industry: ${selectedIndustry[industriesSchema.industry_name]}
            Policy Count: ${policyCount}   
            Number of Endorsements LastPeriod: ${numberEndorsementsLastPeriod}   
            Total Premium Pool: $${premiumPool}
            Previous Income: $${previousIncome}
            Approximate Turnover: $${approxTurnover}\n
            Fee BreakDown
            Fin Lines: $${finLines}
            Ra Team: $${finLines}
            Pa Team: $${paTeam}
            Claims: $${claims}
            Disbursements: $${other}\n
            Operational Costs: $${operationalCosts}
            Total: $${total}
            Fee Percentage: ${poolPercent}%
            Fee Difference This Year VS Last Year: ${feePercentLastYear}%

            RA Team Associates: ${raTeamAssociates.join(", ")}
            RA Team Advisers: 
            ${raTeamFees
              .map((fee) => `${fee.selectedStaff}: $${fee.fee}`)
              .join(",")}\n
            PA Team Associates: ${paTeamAssociates.join(", ")}
            PA Team Advisers:
            ${paTeamFees
              .map((fee) => `${fee.selectedStaff}: $${fee.fee}`)
              .join(",")}\n
            Fin Lines Team Associates: ${finLinesAssociates.join(", ")}
            Fin Lines Team Advisers: 
            ${fineLinesTeamFees
              .map((fee) => `${fee.selectedStaff}: $${fee.fee}`)
              .join(",")}
            `;
      setLoading(true);

      await createBreakdown()
        .then((result) => {
          if (result === null) {
            setLoading(false);
            return setFormState("issue");
          }
          resetFields();
          setFormState("success");
        })
        .catch((error) => {
          console.error(error);
          setFormState("issue");
        });
      await addToSharepoint(note);
    }
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            Adviser Fee Calculator
            <Header.Subheader>
              This form allows the Adviser to capture the Adviser fee breakdown
              associated with a deal. Filling out this form creates a note
              against the deal in Pipedrive
            </Header.Subheader>
          </Header>

          <Divider inverted />
          <a href={descriptionUrl} target="_blank" rel="noreferrer">
            {" "}
            <Button style={{ backgroundColor: "white" }}>
              {" "}
              <Header
                inverted
                as="h3"
                style={{ paddingBottom: "0.5em", color: "black" }}
              >
                {" "}
                <Icon name="info circle" />
                Click here for more information and for descriptions of the
                various fields{" "}
              </Header>{" "}
            </Button>{" "}
          </a>
        </Container>
      </Segment>
      <Container text style={{ padding: "1em 0em" }}>
        <Form
          method="post"
          onSubmit={handleSubmit}
          error={formState === "error" || formState === "issue"}
          success={formState === "success"}
        >
          <Form.Field required error={!isValidClient}>
            <label>
              {" "}
              <Popup
                content="Enter client name"
                trigger={<Icon name="info circle" />}
                position="top center"
              />{" "}
              Client Name Search
            </label>
            <Dropdown
              placeholder="Search client here"
              search
              selection
              className="dropdown-custom"
              // options={clients.map((client) => ({ key: client.cr749_db_companyid, value: client, text: `${client.cr749_companyname} | Pipedrive: ${client.cr749_pipedriveid}` }))}
              options={clients.map((client) => ({
                key: client[clientSchema.id],
                value: client,
                text: `${client[clientSchema.client_name]} | Pipedrive: ${
                  client[clientSchema.pipedrive_id]
                }`,
              }))}
              value={
                Object.keys(selectedClient) === 0
                  ? noValueObject
                  : selectedClient
              }
              onSearchChange={onSearchFetch}
              onChange={(e, { value }) => {
                setSelectedClient(value);
                setIsValidClient(Object.keys(value).length > 0);
              }}
              error={!isValidClient}
            />
            {!isValidClient && (
              <Label prompt error pointing>
                Please select a client
              </Label>
            )}
          </Form.Field>
          <Form.Field required error={!isValidDeal}>
            <label>
              {" "}
              <Popup
                content="Select specific pipedrive deal"
                trigger={<Icon name="info circle" />}
                position="top center"
              />{" "}
              Pipedrive Deal
            </label>
            <Dropdown
              loading={loadingDeals}
              placeholder={
                Object.keys(selectedClient).length === 0
                  ? "Please select a client to get deals"
                  : loadingDeals
                  ? "Loading available deals ..."
                  : deals.length === 0
                  ? "There are no open deals available"
                  : "Select deal to set fee for"
              }
              selection
              clearable
              disabled={deals.length === 0}
              className="dropdown-custom"
              options={deals.map((deal) => ({
                key: deal.id,
                value: deal,
                text: `${deal.id} | ${deal.title}`,
              }))}
              value={
                Object.keys(selectedDeal).length === 0
                  ? noValueObject
                  : selectedDeal
              }
              onChange={(e, { value }) => {
                setSelectedDeal(value);
                setIsValidDeal(Object.keys(value).length > 0);
                // if (value !== null && Object.keys(value).length > 0) {
                //     var dealAdviser = advisers.filter(adviser => adviser.id === value.user_id.id);
                //     if (dealAdviser.length > 0) {
                //         // console.log(dealAdviser[0]);
                //         setSelectedAdviser(dealAdviser[0]);
                //     } else {
                //         setSelectedAdviser({});
                //     }
                // } else {
                //     setSelectedAdviser({});
                // }
              }}
              error={!isValidDeal}
            />
            {Object.keys(selectedDeal).length > 0 &&
              Object.keys(pipeline).length > 0 && (
                <Label style={{ float: "right", marginTop: "0.5em" }}>
                  Pipeline:
                  <Label.Detail>{pipeline.name}</Label.Detail>
                </Label>
              )}
            {!isValidDeal && (
              <Label prompt error pointing>
                Please select a deal
              </Label>
            )}
          </Form.Field>
          {
            <Form.Field>
              <label>
                {" "}
                <Popup
                  content="Person completing the Fee Breakdown, will be recorded on note"
                  trigger={<Icon name="info circle" />}
                  position="top center"
                />{" "}
                Completed By
              </label>
              <Dropdown
                loading={loadingAdvisers}
                placeholder={
                  advisers.length === 0
                    ? "There are no users available"
                    : "Search for a user"
                }
                selection
                search
                className="dropdown-custom"
                options={advisers.map((adviser) => ({
                  key: adviser.id,
                  value: adviser,
                  text: adviser.name,
                }))}
                value={
                  Object.keys(selectedAdviser).length === 0
                    ? noValueObject
                    : selectedAdviser
                }
                onChange={(e, { value }) => setSelectedAdviser(value)}
                // onClick={onSearchAdvisers}
              />
            </Form.Field>
          }
          <Form.Field required error={!isValidIndustry}>
            <label>
              {" "}
              <Popup
                content="Select most relevant industry"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              Industry
            </label>
            <Dropdown
              placeholder="Select industry"
              selection
              className="dropdown-custom"
              // options={industries.map((industry) => ({ key: industry.cr749_industriesid, value: industry, text: industry.cr749_name }))}
              options={industries.sort().map((industry) => ({
                key: industry[industriesSchema.id],
                value: industry,
                text: industry[industriesSchema.industry_name],
              }))}
              value={
                Object.keys(selectedIndustry).length === 0
                  ? noValueObject
                  : selectedIndustry
              }
              onChange={(e, { value }) => {
                setSelectedIndustry(value);
                setIsValidIndustry(Object.keys(value).length > 0);
              }}
              error={!isValidIndustry}
            />
            {!isValidIndustry && (
              <Label prompt error pointing>
                Please select an industry
              </Label>
            )}
          </Form.Field>
          <Segment style={{ margin: "1.5em 0em" }}>
            <Form.Group widths="equal">
              <Popup
                content="Enter estimated total number of policies"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              <Form.Input
                fluid
                label="Policy Count"
                type="number"
                placeholder="Enter policy count"
                name="policyCount"
                min="0"
                value={parseInt(Math.abs(policyCount)).toString()}
                onChange={(e, { value }) => {
                  setPolicyCount(Math.abs(value));
                  setIsValidPolicyCount(value > 0);
                }}
                error={
                  !isValidPolicyCount
                    ? {
                        content: "This field cannot be zero",
                        pointing: "above",
                      }
                    : null
                }
                required
              />
              <Popup
                content="Include the number of endorsements actioned in the previous year, check iBroker for all policies. Have you charged a separate fee for each of these throughout the year or are you apportioning part of the renewal fee to these endorsements?"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              <Form.Input
                fluid
                label="Number of Endorsements in last policy period"
                //fluid label="Policy Count"
                type="number"
                placeholder="0"
                min="0"
                value={parseInt(
                  Math.abs(numberEndorsementsLastPeriod)
                ).toString()}
                onChange={(e, { value }) => {
                  setNumberEndorsementsLastPeriod(Math.abs(value));
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Popup
                content="Enter approximate Turnover of the company, or for Property Owners enter the annual rents, for PA Clients use $1"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              <Form.Input
                fluid
                label="Approximate Turnover"
                icon="dollar sign"
                iconPosition="left"
                type="number"
                min="0"
                placeholder="Enter approximate turnover"
                name="approxTurnover"
                value={parseFloat(Math.abs(approxTurnover)).toString()}
                onChange={(e, { value }) => {
                  setApproxTurnover(Math.abs(value));
                  setIsValidApproxTurnover(value > 0);
                }}
                error={
                  !isValidApproxTurnover
                    ? {
                        content: "This field cannot be zero",
                        pointing: "above",
                      }
                    : null
                }
                required
              />
              <Popup
                content="Enter total premium pool or all policies excluding GST and levies"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              <Form.Input
                fluid
                label="Total Premium Pool"
                icon="dollar sign"
                iconPosition="left"
                type="number"
                min="0"
                placeholder="Enter premium pool value"
                name="premiumPool"
                value={parseFloat(Math.abs(premiumPool)).toString()}
                onChange={(e, { value }) => {
                  setPremiumPool(Math.abs(value));
                  setIsValidPremiumPool(value > 0);
                }}
                error={
                  !isValidPremiumPool
                    ? {
                        content:
                          "This field cannot be zero and should be greater than breakdown total",
                        pointing: "above",
                      }
                    : null
                }
                required
              />
              <Popup
                content="For new business enter previous brokers income, for renewals enter last year's total annual billable fees"
                trigger={<Icon name="info circle" />}
                position="top center"
              />
              <Form.Input
                fluid
                label="Previous Income"
                icon="dollar sign"
                iconPosition="left"
                type="number"
                min="0"
                placeholder="Enter previous income"
                name="previousIncome"
                value={parseFloat(Math.abs(previousIncome)).toString()}
                onChange={(e, { value }) => {
                  setPreviousIncome(Math.abs(value));
                  setIsValidPreviousIncome(value > 0);
                }}
                error={
                  !isValidPreviousIncome
                    ? {
                        content: "This field cannot be zero",
                        pointing: "above",
                      }
                    : null
                }
                required
              />
            </Form.Group>
          </Segment>
          <Segment style={{ margin: "1.5em 0em" }}>
            <Header as="h5">Fee breakdown</Header>
            <Container style={{ margin: "0em 2em" }}>
              <Form.Group widths="equal">
                <a
                  href={descriptionUrl}
                  style={{ color: "black" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <Popup
                    content="Click here for description"
                    trigger={<Icon name="info circle" />}
                    position="top center"
                  />{" "}
                </a>
                <Form.Input
                  fluid
                  label="RA Team"
                  icon="dollar sign"
                  iconPosition="left"
                  type="number"
                  min="0"
                  placeholder="Enter RA Team value"
                  name="RATeam"
                  value={parseFloat(Math.abs(raTeam)).toString()}
                  onChange={(e, { value }) => {
                    setRATeam(Math.abs(value));
                  }}
                />
                <a
                  href={descriptionUrl}
                  style={{ color: "black" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <Popup
                    content="Click here for description"
                    trigger={<Icon name="info circle" />}
                    position="top center"
                  />{" "}
                </a>
                <Form.Input
                  fluid
                  label="Claims"
                  icon="dollar sign"
                  iconPosition="left"
                  type="number"
                  min="0"
                  placeholder="Enter Claims value"
                  name="claims"
                  value={parseFloat(Math.abs(claims)).toString()}
                  onChange={(e, { value }) => {
                    setClaims(Math.abs(value));
                    setIsValidClaims(value > 0);
                  }}
                  error={
                    !isValidClaims
                      ? {
                          content: "This field cannot be zero",
                          pointing: "above",
                        }
                      : null
                  }
                  required
                />
              </Form.Group>
              {parseFloat(Math.abs(raTeam)) > 0 && (
                <Form.Field>
                  <label>
                    <Popup
                      content="Please capture the main Associate that worked on this account, however no fee to be allocated to them"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <strong>RA Team Associate </strong>
                  </label>
                  <Dropdown
                    placeholder="Select RA Team associate"
                    selection
                    multiple
                    options={associateTeamList}
                    value={raTeamAssociates}
                    onChange={(e, { value }) => setRaTeamAssociates(value)}
                  />
                </Form.Field>
              )}
              {parseFloat(Math.abs(raTeam)) > 0 && (
                <>
                  <label>
                    <Popup
                      content="Allocated against all the Advisers involved in this account under the RA Team"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <strong>RA Team Advisers </strong>
                  </label>
                  {raTeamFees.map((teamFee, index) => (
                    <TeamFee
                      key={index}
                      teamList={raTeamList}
                      onSelectAdviser={(selectedAdviser) =>
                        handleAdviserSelection("raTeam", index, selectedAdviser)
                      }
                      onEnterFee={(enteredFee) =>
                        handleFeeEntry("raTeam", index, enteredFee)
                      }
                      onDeleteFee={(e) =>
                        handleDeleteTeamFee("raTeam", index, e)
                      }
                    />
                  ))}
                  <Button
                    style={{ margin: "5px" }}
                    color="green"
                    onClick={(e) => handleAddTeamFee("raTeam", e)}
                  >
                    <Icon name="plus" /> Add Adviser
                  </Button>
                </>
              )}
              <Form.Group widths="equal" style={{ marginTop: "5px" }}>
                <a
                  href={descriptionUrl}
                  style={{ color: "black" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <Popup
                    content="Click here for description"
                    trigger={<Icon name="info circle" />}
                    position="top center"
                  />{" "}
                </a>
                <Form.Input
                  fluid
                  label="PA Team"
                  icon="dollar sign"
                  iconPosition="left"
                  type="number"
                  min="0"
                  placeholder="Enter PA Team value"
                  name="PATeam"
                  value={parseFloat(Math.abs(paTeam)).toString()}
                  onChange={(e, { value }) => setPATeam(Math.abs(value))}
                />
              </Form.Group>
              {parseFloat(Math.abs(paTeam)) > 0 && (
                <Form.Field>
                  <label>
                    <Popup
                      content="Please capture the main Associate that worked on this account, however no fee to be allocated to them"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <strong>PA Team Associate </strong>
                  </label>
                  <Dropdown
                    placeholder="Select PA Team associate"
                    selection
                    multiple
                    options={associateTeamList}
                    value={paTeamAssociates}
                    onChange={(e, { value }) => setPaTeamAssociates(value)}
                  />
                </Form.Field>
              )}
              {parseFloat(Math.abs(paTeam)) > 0 && (
                <>
                  <label>
                    <Popup
                      content="Allocated against all the Advisers involved in this account under the PA Team"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <strong>PA Team Advisers </strong>
                  </label>
                  {paTeamFees.map((teamFee, index) => (
                    <TeamFee
                      key={index}
                      teamList={paTeamList}
                      onSelectAdviser={(selectedAdviser) =>
                        handleAdviserSelection("paTeam", index, selectedAdviser)
                      }
                      onEnterFee={(enteredFee) =>
                        handleFeeEntry("paTeam", index, enteredFee)
                      }
                      onDeleteFee={(e) =>
                        handleDeleteTeamFee("paTeam", index, e)
                      }
                    />
                  ))}
                  <Button
                    style={{ margin: "5px" }}
                    color="green"
                    onClick={(e) => handleAddTeamFee("paTeam", e)}
                  >
                    <Icon name="plus" /> Add Adviser
                  </Button>
                </>
              )}
              <Form.Group widths="equal" style={{ marginTop: "5px" }}>
                <a
                  href={descriptionUrl}
                  style={{ color: "black" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <Popup
                    content="Click here for description"
                    trigger={<Icon name="info circle" />}
                    position="top center"
                  />{" "}
                </a>
                <Form.Input
                  fluid
                  label="Fin Lines"
                  icon="dollar sign"
                  iconPosition="left"
                  type="number"
                  min="0"
                  placeholder="Enter Fin Lines value"
                  name="finLines"
                  value={parseFloat(Math.abs(finLines)).toString()}
                  onChange={(e, { value }) => setFinLines(Math.abs(value))}
                />
                <a
                  href={descriptionUrl}
                  style={{ color: "black" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <Popup
                    content="Click here for description"
                    trigger={<Icon name="info circle" />}
                    position="top center"
                  />{" "}
                </a>
                <Form.Input
                  fluid
                  label="Other / Disbursements"
                  icon="dollar sign"
                  iconPosition="left"
                  type="number"
                  min="0"
                  placeholder="Enter other / disbursements value"
                  name="other"
                  value={parseFloat(Math.abs(other)).toString()}
                  onChange={(e, { value }) => setOther(Math.abs(value))}
                />
              </Form.Group>
              {parseFloat(Math.abs(finLines)) > 0 && (
                <Form.Field>
                  <label>
                    <Popup
                      content="Please capture the main Associate that worked on this account, however no fee to be allocated to them"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <strong>Fin Lines Team Associate </strong>
                  </label>
                  <Dropdown
                    placeholder="Select Fin Lines Team associate"
                    selection
                    multiple
                    options={associateTeamList}
                    value={finLinesAssociates}
                    onChange={(e, { value }) => setFinLinesAssociates(value)}
                  />
                </Form.Field>
              )}
              {parseFloat(Math.abs(finLines)) > 0 && (
                <>
                  <label>
                    <Popup
                      content="Allocated against all the Advisers involved in this account under the Fin Lines Team"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <strong>Fin Lines Team Advisers </strong>
                  </label>
                  {fineLinesTeamFees.map((teamFee, index) => (
                    <TeamFee
                      key={index}
                      teamList={finLinesTeamList}
                      onSelectAdviser={(selectedAdviser) =>
                        handleAdviserSelection(
                          "finLines",
                          index,
                          selectedAdviser
                        )
                      }
                      onEnterFee={(enteredFee) =>
                        handleFeeEntry("finLines", index, enteredFee)
                      }
                      onDeleteFee={(e) =>
                        handleDeleteTeamFee("finLines", index, e)
                      }
                    />
                  ))}
                  <Button
                    style={{ margin: "5px" }}
                    color="green"
                    onClick={(e) => handleAddTeamFee("finLines", e)}
                  >
                    <Icon name="plus" /> Add Adviser
                  </Button>
                </>
              )}
              <Form.Group widths="equal" style={{ marginTop: "5px" }}>
                <Form.Input
                  fluid
                  label="Operational Costs"
                  icon="dollar sign"
                  iconPosition="left"
                  min="0"
                  placeholder="Enter operational cost value"
                  value={parseFloat(Math.abs(operationalCosts)).toString()}
                  onChange={(e, { value }) =>
                    setOperationalCosts(Math.abs(value))
                  }
                />
              </Form.Group>
              <Container style={{ paddingTop: "1em" }}>
                <Form.Field>
                  <Input labelPosition="left">
                    <Label>TOTAL</Label>
                    <Input
                      icon="dollar sign"
                      name="total"
                      iconPosition="left"
                      type="number"
                      placeholder="Total automatically generated"
                      value={total}
                      readOnly
                    />
                  </Input>
                </Form.Field>
                <Form.Field>
                  <Input labelPosition="left">
                    <Popup
                      content="Shows percentage of fee to total premium pool"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <Label>FEE PERCENTAGE</Label>
                    <Input
                      icon="percent"
                      name="total"
                      iconPosition="right"
                      type="number"
                      placeholder="Percentage automatically generated"
                      value={poolPercent}
                      readOnly
                    />
                  </Input>
                </Form.Field>
                <Form.Field>
                  <Input labelPosition="left">
                    <Popup
                      content="Fee % Increase from last year to this year"
                      trigger={<Icon name="info circle" />}
                      position="top center"
                    />
                    <Label>FEE DIFFERENCE THIS YEAR VS LAST YEAR</Label>
                    <Input
                      icon="percent"
                      name="total"
                      iconPosition="right"
                      type="number"
                      placeholder="Percentage automatically generated"
                      value={feePercentLastYear}
                      readOnly
                    />
                  </Input>
                </Form.Field>
              </Container>
            </Container>
          </Segment>
          <Message
            success
            header="Fee breakdown added"
            content="Successfully added a new fee breakdown"
          />
          <Message
            error
            header="Error"
            content={
              formState === "error"
                ? "Please ensure the mandatory fields are filled out"
                : "There was an issue; please try again"
            }
          />
          <Button
            fluid
            loading={loading}
            type="submit"
            content="Submit"
            color="green"
            style={{ margin: "2em 0em 0.5em" }}
            onClick={validateFields}
          />
          <Button
            fluid
            disabled={loading}
            basic
            type="reset"
            content="Reset"
            onClick={resetFields}
          />
        </Form>
      </Container>
    </>
  );
};
