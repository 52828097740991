import React, { useState } from "react";
import { Container, Divider, Header, Menu, Segment } from "semantic-ui-react";
import { PipedriveAdminApp } from "./PipedriveAdminAppForm";
import { DealOwnersTable } from "./DealOwnersTable";
import { IndustriesCreateDelete } from "./IndustriesAdmin";
import { TeamFeeTable } from "./dataverseTeamFeesTable";
import { DbUsersTable } from "./UsersTable";

export const AdminAppForm = () => {
  const [activeTab, setActiveTab] = useState("PipedriveAdminApp");

  const handleTabChange = (e, { name }) => {
    setActiveTab(name);
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container textAlign="center">
          <Header inverted as="h1">
            Admin App Center
            <Header.Subheader>Add and Remove exisiting table data</Header.Subheader>
          </Header>
          <Divider inverted />
        </Container>
      </Segment>

      <Container textAlign="center">
        <Menu pointing secondary>
          <Menu.Item
            name="PipedriveAdminApp"
            active={activeTab === "PipedriveAdminApp"}
            onClick={handleTabChange}
          >
            Pipedrive
          </Menu.Item>
          <Menu.Item
            name="DealOwnersTable"
            active={activeTab === "DealOwnersTable"}
            onClick={handleTabChange}
          >
            Deal Owners
          </Menu.Item>
          <Menu.Item
            name="IndustriesCreateDelete"
            active={activeTab === "IndustriesCreateDelete"}
            onClick={handleTabChange}
          >
            Industries
          </Menu.Item>
          <Menu.Item
            name="TeamFeeTable"
            active={activeTab === "TeamFeeTable"}
            onClick={handleTabChange}
          >
            Team Fees
          </Menu.Item>
          <Menu.Item
            name="DbUsersTable"
            active={activeTab === "DbUsersTable"}
            onClick={handleTabChange}
          >
            DB Users
          </Menu.Item>
        </Menu>
      </Container>

      {activeTab === "PipedriveAdminApp" && <PipedriveAdminApp />}
      {activeTab === "DealOwnersTable" && <DealOwnersTable />}
      {activeTab === "IndustriesCreateDelete" && <IndustriesCreateDelete />}
      {activeTab === "TeamFeeTable" && <TeamFeeTable />}
      {activeTab === "DbUsersTable" && <DbUsersTable />}
    </>
  );
};
