import { Link } from "react-router-dom";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Container, Header, Divider, Button, Loader } from "semantic-ui-react";
import { PageLayout } from "../PageLayout";
import { SignInButton } from "../SignInButton";
import { adminAccounts } from "../../authConfig";
import { useUsersContext } from "../context/usersContext";

export const HomePage = () => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  const { dbUsersList, loadingUsers } = useUsersContext();
  //const isAdminUser = (accounts && accounts[0]?.username && (adminAccounts.includes(accounts[0].username.toLowerCase())));
  const isUser =
    accounts &&
    accounts[0]?.username &&
    dbUsersList.some((user) => user.email === accounts[0]?.username.toLowerCase());
  console.log('is user: ', isUser);


  if (isUser) {
    var user = dbUsersList.find((user) => user.email === accounts[0]?.username.toLowerCase());
    if (user.admin) {
      console.log("User exists and is an admin.");
    } 
  }

  return (
    <PageLayout>
      <Container text style={{ padding: "8em 0em 0.5em" }}>
        <Header as="h1">
          Welcome to the Donaldson Brown App Centre
          <Header.Subheader>
            No Hidden Commissions. No Hidden Fees. Total Transparency
          </Header.Subheader>
        </Header>
        <Divider />
      </Container>

      <Container text style={{ padding: "1em 5em" }}>
        {isAuthenticated ? (
          <Container>
            {loadingUsers ? (
              <Loader active />
            ) : (
              <>
                <Link to="/new-opportunity">
                  <Button basic fluid style={{ marginBottom: "0.5em" }}>
                    New Opportunity Loader
                  </Button>
                </Link>
                {isUser && (
                  <>
                    <Link to="/name-change">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                        Name Change
                      </Button>
                    </Link>
                    <Link to="/fee-app">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                        Fee App
                      </Button>
                    </Link>
                    <Link to="/note-app">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                        Note Taking App
                      </Button>
                    </Link>
                    {user.admin && (
                    <>
                    <Link to="/admin-app">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                        Admin App
                      </Button> 
                    </Link>
                    <Link to="/new-business-checklist">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                        New Business App
                      </Button> 
                    </Link> 
                    <Link to="/renewals-app">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                      Renewals App
                      </Button> 
                    </Link>
                    </>)}
                    <Link to="/group-scheme">
                      <Button basic fluid style={{ marginBottom: "0.5em" }}>
                        Group Scheme Migration
                      </Button>
                    </Link>
                  </>
                )}
              </>
            )}
          </Container>
        ) : (
          <SignInButton showFluid={true} showInverted={false} />
        )}
      </Container>
    </PageLayout>
  );
};
