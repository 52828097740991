import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useAccount, useMsal} from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import { Container, Menu, Divider, Header, Image, Segment, Item, Label } from "semantic-ui-react";
import { adminAccounts } from "../authConfig";
import { useUsersContext } from "./context/usersContext";

/**
 * Renders the navbar component with a sign in or sign out button depending on whether or not a user is authenticated
 * @param props
 */
export const PageLayout = (props) => {

  const { accounts } = useMsal();
  // const isAdminUser = (accounts && accounts[0]?.username && (adminAccounts.includes(accounts[0].username.toLowerCase())));
  const { dbUsersList } = useUsersContext();
  // const isAdminUser = accounts && accounts[0]?.username && dbUsersList.includes(accounts[0]?.username.toLowerCase());
  const isUser =
    accounts &&
    accounts[0]?.username &&
    dbUsersList.some((user) => user.email === accounts[0]?.username.toLowerCase());

  if (isUser) {
    var user = dbUsersList.find((user) => user.email === accounts[0]?.username.toLowerCase());
  }
  // const [activePage, setActivePage] = useState("/");
  // let location = useLocation();
  // console.log(location);
  const isAuthenticated = useIsAuthenticated();
  return (
    <>
    <Menu fixed="top" inverted>
      <Container>
        <Link to="/">
          <Menu.Item header>
              {/* <Image size="mini" src={logo} style={{marginRight: "1.5em"}} /> */}
              <Image size="small" src="https://www.donaldsonbrown.co.nz/media/1173/db_logo_sm.svg?width=300" />
              {/* <span>Donaldson Brown</span> */}
          </Menu.Item>
        </Link>
        {isAuthenticated && <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em"}}>
          <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/new-opportunity">New Opportunity Loader</NavLink>
        </Menu.Item>}
        {isAuthenticated && isUser && (
        <>
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/name-change">Name Change</NavLink>
          </Menu.Item>
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/fee-app">Fee App</NavLink>
          </Menu.Item>
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/note-app">Note Taking App</NavLink>
          </Menu.Item>
          {user.admin && (<> 
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/admin-app">Admin App</NavLink>
          </Menu.Item>
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/new-business-checklist">New Business App</NavLink>
          </Menu.Item>
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/renewals-app">Renewal App</NavLink>
          </Menu.Item>
          </>)}
          <Menu.Item style={{ alignItems: "end", paddingBottom: "1.3em" }}>
            <NavLink className={({ isActive }) => (isActive ? "menu-item-active" : "menu-item-inactive")} to="/group-scheme">Group Scheme Migration</NavLink>
          </Menu.Item>
          
    </>
)}

        
        {/* <Menu.Item position="right" style={{ alignItems: "end", display: "flex", flexDirection: "column", marginTop: "0.9em"}}> */}
        {/* <div style={{marginBottom: "1.3em"}}> */}
        {/* {isAuthenticated ? <SignOutButton /> : <SignInButton />} */}
        {/* {isAuthenticated && accounts.length > 0 && (
          <Label floating>{accounts[0].username.toLowerCase()}</Label>
        )} */}
        {/* </div> */}
        <Menu.Item position="right" style={{ alignItems: "end", paddingBottom: "1.3em"}}>
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}
        </Menu.Item>
        {/* </Menu.Item> */}
      </Container>
    </Menu>
          
    {props.children}

      {/* ---- Footer ---- */}
      <div id="footer">
      <Segment inverted vertical style={{ margin: "5em 0em 0em", padding: "5em, 0em" }}>
        <Container textAlign="center">
          <Divider inverted section/>
          <a href="https://www.cognitiv.co.nz/"><Header inverted as="h6">Powered by Cognitiv</Header></a>
        </Container>
      </Segment>
      </div>
    </>
  );
};