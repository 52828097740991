import React, { useState } from "react";
import { Button, Icon, TableCell, TableRow } from "semantic-ui-react";
import { formatDate } from "../constants";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import DynamicsWebApi from "dynamics-web-api";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { renewalInstance } from "../tableSchema";

export const RenewalDealRow = ({ deal }) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [status, setStatus] = useState(deal.status);

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  const updateRenewalInstance = async () => {
    var request = {
      collection: renewalInstance.plural,
      select: [renewalInstance.id],
      filter: `${renewalInstance.id} eq ${deal.id}`,
      count: true,
    };

    try {
      // Get related renewal instances
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      console.log("Here are the records to be updated:", response);

      // Update each record with new values
      for (const record of response.value) {
        const keyValuePair = {
          [renewalInstance.status]: 4,
        };

        // Update the record
        const updateRequest = {
          key: record[renewalInstance.id],
          collection: renewalInstance.plural,
          data: keyValuePair,
        };

        await dynamicsWebApi.updateRequest(updateRequest);
        console.log("Successfully updated renewal instance");
        setStatus("Email sent");
      }
    } catch (error) {
      console.log("Error updating renewal instances", error);
    }
  };

  const handleButtonClick = async (e) => {
    e.stopPropagation();
    await updateRenewalInstance();
  };

  const getRowColor = (daysTillRenewal) => {
    if (daysTillRenewal < 45) {
      return "#FAA0A0";
    } else if (daysTillRenewal < 75) {
      return "#FBCEB1";
    } else {
      return 'white';
    }
  };

  return (
    <TableRow
      onClick={() => navigate(`${deal.id}`)}
      style={{
        cursor: "pointer",
        backgroundColor: deal.status === "Pre-renewal complete" ? "#AFE1AF" : getRowColor(deal.days_till_renewal),
      }}
      key={`deal-table-row-${deal.id}`}
    >
      <TableCell>{deal.client_name}</TableCell>
      <TableCell>{deal.ibroker}</TableCell>
      <TableCell>{deal.pipedrive_id}</TableCell>
      <TableCell>{deal.days_till_renewal}</TableCell>
      <TableCell>{deal.renewal_date}</TableCell>
      <TableCell
        style={{
          justifyContent: "center",
        }}
      >
        <span>{deal.status}</span>
        {/* {status === "Pending email" && (
          <Button onClick={handleButtonClick} icon>
            <Icon name="check circle" />
            Mark Sent
          </Button>
        )} */}
      </TableCell>
    </TableRow>
  );
};
