import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Segment,
  Container,
  Header,
  Message,
  Table,
  Input,
  Modal,
  Dropdown,
  Popup,
  Icon,
} from "semantic-ui-react";
import RenewalDatePicker from "./RenewalDatePicker";
import "../styles/NameChangeForm.css";
import { useRenewalsContext } from "./context/renewalsContext";
import {
  adviserFeeTeamSchema,
  dealOwnersSchema,
  renewalDateReasonsSchema,
  renewalInstance,
  renewalMeetingInstance,
} from "../tableSchema";
import DynamicsWebApi from "dynamics-web-api";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { useMsal } from "@azure/msal-react";
import { useFetcher, useNavigate } from "react-router-dom";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { saveAs } from "file-saver";
import PizZipUtils from "pizzip/utils/index.js";
import expressionParser from "docxtemplater/expressions";
import myFile from "../../src/Advice Process Improvements Project planning meeting report to client.docx";
import {
  formatDateString,
  formatDateYearMonthDay,
  functionSite,
  mkey,
} from "../constants";
import axios from "axios";

export const RenewalsKeyMilestonesForm = ({ onBackButtonClick }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [formState, setFormState] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const { renewalInstanceData, renewalMeetingData, refetchData } =
    useRenewalsContext();
  const [pastTasks, setPastTasks] = useState([]);
  const [allTasks, setAllTasks] = useState([]);
  const [associates, setAllAssociates] = useState([]);
  const [loadingAssociates, setIsLoadingAssociates] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [selectedReason, setSelectedReason] = useState("");
  const [isValidReason, setIsValidReason] = useState(true);
  const [isValidOtherReason, setIsValidOtherReason] = useState(true);

  const [paymentOptions, setPaymentOptions] = useState([
    "Payment in full",
    "Funding",
    "3 or 4 monthly instalments",
    "Undecided",
    "Not discussed",
  ]);
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("Not selected");
  const [paymentReason, setPaymentReason] = useState(" ");

  const [riskAdviser, setRiskAdviser] = useState(
    renewalInstanceData.cr0d7_riskadviser ?? " "
  );

  const [associateRiskAdviser, setAssociateRiskAdviser] = useState(
    renewalInstanceData.cr0d7_associateriskadviser ?? " "
  );

  const [alternateRiskAdviser, setAlternateRiskAdviser] = useState(
    renewalInstanceData.cr0d7_alternateriskadviser ?? " "
  );

  const [claims, setClaims] = useState(renewalInstanceData.cr0d7_claims ?? " ");
  const [otherReason, setOtherReason] = useState(" ");

  const url = window.location.pathname;
  const id = url.split("/").pop();

  const navigate = useNavigate();

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  // Deal owners
  const [dealOwners, setDealOwners] = useState([]);

  //pull data into a dropdown to associate specific task depending the user_id
  useEffect(() => {
    setIsLoadingAssociates(true);
    const fetchAdviserTeams = async () => {
      var request = {
        collection: adviserFeeTeamSchema.plural,
        select: [
          adviserFeeTeamSchema.adviser_name,
          adviserFeeTeamSchema.pipedrive_id,
        ],
      };
      dynamicsWebApi.retrieveMultipleRequest(request).then(function (response) {
        const associates = response.value.map((person) => {
          return {
            key: person.cr0d7_name,
            text: person.cr0d7_name,
            value: person,
          };
        });
        setAllAssociates(associates);
      });
      setIsLoadingAssociates(false);
    };
    fetchAdviserTeams();
    fetchDealOwners();
  }, []);

  const fetchDealOwners = async () => {
    var request = {
      collection: dealOwnersSchema.plural,
    };
    console.log(request);
    dynamicsWebApi
      .retrieveMultipleRequest(request)
      .then(function (response) {
        const uniqueNames = {};
        const riskAdvisers = response.value
          .filter((person) => {
            if (!uniqueNames[person.cr0d7_name]) {
              uniqueNames[person.cr0d7_name] = true;
              return true;
            }
            return false;
          })
          .map((person) => ({
            key: person.cr0d7_name,
            text: person.cr0d7_name,
            value: person,
          }));
        setDealOwners(riskAdvisers);
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
  };

  const updateMeetingInstance = async () => {
    var request = {
      collection: renewalMeetingInstance.plural,
      select: [renewalMeetingInstance.id],
      filter: `${renewalMeetingInstance.relationship_id}/cr0d7_client eq '${renewalInstanceData.cr0d7_client}'`,
      count: true,
    };

    try {
      // Get related renewal instances
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      console.log("Here are the records to be updated:", response);
      console.log("updating tasks: ", allTasks);

      //Creates a key value pair of dates
      var dates = {};
      allTasks.forEach((task) => {
        dates[task.fieldName] = task.agreedDate;
      });
      // Update each record with new values
      for (const record of response.value) {
        const keyValuePair = {
          [renewalMeetingInstance.task_email_report]: dates["emailReport"],
          [renewalMeetingInstance.task_email_information]:
            dates["emailInformation"],
          [renewalMeetingInstance.task_preparation_pre_renewal_meeting]:
            dates["preparationPrerenewal"],
          [renewalMeetingInstance.task_pre_renewal]: dates["prerenewalMeeting"],
          [renewalMeetingInstance.task_email_pre_renewal]:
            dates["prerenewalRecap"],
          [renewalMeetingInstance.task_completed_renewal]:
            dates["completedRenewalInfo"],
          [renewalMeetingInstance.task_present_renewal]:
            dates["presentRenewalOutcomes"],
          [renewalMeetingInstance.task_action_required_instalment]:
            dates["actionAnyOutcomes"],
          [renewalMeetingInstance.task_renewal_agreement]:
            dates["renewalAgreement"],
          [renewalMeetingInstance.task_renewal_agreement]:
            dates["renewalAgreement"],
          [renewalMeetingInstance.task_renewal_confirmation]:
            dates["renewalConfirmation"],
          [renewalMeetingInstance.task_preparation_renewal_doc]:
            dates["preparationRenewalDoc"],
          [renewalMeetingInstance.task_email_renewal_doc]:
            dates["emailRenewalDoc"],
          [renewalMeetingInstance.task_select_payment_option]:
            dates["paymentSelect"],
          [renewalMeetingInstance.task_return_signed_document]:
            dates["returnSignedDocs"],
          [renewalMeetingInstance.task_payment_full]: dates["paymentInfull"],
          [renewalMeetingInstance.task_3_or_4_instalment]:
            dates["threeOrfourMonthly"],
          [renewalMeetingInstance.task_1st_instalment]: dates["oneinstalment"],
          [renewalMeetingInstance.task_2nd_instalment]: dates["twoinstalment"],
          [renewalMeetingInstance.task_3rd_instalment]:
            dates["threeinstalment"],
          [renewalMeetingInstance.task_4th_instalment]: dates["fourinstalment"],
          [renewalMeetingInstance.agreed_date_reason]: selectedReason,
          [renewalMeetingInstance.other_agreed_reason]: otherReason,
          [renewalMeetingInstance.payment_option]: selectedPaymentOption,
          [renewalMeetingInstance.payment_reason]: paymentReason,
        };
        // Update the record
        const updateRequest = {
          key: record[renewalMeetingInstance.id],
          collection: renewalMeetingInstance.plural,
          data: keyValuePair,
        };

        await dynamicsWebApi.updateRequest(updateRequest);
        console.log("Successfully updated renewal instance");
        refetchData();
      }
    } catch (error) {
      console.log("Error updating renewal instances", error);
    }
  };

  const updateRenewalInstance = async (statusValue) => {
    var request = {
      collection: renewalInstance.plural,
      select: [renewalInstance.id],
      filter: `${renewalInstance.id} eq ${id}`,
      count: true,
    };

    dynamicsWebApi
      .retrieveMultipleRequest(request)
      .then((response) => {
        // Update Renewal instance dataverse table
        response.value.forEach(async (record) => {
          for (const record of response.value) {
            const keyValuePair = {
              [renewalInstance.status]: statusValue,
            };
            // Update the record
            const updateRequest = {
              key: record[renewalInstance.id],
              collection: renewalInstance.plural,
              data: keyValuePair,
            };
            await dynamicsWebApi.updateRequest(updateRequest);
            console.log("Successfully updated renewal instance");
          }
        });
      })
      .catch(function (error) {
        console.log("Error fetching relationship", error);
      });
  };

  useEffect(() => {
    const fetchReasons = async () => {
      var request = {
        collection: renewalDateReasonsSchema.plural,
        select: [
          renewalDateReasonsSchema.reason_name,
          renewalDateReasonsSchema.id,
        ],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          setReasons(response.value);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };
    fetchReasons();
  }, []);

  useEffect(() => {
    refetchData();
  }, []);

  const loadFile = async () => {
    try {
      // Read the file content
      console.log("here is my file: ", myFile);
      const response = await fetch(myFile);
      const content = await response.arrayBuffer();
      console.log("here is the content: ", content);
      return content;
    } catch (error) {
      console.error("Error loading file:", error);
    }
  };

const commonExcludedTasks = [
  "threeOrfourMonthly",
  "oneinstalment",
  "twoinstalment",
  "threeinstalment",
  "fourinstalment",
];

const excludedTasks = {
  "3 or 4 monthly instalments": ["returnSignedDocs", "paymentReason"],
  "Not discussed": [...commonExcludedTasks, "returnSignedDocs"],
  "Undecided": [...commonExcludedTasks, "returnSignedDocs"],
  Funding: [
    ...commonExcludedTasks,
    "paymentReason",
  ],
  "Payment in full": [
    ...commonExcludedTasks,
    "returnSignedDocs",
    "paymentReason",
  ],
  "Not selected": [
    ...commonExcludedTasks,
    "returnSignedDocs",
    "paymentReason",
  ]

};

  const filteredTasks = allTasks.filter((task) => {
    const excludedTaskNames = excludedTasks[selectedPaymentOption] || [];
    return !excludedTaskNames.includes(task.fieldName);
  });

  const generateDocument = async () => {
    const content = await loadFile();
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
      parser: expressionParser,
    });

    const taskResponsibilities = {};
    allTasks.forEach((task) => {
      taskResponsibilities[task.fieldName + "Responsibility"] =
        renewalInstanceData.cr0d7_riskadviser ?? " ";
    });

    var dates = {};
    allTasks.forEach((task) => {
      dates[task.fieldName] = task.agreedDate;
    });

    doc.render({
      clientName: `${renewalInstanceData.cr0d7_fullclientname ?? "____"}`,
      clientContact: `${renewalInstanceData.cr0d7_clientcontactname ?? "____"}`,
      clientContactEmail: `${
        renewalInstanceData.cr0d7_clientcontactemail ?? "____"
      }`,
      iBroker: `${renewalInstanceData.cr0d7_ibrokerid}`,
      renewalDate: `${new Date(
        renewalInstanceData.cr0d7_renewaldate
      ).toLocaleDateString("en-NZ")}`,
      prerenewalMeeting: new Date(
        dates["prerenewalMeeting"]
      ).toLocaleDateString("en-NZ"),
      emailInformation: new Date(dates["emailInformation"]).toLocaleDateString(
        "en-NZ"
      ),
      emailReport: new Date(dates["emailReport"]).toLocaleDateString("en-NZ"),
      prepRenewalMeeting: new Date(dates["prerenewalRecap"]).toLocaleDateString(
        "en-NZ"
      ),
      meetingRecap: new Date(dates["preparationPrerenewal"]).toLocaleDateString(
        "en-NZ"
      ),
      completedRenewalInfo: new Date(
        dates["completedRenewalInfo"]
      ).toLocaleDateString("en-NZ"),
      presentRenewal: new Date(
        dates["presentRenewalOutcomes"]
      ).toLocaleDateString("en-NZ"),
      anyChanges: new Date(dates["actionAnyOutcomes"]).toLocaleDateString(
        "en-NZ"
      ),
      renewalAgreement: new Date(dates["renewalAgreement"]).toLocaleDateString(
        "en-NZ"
      ),
      renewalConfirmation: new Date(
        dates["renewalConfirmation"]
      ).toLocaleDateString("en-NZ"),
      renewalDocumentation: new Date(
        dates["emailRenewalDoc"]
      ).toLocaleDateString("en-NZ"),
      returnSignFunding: new Date(dates["returnSignedDocs"]).toLocaleDateString(
        "en-NZ"
      ),
      paymentFull: new Date(dates["paymentInfull"]).toLocaleDateString("en-NZ"),
      threeOrFour: new Date(dates["threeOrfourMonthly"]).toLocaleDateString(
        "en-NZ"
      ),
      oneInstalment: new Date(dates["oneinstalment"]).toLocaleDateString(
        "en-NZ"
      ),
      twoInstalment: new Date(dates["twoinstalment"]).toLocaleDateString(
        "en-NZ"
      ),
      threeInstalment: new Date(dates["threeinstalment"]).toLocaleDateString(
        "en-NZ"
      ),
      fourInstalment: new Date(dates["fourinstalment"]).toLocaleDateString(
        "en-NZ"
      ),
      ...taskResponsibilities,
      attendees: `${riskAdviser.cr0d7_name ?? " "} ${
        associateRiskAdviser.cr0d7_name ?? " "
      } ${alternateRiskAdviser} ${claims}`,
    });
    const out = doc.getZip().generate({
      type: "blob",
      mimeType:
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    }); //Output the document using Data-URI
    saveAs(
      out,
      "Advice Process Improvements Project_planning meeting report to client.docx"
    );
  };

  const addPipedriveActivities = async () => {
    var endpoint = `${functionSite}BulkCreateActivities?code=${mkey}`;
    //renewalInstanceData.cr0d7_pipedriveid
    //need to format the deal id to string
    const dealId = renewalInstanceData.cr0d7_pipedriveid;
    const pipedriveTasks = [
      {
        deal_id: dealId,
        user_id: parseInt(associateRiskAdviser.cr0d7_pipedriveid) ?? null,
        subject: "Prep for Planning Mtg - claims team, info list etc",
        note: "Prep for Planning Mtg - claims team, info list etc",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            90,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        note: "Request from UW Claims History & Rnwl Requirements",
        user_id: parseInt(associateRiskAdviser.cr0d7_pipedriveid) ?? null,
        subject: "Request from UW Claims History & Rnwl Requirements",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            90,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        user_id: parseInt(riskAdviser.cr0d7_pipedriveid) ?? null,
        note: "Renewal Timeframe Agreed with Client",
        subject: "Renewal Timeframe Agreed with Client",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            76,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        user_id: parseInt(riskAdviser.cr0d7_pipedriveid) ?? null,
        note: "Discussion with UW (large or complex rnwl)",
        subject: "Discussion with UW (large or complex rnwl)",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            80,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        user_id: parseInt(riskAdviser.cr0d7_pipedriveid) ?? null,
        note: "Email Planning Mtg outcome to client",
        subject: "Email Planning Mtg outcome to client",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            75,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        note: "Prep Email Pre Rnwl Info & Requirements to Client",
        user_id: parseInt(associateRiskAdviser.cr0d7_pipedriveid) ?? null,
        subject: "Prep Email Pre Rnwl Info & Requirements to Client",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            70,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        user_id: parseInt(riskAdviser.cr0d7_pipedriveid) ?? null,
        note: "Send Client Pre Rnwl Info & Rnwl Requirement email",
        subject: "Send Client Pre Rnwl Info & Rnwl Requirement email",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            65,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        user_id: parseInt(riskAdviser.cr0d7_pipedriveid) ?? null,
        note: "Review & Finalise Prep for Pre Rnwl Mtg",
        subject: "Review & Finalise Prep for Pre Rnwl Mtg",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            63,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
      {
        deal_id: dealId,
        user_id: parseInt(riskAdviser.cr0d7_pipedriveid) ?? null,
        note: "Action Meeting Outcomes",
        subject: "Action Meeting Outcomes",
        due_date: formatDateYearMonthDay(
          calculateDefaultDate(
            20,
            "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        ),
      },
    ];
    //   const formattedTasks = allTasks.map((task) => {
    //     return {
    //         deal_id: 22414,
    //         note: task.label,
    //         due_date: formatDateString(task.agreedDate)
    //     };
    // });

    const body = {
      activities: pipedriveTasks,
    };

    try {
      let response = await axios.post(endpoint, body);
      return response.data;
    } catch (error) {
      console.log("there were errors: ", error);
    }
  };

  // const handleSubmit = async (e) => {
  //   // Prevent the browser from reloading the page
  //   e.preventDefault();

  //   if (validateFields()) {
  //     setIsLoading(true);
  //     await updateMeetingInstance();
  //     await updateRenewalInstance(3);
  //     var message = `Successfully saved for client: ${renewalInstanceData.cr0d7_fullclientname}`;
  //     setSuccessMessage(message);
  //     setFormState("success");
  //     setIsLoading(false);
  //     await generateDocument();
  //     await addPipedriveActivities();
  //     setTimeout(() => {
  //       navigate("/completed");
  //     }, 5000);
  //   }
  // };

  const calculateDefaultDate = (offset, type, date) => {
    const renewalDate = new Date(date);
    let defaultDate;
    if (type === "add") {
      defaultDate = new Date(
        renewalDate.getTime() + offset * 24 * 60 * 60 * 1000
      );
    } else {
      defaultDate = new Date(
        renewalDate.getTime() - offset * 24 * 60 * 60 * 1000
      );
    }
    return defaultDate;
  };

  useEffect(() => {
    const generateTasks = () => {
      const taskConfigurations = [
        {
          label:
            "Email report of planning meeting to client (agreed timeframe etc)",
          fieldName: "emailReport",
          defaultOffset: 75,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskemailreport
            ? new Date(renewalMeetingData.cr0d7_taskemailreport)
            : new Date(
                calculateDefaultDate(
                  75,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Email information request to client (renewal requirements)",
          fieldName: "emailInformation",
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          defaultOffset: 70,
          agreedDate: renewalMeetingData.cr0d7_taskemailinformation
            ? new Date(renewalMeetingData.cr0d7_taskemailinformation)
            : new Date(
                calculateDefaultDate(
                  70,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label:
            "Preparation for pre-renewal meeting - gathering of required information",
          fieldName: "preparationPrerenewal",
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          defaultOffset: 5,
          agreedDate: renewalMeetingData.cr0d7_taskpreparationprerenewalmeeting
            ? new Date(
                renewalMeetingData.cr0d7_taskpreparationprerenewalmeeting
              )
            : new Date(
                calculateDefaultDate(
                  5,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Pre-renewal meeting",
          fieldName: "prerenewalMeeting",
          defaultOffset: 60,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",

          agreedDate: renewalMeetingData.cr0d7_taskprerenewal
            ? new Date(renewalMeetingData.cr0d7_taskprerenewal)
            : new Date(
                calculateDefaultDate(
                  60,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Email pre-renewal meeting recap to client",
          fieldName: "prerenewalRecap",
          defaultOffset: 59,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskemailprerenewal
            ? new Date(renewalMeetingData.cr0d7_taskemailprerenewal)
            : new Date(
                calculateDefaultDate(
                  59,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label:
            "Completed Renewal information back to DB (proposals, declarations, valuations, schedules etc",
          fieldName: "completedRenewalInfo",
          defaultOffset: 50,
          responsibility: renewalInstanceData.cr0d7_clientcontactname ?? " ",

          agreedDate: renewalMeetingData.cr0d7_taskcompletedrenewal
            ? new Date(renewalMeetingData.cr0d7_taskcompletedrenewal)
            : new Date(
                calculateDefaultDate(
                  50,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label:
            "Present renewal outcomes with options and reccomendations including payments",
          fieldName: "presentRenewalOutcomes",
          defaultOffset: 23,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          agreedDate: renewalMeetingData.taskpresentrenewal
            ? new Date(renewalMeetingData.taskpresentrenewal)
            : new Date(
                calculateDefaultDate(
                  23,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Action any required changes to renewal outcomes",
          fieldName: "actionAnyOutcomes",
          defaultOffset: 20,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskactionrequired
            ? new Date(renewalMeetingData.cr0d7_taskactionrequired)
            : new Date(
                calculateDefaultDate(
                  20,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Renewal agreement from client to DB",
          fieldName: "renewalAgreement",
          responsibility: renewalInstanceData.cr0d7_clientcontactname ?? " ",
          defaultOffset: 15,
          agreedDate: renewalMeetingData.cr0d7_taskrenewalagreement
            ? new Date(renewalMeetingData.cr0d7_taskrenewalagreement)
            : new Date(
                calculateDefaultDate(
                  15,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label:
            "Renewal confirmation to insurers with confirmation back to client",
          fieldName: "renewalConfirmation",
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          defaultOffset: 15,
          agreedDate: renewalMeetingData.cr0d7_taskrenewalconfirmation
            ? new Date(renewalMeetingData.cr0d7_taskrenewalconfirmation)
            : new Date(
                calculateDefaultDate(
                  15,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label:
            "Preparation of renewal documentation including invoices and agreed payment options",
          fieldName: "preparationRenewalDoc",
          defaultOffset: 14,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskpreparationrenewaldoc
            ? new Date(renewalMeetingData.cr0d7_taskpreparationrenewaldoc)
            : new Date(
                calculateDefaultDate(
                  14,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },

        {
          label: "Email renewal documentation including invoice to client",
          fieldName: "emailRenewalDoc",
          defaultOffset: 8,
          responsibility: renewalInstanceData.cr0d7_riskadviser ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskemailrenewaldoc
            ? new Date(renewalMeetingData.cr0d7_taskemailrenewaldoc)
            : new Date(
                calculateDefaultDate(
                  8,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Payment - select agreed option",
          fieldName: "paymentSelect",
          defaultOffset: 5,
          agreedDate: renewalMeetingData.cr0d7_taskpaymentselectoption
            ? new Date(renewalMeetingData.cr0d7_taskpaymentselectoption)
            : new Date(
                calculateDefaultDate(
                  5,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
        },
        {
          label: "Payment Option",
          fieldName: "paymentInfull",
          defaultOffset: 1,
          paymentValue: " ",
          responsibility: renewalInstanceData.cr0d7_clientcontactname ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskpaymentinfull
            ? new Date(renewalMeetingData.cr0d7_taskpaymentinfull)
            : new Date(
                calculateDefaultDate(
                  1,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Payment Reason",
          fieldName: "paymentReason",
          defaultOffset: 1,
          paymentValue: " ",
          responsibility: renewalInstanceData.cr0d7_clientcontactname ?? " ",
          agreedDate: renewalMeetingData.cr0d7_taskpaymentinfull
            ? new Date(renewalMeetingData.cr0d7_taskpaymentinfull)
            : new Date(
                calculateDefaultDate(
                  1,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "Return signed funding documents",
          fieldName: "returnSignedDocs",
          defaultOffset: 5,
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
          agreedDate: renewalMeetingData.cr0d7_taskreturnsigneddocument
            ? new Date(renewalMeetingData.cr0d7_taskreturnsigneddocument)
            : new Date(
                calculateDefaultDate(
                  5,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "3 or 4 monthly instalment (if applicabel)",
          fieldName: "threeOrfourMonthly",
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
          defaultOffset: 0,
          agreedDate: renewalMeetingData.cr0d7_task3or4instalment
            ? new Date(renewalMeetingData.cr0d7_task3or4instalment)
            : new Date(
                calculateDefaultDate(
                  0,
                  "minus",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "1st instalment",
          fieldName: "oneinstalment",
          defaultOffset: 5,
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
          type: "add",
          agreedDate: renewalMeetingData.cr0d7_task1instalment
            ? new Date(renewalMeetingData.cr0d7_task1instalment)
            : new Date(
                calculateDefaultDate(
                  5,
                  "add",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "2nd instalment",
          fieldName: "twoinstalment",
          defaultOffset: 30,
          type: "add",
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
          agreedDate: renewalMeetingData.cr0d7_task2ndinstalment
            ? new Date(renewalMeetingData.cr0d7_task2ndinstalment)
            : new Date(
                calculateDefaultDate(
                  30,
                  "add",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "3rd instalment",
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
          fieldName: "threeinstalment",
          defaultOffset: 60,
          type: "add",
          agreedDate: renewalMeetingData.cr0d7_rdinstalment
            ? new Date(renewalMeetingData.cr0d7_rdinstalment)
            : new Date(
                calculateDefaultDate(
                  60,
                  "add",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
        {
          label: "4th instalment (if Applicable)",
          fieldName: "fourinstalment",
          defaultOffset: 75,
          responsibility: renewalInstanceData.cr0d7_clientcontactname,
          type: "add",
          agreedDate: renewalMeetingData.cr0d7_task4thinstalment
            ? new Date(renewalMeetingData.cr0d7_task4thinstalment)
            : new Date(
                calculateDefaultDate(
                  75,
                  "add",
                  renewalInstanceData.cr0d7_renewaldate
                )
              ),
        },
      ];

      const generatedTasks = taskConfigurations.map((task) => {
        const internalDate = new Date(
          calculateDefaultDate(
            task.defaultOffset,
            task.type === "add" ? "add" : "minus",
            renewalInstanceData.cr0d7_renewaldate
          )
        );
        // const agreedDate = renewalMeetingData[task.fieldName]
        //   ? new Date(renewalMeetingData[task.fieldName])
        //   : new Date(internalDate);
        const warning = task.agreedDate > internalDate;

        return {
          ...task,
          internalDate,
          // agreedDate,
          warning,
        };
      });
      return generatedTasks;
    };
    setAllTasks(generateTasks());
  }, [renewalMeetingInstance]);

  useEffect(() => {
    console.log("here are the tasks: ", allTasks);
  }, [allTasks]);

  const handleCompleteRewenal = async () => {
    console.log("selected reason: ", selectedReason);
    if (selectedReason || pastTasks.length === 0) {
      const validated = validateFields();
      if (validated) {
        setIsLoading(true);
        await updateMeetingInstance();
        await updateRenewalInstance(3);
        var message = `Successfully saved for client: ${renewalInstanceData.cr0d7_fullclientname}`;
        setSuccessMessage(message);
        setFormState("success");
        setShowModal(false);
        setIsLoading(false);
        await generateDocument();
        await addPipedriveActivities();
        setTimeout(() => {
          navigate("completed");
        }, 5000);
      }
    }
  };

  const handleNextButtonClick = async () => {
    const pastTasks = allTasks.filter((task) => {
      return task.agreedDate > task.internalDate;
    });
    setPastTasks(pastTasks);
    console.log("here are the tasks that are past: ", pastTasks);
    if (pastTasks.length > 0) {
      setShowModal(true);
    } else {
      console.log("There are no past tasks!");
      await handleCompleteRewenal();
    }
  };

  const onChange = (fieldName, date, responsibility) => {
    console.log("date on change called!", fieldName);
    console.log("Selected new date: ", date);
    const updatedTasks = allTasks.map((task) => {
      if (task.fieldName === fieldName) {
        const warning = date > task.internalDate;
        return {
          ...task,
          agreedDate: date,
          responsibility: responsibility,
          warning: warning,
        };
      }
      return task;
    });
    console.log("here are the updated tasks: ", updatedTasks);
    setAllTasks(updatedTasks);
  };

  const onResponsiblityChange = (fieldName, responsibilityValue) => {
    const updatedTasks = allTasks.map((task) => {
      if (task.fieldName === fieldName) {
        return {
          ...task,
          responsibility: responsibilityValue,
        };
      }
      return task;
    });
    setAllTasks(updatedTasks);
  };

  const validateFields = () => {
    if (pastTasks && pastTasks.length > 0) {
      setIsValidReason(selectedReason.length > 0);
      if (isValidReason) {
        return true;
      } else {
        setFormState("error");
        return false;
      }
    } else {
      return true;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await updateMeetingInstance();
    await updateRenewalInstance(2);
    var message = `Successfully saved for client: ${renewalInstanceData.cr0d7_fullclientname}`;
    setSuccessMessage(message);
    setFormState("success");
    setIsLoading(false);
  };

  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            Renewal App
            <Header.Subheader>
              Fill out the form and click complete to generate tasks and
              planning meeting report. Click save to save your progress and
              complete at a later date.
            </Header.Subheader>
          </Header>
        </Container>
      </Segment>
      <Container text style={{ padding: "1em 0em" }}>
        <Form
          loading={isLoading}
          method="post"
          // onSubmit={handleSubmit}
          error={formState === "error"}
          success={formState === "success"}
        >
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Task
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Internal date
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Agreed date
                </Table.HeaderCell>
                <Table.HeaderCell
                  style={{ backgroundColor: "#03C5A1", color: "white" }}
                >
                  Responsibility
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filteredTasks.map((task, index) => (
                <Table.Row key={index}>
                  <Table.Cell error={task.warning}>{task.label}</Table.Cell>
                  <Table.Cell error={task.warning}>
                    {task.warning && (
                      <div>
                        <Popup
                          position="top right"
                          content="The Selected agreed date is past the internal date. You will be required to select a reason when it comes to completion of the form"
                          trigger={<Icon name="info circle" />}
                        />
                      </div>
                    )}
                    {task.internalDate.toLocaleDateString("en-NZ")}
                  </Table.Cell>
                  <Table.Cell error={task.warning}>
                    {task.fieldName === "paymentInfull" ? (
                      <Dropdown
                        placeholder="Select payment Option"
                        fluid
                        selection
                        options={paymentOptions.sort().map((reason) => ({
                          key: reason,
                          value: reason,
                          text: reason,
                        }))}
                        onChange={(e, { value }) => {
                          setSelectedPaymentOption(value);
                        }}
                      />
                    ) : task.fieldName === "paymentReason" ? (
                      <Input
                        placeholder="Enter Reason for Payment option"
                        fluid
                        onChange={(e) => {
                          const newValue = e.target.value;
                          setPaymentReason(newValue)
                        }}
                      />
                    ) : (
                      <RenewalDatePicker
                        label={task.label}
                        fieldName={task.fieldName}
                        defaultOffset={task.defaultOffset}
                        onChange={onChange}
                        date={new Date(task.agreedDate)}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell error={task.warning}>
                    <Input
                      defaultValue={task.responsibility}
                      fluid
                      onChange={(e) => {
                        const newValue = e.target.value;
                        onResponsiblityChange(task.fieldName, newValue);
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <Table celled>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Risk Adviser</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    placeholder={"Select risk adviser"}
                    fluid
                    selection
                    value={riskAdviser}
                    onChange={(e, { value }) => {
                      console.log("here is the value: ", value);
                      setRiskAdviser(value);
                    }}
                    options={dealOwners}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Associate Risk Adviser</Table.Cell>
                <Table.Cell>
                  <Dropdown
                    loading={loadingAssociates}
                    placeholder={"Select associate risk adviser"}
                    fluid
                    selection
                    value={associateRiskAdviser}
                    onChange={(e, { value }) => {
                      console.log("here is the value: ", value);
                      setAssociateRiskAdviser(value);
                    }}
                    options={associates}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Alternate Risk Adviser (if applicable)</Table.Cell>
                <Table.Cell>
                  <Input
                    defaultValue={alternateRiskAdviser}
                    fluid
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setAlternateRiskAdviser(newValue);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Claims (if applicable)</Table.Cell>
                <Table.Cell>
                  <Input
                    defaultValue={claims}
                    fluid
                    onChange={(e) => {
                      const newValue = e.target.value;
                      setClaims(newValue);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Button
            fluid
            type="submit"
            content="Save"
            color="green"
            style={{ margin: "2em 0em 0.5em" }}
            onClick={handleSave}
          />
          <Button
            type="button"
            onClick={handleNextButtonClick}
            fluid
            color="green"
            content="Complete and submit"
          />
          <Button
            fluid
            basic
            style={{ margin: "0.5em 0em 0.5em" }}
            content="Back"
            onClick={onBackButtonClick}
          />
          <Message
            success
            header="Renewal deal saved!"
            content={successMessage}
          />
          <Message
            error
            header="Error"
            content="Error saving renewal deal information"
          />
        </Form>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          style={{ height: 850 }}
          centered={true}
        >
          <Modal.Header>Reason for dates falling outside of approved timelines</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>These dates fall after the internal due dates:</p>
              <ul>
                {pastTasks.map((task, index) => (
                  <li key={index}>{task.label}</li>
                ))}
              </ul>
            </Modal.Description>
            {(pastTasks && pastTasks.length) > 0 && (
              <Dropdown
                placeholder="Reason why"
                search
                clearable
                selection
                className="dropdown-custom"
                options={reasons.sort().map((reason) => ({
                  key: reason[renewalDateReasonsSchema.id],
                  value: reason.cr0d7_name,
                  text: reason[renewalDateReasonsSchema.reason_name],
                }))}
                value={selectedReason}
                onChange={(e, { value }) => {
                  console.log("here is the value: ", value);
                  setSelectedReason(value);
                  setIsValidReason(value.length > 0);
                }}
              />
            )}
            {selectedReason.trim() ===
              "Other, please specify reason for delay" && (
              <Form.Input
                fluid
                label="Other Reason"
                type="text"
                autoComplete="off"
                value={otherReason}
                onChange={(e) => {
                  setOtherReason(e.target.value);
                  setIsValidOtherReason(e.target.value.length > 0);
                }}
                error={
                  !isValidOtherReason
                    ? {
                        content: "Please enter a valid reason",
                        pointing: "above",
                      }
                    : null
                }
                required
              />
            )}

            {!isValidReason && (
              <p style={{ color: "red" }}>
                Please select a valid reason as to why you have chosen dates out
                side of internal.
              </p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowModal(false)}>Close</Button>
            <Button
              type="button"
              color="green"
              content="Complete"
              onClick={handleCompleteRewenal}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    </>
  );
};

export default RenewalsKeyMilestonesForm;
