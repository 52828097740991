import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Dropdown,
  Button,
  Segment,
  Container,
  Header,
  Table,
  Pagination,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { adviserFeeTeamSchema } from "../tableSchema";

export const TeamFeeTable = () => {
  const { instance, accounts } = useMsal();
  const [isloading, setIsLoading] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [newTeamUser, setNewTeamUser] = useState("");
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = teamData.slice(indexOfFirstItem, indexOfLastItem);

  const [checkboxes, setCheckboxes] = useState({
    associate: false,
    finlinesTeam: false,
    paTeam: false,
    raTeam: false,
  });

  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  useEffect(() => {
    const fetchAdviserTeams = async () => {
      var request = {
        collection: adviserFeeTeamSchema.plural,
        select: [
          adviserFeeTeamSchema.adviser_name,
          adviserFeeTeamSchema.associate,
          adviserFeeTeamSchema.pa_team,
          adviserFeeTeamSchema.fin_team,
          adviserFeeTeamSchema.ra_team,
          adviserFeeTeamSchema.id,
        ],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          const teams = response.value.map((person) => ({
            name: person.cr0d7_name,
            associate: person.cr0d7_associate || false,
            paTeam: person.cr0d7_pateam || false,
            finlinesTeam: person.cr0d7_finlinesteam || false,
            raTeam: person.cr0d7_rateam || false,
            key: person.cr0d7_dbadviserfeerolesid || null,
          }));
          // Sort teams alphabetically by name
          teams.sort((a, b) => a.name.localeCompare(b.name));
          setTeamData(teams);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };

    fetchAdviserTeams();
  }, []);

  const retrieveTeamFeeUser = async (rowData) => {
    var request = {
      collection: adviserFeeTeamSchema.plural,
      select: [adviserFeeTeamSchema.id],
      filter: `${adviserFeeTeamSchema.id} eq '${rowData.key}'`,
      count: true,
    };

    try {
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      return response;
    } catch (error) {
      console.log("Error retrieving row", error);
      return null;
    }
  };

  const createTeamFeeRow = async (rowData) => {
    // var entity = {
    //     cr749_companyname_: company.cr749_companyname,
    //     cr749_businesssection: businessSection.key
    // };
    var entity = {};
    entity[adviserFeeTeamSchema.adviser_name] = rowData.name;
    entity[adviserFeeTeamSchema.ra_team] = rowData.raTeam;
    entity[adviserFeeTeamSchema.pa_team] = rowData.paTeam;
    entity[adviserFeeTeamSchema.associate] = rowData.associate;
    entity[adviserFeeTeamSchema.fin_team] = rowData.finlinesTeam;

    var request = {
      // collection: "cr749_db_businesslines",
      collection: adviserFeeTeamSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    const response = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        console.log("created record: ", record);
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return response;
  };

  const updateTeamFeeUser = async (rowData) => {
    console.log("here is the row data: ", rowData);
    var entity = {};
    entity[adviserFeeTeamSchema.adviser_name] = rowData.name;
    entity[adviserFeeTeamSchema.ra_team] = rowData.raTeam;
    entity[adviserFeeTeamSchema.pa_team] = rowData.paTeam;
    entity[adviserFeeTeamSchema.associate] = rowData.associate;
    entity[adviserFeeTeamSchema.fin_team] = rowData.finlinesTeam;

    var request = {
      key: rowData.key,
      collection: adviserFeeTeamSchema.plural,
      entity: entity,
      returnRepresentation: true,
      select: [adviserFeeTeamSchema.id],
    };

    dynamicsWebApi
      .updateRequest(request)
      .then((response) => {
        console.log(`Successfully updated Row ${entity}`);
      })
      .catch((error) => {
        console.log("Error updating client row", error);
      });
  };

  const deleteTeamFeeUser = async (rowData) => {
    var request = {
      key: rowData.key,
      collection: adviserFeeTeamSchema.plural,
    };

    try {
      await dynamicsWebApi.deleteRequest(request);
      console.log(`Successfully deleted row with key ${rowData.key}`);
    } catch (error) {
      console.log("Error deleting row", error);
    }
  };

  const handleCheckboxChange = (pageIndex, itemIndex, name) => {
    const adjustedIndex = pageIndex * itemsPerPage + itemIndex;
    const updatedData = [...teamData];
    updatedData[adjustedIndex] = {
      ...updatedData[adjustedIndex],
      [name]: !updatedData[adjustedIndex][name],
    };
    setTeamData(updatedData);
  };

  const handleAddRow = () => {
    setTeamData([
      ...teamData,
      {
        name: "",
        associate: false,
        paTeam: false,
        finlinesTeam: false,
        raTeam: false,
        key: null,
      },
    ]);
  };

  const handleInputChange = (pageIndex, itemIndex, field, value) => {
    const adjustedIndex = pageIndex * itemsPerPage + itemIndex;
    const updatedData = [...teamData];
    updatedData[adjustedIndex] = {
      ...updatedData[adjustedIndex],
      [field]: value,
    };
    setTeamData(updatedData);
  };

  const handleSaveRow = async (index) => {
    const adjustedIndex = indexOfFirstItem + index;
    const rowData = teamData[adjustedIndex];
    console.log("here is the row data: ", rowData);
    if (!rowData || !rowData.name || rowData.name.trim().length === 0) {
      return;
    }

    const updatedData = [...teamData];
    updatedData[adjustedIndex] = { ...rowData, isSaving: true };
    setTeamData(updatedData);

    let updatedKey = null; 

    try {
      const response = await retrieveTeamFeeUser(rowData);
      if (response && response?.value?.length > 0) {
        await updateTeamFeeUser(rowData);
      } else {
        const createdRow = await createTeamFeeRow(rowData);
        updatedKey = createdRow?.cr0d7_dbadviserfeerolesid || null;
        const updatedRow = {
          ...rowData,
          key: updatedKey,
        };
        updatedData[adjustedIndex] = updatedRow;
        console.log("here is the updated row: ", updatedRow);
        setTeamData(updatedData);
      }
    } catch (error) {
      console.error("Error saving row:", error);
    } finally {
      const finalData = [...teamData];
      finalData[adjustedIndex] = {
        ...rowData,
        key: updatedKey,
        isSaving: false,
      };
      setTeamData(finalData);
    }
  };

  const handleDeleteRow = async (index) => {
    const adjustedIndex = indexOfFirstItem + index;
    const rowData = teamData[adjustedIndex];
    console.log("here is the row data to delete: ", rowData);
    if (!rowData || !rowData.name || rowData.name.trim().length === 0) {
      return;
    }
    await deleteTeamFeeUser(rowData);
    const updatedData = [...teamData];
    updatedData.splice(adjustedIndex, 1);
    setTeamData(updatedData);
  };

  return (
    <>
      <Container text style={{ padding: "1em 0em" }}>
        <Header as="h3">Fee App Team</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Name
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Associate
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                PA Team
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Fin Team
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                RA Team
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentItems.map((team, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Form.Input
                    value={team.name}
                    onChange={(e, { value }) =>
                      handleInputChange(activePage - 1, index, "name", value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Checkbox
                    checked={team.associate}
                    onChange={() =>
                      handleCheckboxChange(activePage - 1, index, "associate")
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Checkbox
                    checked={team.paTeam}
                    onChange={() =>
                      handleCheckboxChange(activePage - 1, index, "paTeam")
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Checkbox
                    checked={team.finlinesTeam}
                    onChange={() =>
                      handleCheckboxChange(
                        activePage - 1,
                        index,
                        "finlinesTeam"
                      )
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Checkbox
                    checked={team.raTeam}
                    onChange={() =>
                      handleCheckboxChange(activePage - 1, index, "raTeam")
                    }
                  />
                </Table.Cell>

                <Table.Cell>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      color="green"
                      onClick={() => handleSaveRow(index)}
                      disabled={!team.name || team.isSaving}
                    >
                      {team.isSaving ? "Saving..." : "Save"}
                    </Button>

                    <Button onClick={() => handleDeleteRow(index)}>
                      Delete
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination
          activePage={activePage}
          onPageChange={(e, { activePage }) => setActivePage(activePage)}
          totalPages={Math.ceil(teamData.length / itemsPerPage)}
        />
        <Button color="green" style={{ marginLeft: 10 }} onClick={handleAddRow}>
          Add Row
        </Button>
      </Container>
    </>
  );
};
