import React, { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import {
  Form,
  Dropdown,
  Button,
  Segment,
  Container,
  Header,
  Table,
  Pagination,
} from "semantic-ui-react";
import DynamicsWebApi from "dynamics-web-api";
import "../styles/NameChangeForm.css";
import { dynamicsParams, dynamicsWebApiCallback } from "../authConfig";
import { dbUsersSchema } from "../tableSchema";

export const DbUsersTable = () => {
  const { instance, accounts } = useMsal();
  const [isloading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [newUser, setNewUser] = useState("");
  const [activePage, setActivePage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = userData.slice(indexOfFirstItem, indexOfLastItem);


  const dynamicsWebApi = new DynamicsWebApi({
    ...dynamicsParams,
    onTokenRefresh: (callback) => {
      dynamicsWebApiCallback(instance, accounts, callback);
    },
  });

  useEffect(() => {
    const fetchDbUsers = async () => {
      var request = {
        collection: dbUsersSchema.plural,
        select: [
          dbUsersSchema.email,
          dbUsersSchema.admin,
          dbUsersSchema.id,
        ],
      };
      dynamicsWebApi
        .retrieveMultipleRequest(request)
        .then(function (response) {
          const dbUsers = response.value.map((user) => ({
            email: user.cr0d7_email,
            admin: user.cr0d7_admin || false,
            key: user.cr0d7_dbusersid || null,
          }));
          // Sort teams alphabetically by name
          dbUsers.sort((a, b) => a.email.localeCompare(b.email));
        console.log('here are the users: ', dbUsers);
        setUserData(dbUsers);
        })
        .catch(function (error) {
          console.log(JSON.stringify(error));
        });
    };
    fetchDbUsers();
  }, []);

  const retrieveDbUser = async (rowData) => {
    var request = {
      collection: dbUsersSchema.plural,
      select: [dbUsersSchema.id],
      filter: `${dbUsersSchema.id} eq '${rowData.key}'`,
      count: true,
    };

    try {
      const response = await dynamicsWebApi.retrieveMultipleRequest(request);
      return response;
    } catch (error) {
      console.log("Error retrieving row", error);
      return null;
    }
  };

  const createDbUser = async (rowData) => {
    var entity = {};
    entity[dbUsersSchema.email] = rowData.email;
    entity[dbUsersSchema.admin] = rowData.admin;

    var request = {
      // collection: "cr749_db_businesslines",
      collection: dbUsersSchema.plural,
      entity: entity,
      returnRepresentation: true,
    };
    //call dynamicsWebApi.createRequest function
    const response = await dynamicsWebApi
      .createRequest(request)
      .then(function (record) {
        console.log("created record: ", record);
        return record;
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
      });
    return response;
  };

  const updateDbUser = async (rowData) => {
    console.log("here is the row data: ", rowData);
    var entity = {};
    entity[dbUsersSchema.email] = rowData.email;
    entity[dbUsersSchema.admin] = rowData.admin;

    var request = {
      key: rowData.key,
      collection: dbUsersSchema.plural,
      entity: entity,
      returnRepresentation: true,
      select: [dbUsersSchema.id],
    };

    dynamicsWebApi
      .updateRequest(request)
      .then((response) => {
        console.log(`Successfully updated Row ${entity}`);
      })
      .catch((error) => {
        console.log("Error updating client row", error);
      });
  };

  const deleteDbUser = async (rowData) => {
    var request = {
      key: rowData.key,
      collection: dbUsersSchema.plural,
    };

    try {
      await dynamicsWebApi.deleteRequest(request);
      console.log(`Successfully deleted row with key ${rowData.key}`);
    } catch (error) {
      console.log("Error deleting row", error);
    }
  };

  const handleCheckboxChange = (pageIndex, itemIndex, name) => {
    const adjustedIndex = pageIndex * itemsPerPage + itemIndex;
    const updatedData = [...userData];
    updatedData[adjustedIndex] = {
      ...updatedData[adjustedIndex],
      [name]: !updatedData[adjustedIndex][name],
    };
    setUserData(updatedData);
  };

  const handleAddRow = () => {
    setUserData([
      ...userData,
      {
        email: "",
        admin: false,
        key: null,
      },
    ]);
  };

  const handleInputChange = (pageIndex, itemIndex, field, value) => {
    const adjustedIndex = pageIndex * itemsPerPage + itemIndex;
    const updatedData = [...userData];
    updatedData[adjustedIndex] = {
      ...updatedData[adjustedIndex],
      [field]: value,
    };
    setUserData(updatedData);
  };

  const handleSaveRow = async (index) => {
    const adjustedIndex = indexOfFirstItem + index;
    const rowData = userData[adjustedIndex];
    console.log("here is the row data: ", rowData);
    if (!rowData || !rowData.email || rowData.email.trim().length === 0) {
      return;
    }

    const updatedData = [...userData];
    updatedData[adjustedIndex] = { ...rowData, isSaving: true };
    setUserData(updatedData);

    let updatedKey = null; 

    try {
      const response = await retrieveDbUser(rowData);
      if (response && response?.value?.length > 0) {
        await updateDbUser(rowData);
      } else {
        const createdRow = await createDbUser(rowData);
        updatedKey = createdRow?.cr0d7_dbusersid || null;
        const updatedRow = {
          ...rowData,
          key: updatedKey,
        };
        updatedData[adjustedIndex] = updatedRow;
        console.log("here is the updated row: ", updatedRow);
        setUserData(updatedData);
      }
    } catch (error) {
      console.error("Error saving row:", error);
    } finally {
      const finalData = [...userData];
      finalData[adjustedIndex] = {
        ...rowData,
        key: updatedKey,
        isSaving: false,
      };
      setUserData(finalData);
    }
  };

  const handleDeleteRow = async (index) => {
    const adjustedIndex = indexOfFirstItem + index;
    const rowData = userData[adjustedIndex];
    console.log("here is the row data to delete: ", rowData);
    if (!rowData || !rowData.email || rowData.email.trim().length === 0) {
      return;
    }
    await deleteDbUser(rowData);
    const updatedData = [...userData];
    updatedData.splice(adjustedIndex, 1);
    setUserData(updatedData); 
  };
  

  return (
    <>
      <Container text style={{ padding: "1em 0em" }}>
        <Header as="h3">DB Users Table</Header>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Email
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Admin
              </Table.HeaderCell>
              <Table.HeaderCell
                style={{ backgroundColor: "#03C5A1", color: "white" }}
              >
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {currentItems.map((user, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                  <Form.Input
                    value={user.email}
                    onChange={(e, { value }) =>
                      handleInputChange(activePage - 1, index, "email", value)
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Checkbox
                    checked={user.admin}
                    onChange={() =>
                      handleCheckboxChange(activePage - 1, index, "admin")
                    }
                  />
                </Table.Cell>
                <Table.Cell>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Button
                      color="green"
                      onClick={() => handleSaveRow(index)}
                      disabled={!user.email || user.isSaving}
                    >
                      {user.isSaving ? "Saving..." : "Save"}
                    </Button>

                    <Button onClick={() => handleDeleteRow(index)}>
                      Delete
                    </Button>
                  </div>
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Pagination
          activePage={activePage}
          onPageChange={(e, { activePage }) => setActivePage(activePage)}
          totalPages={Math.ceil(userData.length / itemsPerPage)}
        />
        <Button color="green" style={{ marginLeft: 10 }} onClick={handleAddRow}>
          Add Row
        </Button>
      </Container>
    </>
  );
};
