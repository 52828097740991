import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import { useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, useIsAuthenticated } from "@azure/msal-react";
// import { Container, Header, Divider, Button, Grid } from "semantic-ui-react";
import { HomePage } from "./components/pages/HomePage";
import { NewOpportunity } from "./components/pages/NewOpportunity";
import { NameChange } from "./components/pages/NameChange";
import { FeeApp } from "./components/pages/FeeApp";
import { ErrorPage } from "./components/pages/ErrorPage";
import { NoteWebApp } from "./components/pages/NoteApp";
import "./styles/App.css";
import { GroupScheme } from "./components/pages/GroupSchemePage";
import { RenewalsWebApp } from "./components/pages/RenewalPage";
import { RenewalsContactForm } from "./components/RenewalsContactsForm";
import { RenewalsContactApp } from "./components/pages/RenewalsContactPage";
import { RenewalsKeyMilestonesForm } from "./components/RenewalsKeyMilestonesForm";
import { RenewalsCompletedApp } from "./components/pages/RenewalsCompletedPage";
import { DbUsersContext } from "./components/context/usersContext";
import { AdminAppPage } from "./components/pages/AdminAppPage";
import { NewBusinessChecklistPage } from "./components/pages/NewBusinessChecklistPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <HomePage/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/new-opportunity",
        element: <NewOpportunity/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/name-change",
        element: <NameChange/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/fee-app",
        element: <FeeApp/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/note-app",
        element: <NoteWebApp/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/admin-app",
        element: <AdminAppPage/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/group-scheme",
        element: <GroupScheme/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/renewals-app",
        element: <RenewalsWebApp/>,
        errorElement: <ErrorPage/>
    },{
		path: 'renewals-app/:dealId',
		element: <RenewalsContactApp />,
		errorElement: <ErrorPage/>
	},{
		path: 'renewals-app/:dealId/completed',
		element: <RenewalsCompletedApp />,
		errorElement: <ErrorPage/>
	},
    {
        path: "/new-business-checklist",
        element: <NewBusinessChecklistPage/>,
        errorElement: <ErrorPage/>
    }
    
]);

//wrapped App in user context
export default function App() {

    return (
        <DbUsersContext>
        <RouterProvider router={router}/>
        </DbUsersContext>
    );
}
