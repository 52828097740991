import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Header, Divider, Segment, Button } from "semantic-ui-react";

export const RenewalsCompletedForm = () => {
  const navigate = useNavigate();
  return (
    <>
      <Segment inverted style={{ padding: "10em 0em 0.5em" }}>
        <Container text>
          <Header inverted as="h1">
            Renewal App
            <Header.Subheader>Done!</Header.Subheader>
          </Header>
          <Divider inverted />
        </Container>
      </Segment>
      <Container style={{ padding: "1em 0em" }}>
        <Segment>
          <Button color="green" style={{marginBottom: 20}} onClick={() => navigate("/renewals-app")}>
            Back to renewals
          </Button>
          <p>Renewal planning meeting complete! </p>
          <p> Tasks have been generated based on the agreed plan</p>
          <p>
            {" "}
            The client folder will contain the generated report for your review
            - PDF.
          </p>
        </Segment>
      </Container>
    </>
  );
};
